import { LensCategory } from '../interfaces/lens-interface';
import { CoreMenuItems } from '../interfaces/menu.interface';
import { SavedViewsViewType } from './saved-views.constants';

export const AlarmMenuIcon = 'fas fa-bell';
export const WorkMenuIcon = 'fas fa-clipboard-list-check';
export const HelpZendeskUrl = 'https://help.vfacility.com/hc/en-us/signin/';

export enum lensType {
  SYSTEM = 'system',
  CUSTOM = 'custom',
  CREATE = 'create',
}

export enum AlarmViews {
  tile = 'tile',
  table = 'table',
}

export enum LensParent {
  ACTIVE_ALARMS = 'Active Alarms',
  ALL_ALARMS = 'All Alarms',
  OPEN_WORK = 'Open Work',
  ALL_WORK = 'All Work',
  ACTIVE_ASSETS = 'Active Assets',
  ALL_ASSETS = 'All Assets',
}

export enum tabRoutes {
  ACTIVE_ALARM = '/m/alarms/list/',
  ALL_ALARM = '/alarms/list/all_alarms/',
  ACTIVE_TABLE = '/alarms/Standard Lenses',
  ALL_ALARM_TABLE = '/alarms/Standard Lenses',
  ACTIVE_WORK = '/m/alarms/work/list/',
  ACTIVE_WORK_LIST = '/alarms/work/list/active_work/Open Work/0',
  ALL_WORK = '/work/list/',
  ALL_WORK_LIST = '/work/Standard Lenses/All Work',
  MOBILE_LINKED_WORK = '/m/alarms/work/list/active_work/all/0',
  MOBILE_ACTIVE_ALARM = '/m/alarms/alarms/list/active_alarms/',
  ASSET = '/assets/',
}

export enum adminTabRoutes {
  ADMIN_USERS_LIST = '/users/list/main',
  ADMIN_USERS_SETTINGS = '/users/settings/main',
  ADMIN_USERS_ANALYTICS_GOALS = '/users/analytics-goals/main',
  ADMIN_CMMS_CONFIG = '/users/cmms-config/main',
  ADMIN_COMMAND_CENTER_CONFIG = '/users/command-center-config/main',
  ADMIN_INVENTORY = '/users/inventory/main',
  ADMIN_MAP_VIEW_CONFIG = '/users/map-view-config/main',
}

export const categoryBaseUrl = {
  active_work: tabRoutes.ACTIVE_WORK,
  active_alarms: tabRoutes.ACTIVE_ALARM,
  active_table: tabRoutes.ACTIVE_TABLE,
  all_alarms: tabRoutes.ALL_ALARM,
  all_work: tabRoutes.ALL_WORK,
  all_alarms_table: tabRoutes.ALL_ALARM_TABLE,
  recently_closed_work: tabRoutes.ACTIVE_WORK,
};

export const categoryBaseTileUrl = {
  active_work: tabRoutes.ACTIVE_WORK,
  active_alarms: tabRoutes.ACTIVE_ALARM,
  active_table: tabRoutes.ACTIVE_TABLE,
  all_alarms: tabRoutes.ALL_ALARM,
  all_work: tabRoutes.ALL_WORK,
  all_alarms_table: tabRoutes.ALL_ALARM_TABLE,
  recently_closed_work: tabRoutes.ACTIVE_WORK,
};

export const categoryBaseTableUrl = {
  active_work: tabRoutes.ACTIVE_WORK,
  active_alarms: tabRoutes.ACTIVE_TABLE,
  active_table: tabRoutes.ACTIVE_TABLE,
  all_alarms: tabRoutes.ALL_ALARM,
  all_work: tabRoutes.ALL_WORK,
  all_alarms_table: tabRoutes.ALL_ALARM_TABLE,
  recently_closed_work: tabRoutes.ACTIVE_WORK,
};

export const typeBaseUrl = {
  alarm: tabRoutes.ACTIVE_TABLE,
  work: tabRoutes.ALL_WORK,
  asset: tabRoutes.ASSET,
};

export const ALL_ACCESS_MAIN_ROUTE =
  'alarms/list/active_alarms/Active Alarms/0';

export const ANALYTIC_REPORT_PATH = {
  ALARM_HISTORY: 'alarm-history',
  ALARM_HISTORICAL_DETAIL: 'alarm-historical-detail',
  BAD_ACTORS_REPORT: 'bad-actors-report',
  WORK_HISTORY: 'workhistory',
  WORK_HISTORY_REPORT: 'work-history',
  WORK_RESOLUTION: 'work-resolution',
  CURRENT_STATUS_REPORT: 'current-status-report',
  SPACE_IMPACT_SUMMARY: 'space-impact-summary',
  EQUIPMENT_FAILURE_SUMMARY: 'equipment-failure-summary',
  SYSTEM_FAILURE_SUMMARY: 'system-failure-summary',
  KPI_DASHBOARD: 'kpi-dashboard',
  ACTIVE_ALARMS: 'active-alarms',
};

export const AnalyticsLensUrlMap = new Map([
  [SavedViewsViewType.AlarmHistory, ANALYTIC_REPORT_PATH.ALARM_HISTORY],
  [
    SavedViewsViewType.AlarmHistoricalDetail,
    ANALYTIC_REPORT_PATH.ALARM_HISTORICAL_DETAIL,
  ],
  [SavedViewsViewType.Nuisance, ANALYTIC_REPORT_PATH.BAD_ACTORS_REPORT],
  [SavedViewsViewType.WorkHistory, ANALYTIC_REPORT_PATH.WORK_HISTORY],
  [
    SavedViewsViewType.WorkHistoryReport,
    ANALYTIC_REPORT_PATH.WORK_HISTORY_REPORT,
  ],
  [SavedViewsViewType.WorkResolution, ANALYTIC_REPORT_PATH.WORK_RESOLUTION],
  [
    SavedViewsViewType.CurrentStatus,
    ANALYTIC_REPORT_PATH.CURRENT_STATUS_REPORT,
  ],
  [
    SavedViewsViewType.SpaceHistoricalFailure,
    ANALYTIC_REPORT_PATH.SPACE_IMPACT_SUMMARY,
  ],
  [
    SavedViewsViewType.EquipmentHistoricalFailure,
    ANALYTIC_REPORT_PATH.EQUIPMENT_FAILURE_SUMMARY,
  ],
  [
    SavedViewsViewType.SystemHistoricalFailure,
    ANALYTIC_REPORT_PATH.SYSTEM_FAILURE_SUMMARY,
  ],
  [SavedViewsViewType.Kpi, ANALYTIC_REPORT_PATH.KPI_DASHBOARD],
  [SavedViewsViewType.ActiveAlarms, ANALYTIC_REPORT_PATH.ACTIVE_ALARMS],
]);

export const SETTINGS_MENU: CoreMenuItems[] = [
  {
    app: 'Settings',
    filter: {},
    name: 'My Profile',
    order: 1,
    parent: '',
    lens: [],
    icon: 'fas fa-user',
    counter: 0,
    route: '/settings/my-profile',
    routeParams: 'main',
    permission: 'profile',
    permissionApp: 'settings',
    show: false,
  },
  {
    app: 'Settings',
    filter: {},
    name: 'Users',
    order: 2,
    parent: '',
    lens: [],
    icon: 'fas fa-hospital-user',
    counter: 0,
    route: '/settings/users',
    routeParams: 'main',
    permission: 'userGroups',
    permissionApp: 'settings',
    show: false,
  },
  {
    app: 'Settings',
    filter: {},
    name: 'Roles',
    order: 2,
    parent: '',
    lens: [],
    icon: 'fas fa-user-lock',
    counter: 0,
    route: '/settings/roles',
    routeParams: 'main',
    permission: 'roles',
    permissionApp: 'settings',
    show: false,
  },
  {
    app: 'Settings',
    filter: {},
    name: 'Teams',
    order: 2,
    parent: '',
    lens: [],
    icon: 'fa-solid fa-users-medical',
    counter: 0,
    route: '/settings/teams',
    routeParams: 'main',
    permission: 'teams',
    permissionApp: 'settings',
    show: false,
    isBeta: false,
  },
  {
    app: 'Settings',
    filter: {},
    name: 'Notifications',
    order: 2,
    parent: '',
    lens: [],
    icon: 'fa-solid fa-messages',
    counter: 0,
    route: '/settings/notifications',
    routeParams: 'main',
    permission: 'teams',
    permissionApp: 'settings',
    show: false,
    isBeta: false,
  },
  {
    app: 'Settings',
    filter: {},
    name: 'Automation Rules',
    order: 8,
    parent: '',
    lens: [],
    icon: 'fa-solid fa-wand-magic-sparkles',
    counter: 0,
    route: '/settings/automation-rules',
    routeParams: 'main',
    permission: 'automationRules',
    permissionApp: 'settings',
    show: false,
    isBeta: false,
  },
  {
    app: 'Settings',
    filter: {},
    name: 'Command Center',
    order: 8,
    parent: '',
    lens: [],
    icon: 'fa-regular fa-radar',
    counter: 0,
    route: '/settings/command-center',
    routeParams: 'main',
    permission: 'commandCenter',
    permissionApp: 'settings',
    show: false,
    isBeta: false,
  },
  {
    app: 'Settings',
    filter: {},
    name: 'Submit Feedback',
    order: 3,
    parent: '',
    lens: [],
    icon: 'fas fa-comment-alt-lines',
    counter: 0,
    route: '/settings/feedback',
    routeParams: 'main',
    permission: 'feedback',
    permissionApp: 'settings',
    show: false,
  },
  {
    app: 'Settings',
    filter: {},
    name: 'About Virtual Facility',
    order: 5,
    parent: '',
    lens: [],
    icon: 'fas fa-building',
    counter: 0,
    route: '/settings/about',
    routeParams: 'main',
    permission: 'about',
    permissionApp: 'settings',
    show: false,
  },
];

export const ANALYTICS_MENU: CoreMenuItems[] = [
  {
    app: 'Analytics',
    filter: {},
    name: 'Alarm Activity (Last 14 Days)',
    order: 1,
    parent: 'Alarms',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.ALARM_HISTORY}`,
    routeParams: 'main',
    permission: 'alarms',
    permissionApp: 'analytics',
    show: false,
    header: 'Alarms',
    isBeta: false,
    isWork: true,
    type: SavedViewsViewType.AlarmHistory,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Alarm Historical Detail',
    order: 1,
    parent: 'Alarm Historical Detail',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.ALARM_HISTORICAL_DETAIL}`,
    routeParams: 'main',
    permission: 'advanced-analytics',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: false,
    isParent: true,
    isAdvancedAnalytics: true,
    type: SavedViewsViewType.AlarmHistoricalDetail,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Active Alarms Report',
    order: 1,
    parent: 'Active Alarms Report',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.ACTIVE_ALARMS}`,
    routeParams: 'main',
    permission: 'advanced-analytics',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: false,
    isParent: true,
    isAdvancedAnalytics: true,
    type: SavedViewsViewType.ActiveAlarms,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Bad Actors Report',
    order: 1,
    parent: 'Bad Actors Report',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.BAD_ACTORS_REPORT}`,
    routeParams: 'main',
    permission: 'advanced-analytics',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: false,
    isParent: true,
    isAdvancedAnalytics: true,
    type: SavedViewsViewType.Nuisance,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Work History',
    order: 1,
    parent: 'Work History',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.WORK_HISTORY}`,
    routeParams: 'main',
    permission: 'work-history',
    permissionApp: 'analytics',
    show: false,
    isBeta: false,
    isWork: true,
    header: 'Work',
    type: SavedViewsViewType.WorkHistory,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Work Activity (Last 14 Days)',
    order: 1,
    parent: 'Work History',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.WORK_HISTORY_REPORT}`,
    routeParams: 'main',
    permission: 'work-history',
    permissionApp: 'analytics',
    show: false,
    isBeta: false,
    isWork: true,
    type: SavedViewsViewType.WorkHistoryReport,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Work Resolution',
    order: 1,
    parent: 'Work Resolution',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.WORK_RESOLUTION}`,
    routeParams: 'main',
    permission: 'work-resolution',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: true,
    type: SavedViewsViewType.WorkResolution,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Current Status Report',
    order: 1,
    parent: 'Current Status Report',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.CURRENT_STATUS_REPORT}`,
    routeParams: 'main',
    permission: 'advanced-analytics',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: false,
    isParent: true,
    isAdvancedAnalytics: true,
    header: 'Assets',
    type: SavedViewsViewType.CurrentStatus,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Space Impact Summary',
    order: 1,
    parent: 'Space Impact Summary',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.SPACE_IMPACT_SUMMARY}`,
    routeParams: 'main',
    permission: 'advanced-analytics',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: false,
    isParent: true,
    isAdvancedAnalytics: true,
    type: SavedViewsViewType.SpaceHistoricalFailure,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'Equipment Failure Summary',
    order: 1,
    parent: 'Equipment Failure Summary',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.EQUIPMENT_FAILURE_SUMMARY}`,
    routeParams: 'main',
    permission: 'advanced-analytics',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: false,
    isParent: true,
    isAdvancedAnalytics: true,
    type: SavedViewsViewType.EquipmentHistoricalFailure,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'System Failure Summary',
    order: 1,
    parent: 'System Failure Summary',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.SYSTEM_FAILURE_SUMMARY}`,
    routeParams: 'main',
    permission: 'advanced-analytics',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: false,
    isParent: true,
    isAdvancedAnalytics: true,
    type: SavedViewsViewType.SystemHistoricalFailure,
  },
  {
    app: 'Analytics',
    filter: {},
    name: 'KPI Dashboard',
    order: 1,
    parent: 'KPI Dashboard',
    lens: [],
    icon: 'fas fa-poll',
    counter: 0,
    route: `/analytics/${ANALYTIC_REPORT_PATH.KPI_DASHBOARD}`,
    routeParams: 'main',
    permission: 'kpi-dashboard',
    permissionApp: 'analytics',
    isBeta: false,
    show: false,
    isWork: false,
    header: 'Dashboards',
    type: SavedViewsViewType.Kpi,
  },
];

export const SIMULATOR_ALARM_MENU: CoreMenuItems[] = [
  {
    app: 'Simulator',
    name: 'Sales',
    order: 1,
    parent: 'Sales',
    icon: 'fas fa-bell',
    counter: 0,
    route: '/simulator/sales',
    routeParams: 'sales',
    lensId: '0',
    permission: 'counts',
    permissionApp: 'reporting',
    show: true,
    header: 'Simulator',
  },
];

export const WORK_MENU_ITEMS = [
  {
    name: 'My Work',
    id: 2,
    criteria: {},
    category: 'work',
    displayOptions: null,
    isActive: true,
  },
];

export const WORK_RELATED_LENSES = ['Dispatched', 'Active Work'];
export const ALL_ACCESS_TRIAGE_LENS_CATEGORIES = [
  'active_alarms',
  'active_work',
  'recently_closed_work',
];
export const RESTRICTED_TRIAGE_LENS_CATEGORIES = ['active_alarms'];
export const ALL_ACCESS_ALARM_LENS_CATEGORIES = ['active_alarms', 'all_alarms'];
export const ALL_ACCESS_WORK_LENS_CATEGORIES = [
  'active_work',
  'recently_closed_work',
  'all_work',
];
export const RESTRICTED_WORK_LENS_CATEGORIES = [];

export const HIDE_BADGE_CATEGORIES = [
  LensCategory.ALL_ALARMS,
  LensCategory.ALL_WORK,
  LensCategory.RECENTLY_CLOSED_WORK,
  LensCategory.ALL_ASSETS,
];

export const IgnoreDefaultSearchParam = 'ignore-default';
