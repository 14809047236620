import { gql } from 'apollo-angular';

import { allWorkOrderFields } from '@vfi-ui/models';

export const GET_ALL_WORK_ORDER_FIELDS_QUERY = gql`
  query work_order_view($where: work_order_view_bool_exp) {
    work_order_view(where: $where) {
      ${allWorkOrderFields}
    }
  }
`;

export const POST_WORK_TICKET_QUERY = gql`
  mutation createWorkTicket($input: WorkTicketCreateInput!) {
    createWorkTicket(input: $input) {
      id
    }
  }
`;
