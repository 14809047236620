import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  input,
  output,
  model,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { PasswordValidator } from '@vfi-ui/util/validators';
import { NgClass } from '@angular/common';

@Component({
  selector: 'nuclei-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, ReactiveFormsModule, NgClass],
})
export class SetPasswordComponent implements OnInit {
  readonly passwordForm = model<UntypedFormGroup>(undefined);
  readonly changePassword = input(false);
  readonly oldPasswordError = input('');
  readonly showWarning = input<boolean>(false);
  readonly formValid = output<boolean>();

  showForm = false;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    const passwordForm = this.passwordForm() ?? this.formBuilder.group({});

    passwordForm.addControl('password', new UntypedFormControl(null));
    passwordForm.addControl('confirmPassword', this.formBuilder.control(null));
    if (this.changePassword()) {
      passwordForm.addControl(
        'oldPassword',
        this.formBuilder.control(null, Validators.required)
      );
    }

    const validators = this.getPasswordValidators();

    passwordForm.controls['password'].setValidators(validators);

    passwordForm.controls['confirmPassword'].setValidators(Validators.required);

    this.passwordForm.set(passwordForm);
    this.showForm = true;

    passwordForm.statusChanges.subscribe((d) => {
      this.formValid.emit(d === 'VALID');
    });
  }

  /**
   * return validators for password
   *
   * @private
   * @returns
   * @memberof SetPasswordComponent
   */
  private getPasswordValidators() {
    return [
      Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        PasswordValidator.patternValidator(/\d/, {
          hasNumber: true,
        }),
        // check whether the entered password has upper case letter
        PasswordValidator.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        // check whether the entered password has a lower case letter
        PasswordValidator.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        // check whether the entered password has a special character
        PasswordValidator.patternValidator(
          /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ]),
    ];
  }
}
