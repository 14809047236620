<div class="d-flex align-items-center pagination">
  <nz-pagination
    aria-label="pagination"
    [(nzPageIndex)]="pageIndex"
    [nzTotal]="totalItems()"
    [ngClass]="{ disableInput: disabled() }"
    [nzPageSize]="pageLimit()"
    [nzSize]="'small'"
    nzSimple
    (nzPageIndexChange)="pageChanged.emit($event)"
  ></nz-pagination>
  @if (allowPageChange()) {
    <nz-select
      class="iml-2"
      aria-label="pageSize"
      [nzSize]="'small'"
      [(ngModel)]="pageLimit"
      (ngModelChange)="pageSizeChanged.emit($event)"
      >
      @for (option of pageOptions; track option) {
        <nz-option
          nzLabel="{{ option }} / page"
          [nzValue]="option"
          >
        </nz-option>
      }
    </nz-select>
  }
</div>
