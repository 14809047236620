<div class="multi-level-select">
  <button
    class="dropdown-header"
    aria-haspopup="listbox"
    aria-label="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showSavedViews"
    [nzPopoverPlacement]="['bottomLeft']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="savedViewsDropdown"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    [disabled]="disabled()"
    [class.gray-8-bg]="disabled()"
    >
    <h1 class="header-title regular-24 mr-2 mb-0 dm00 fw-500">
      {{ selectedOption()?.name }}
    </h1>
    <i class="fas fa-chevron-down"></i>
  </button>
</div>
<ng-template #savedViewsDropdown>
  <div
    class="dropdown-list"
    role="listbox"
    aria-expanded="false"
    >
    <atom-dropdown-item
      class="default-view"
      [item]="defaultView()"
      [title]="defaultView().name"
      [showCheckbox]="false"
      [selected]="defaultView().id === selectedOption()?.id"
      (selectChange)="isChecked(defaultView())"
      [showRightIcon]="false"
      [showDrilldown]="false"
    ></atom-dropdown-item>

    @if (loading()) {
      <div
        class="alert"
      >Loading...</div>
    }

    @if (!loading() && options()?.length > 0) {
      <div
        class="list-container"
        #listContainer
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="listContainer"
        >
        <h4 class="user-views regular-14">Saved Views</h4>
        @for (o of options(); track o) {
          <atom-dropdown-item
            [item]="o"
            [title]="o.name"
            [showCheckbox]="false"
            [selected]="o.id === selectedOption()?.id"
            (selectChange)="isChecked(o)"
            [customRightIcon]="o.id === selectedOption()?.id ? 'fa-pen' : null"
            [alwaysShowRightIcon]="true"
            (rightIconClick)="editClicked.emit(o)"
            [showRightIcon]="false"
            [showDrilldown]="false"
            [ngClass]="{ 'cursor-not-allowed': o?.meta?.disabled }"
          ></atom-dropdown-item>
        }
      </div>
    }
  </div>
</ng-template>
