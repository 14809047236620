<div [ngClass]="{ 'cursor-not-allowed': disabled() }">
  <div
    class="vfi-input"
    [ngClass]="{ disabled: disabled() }"
    >
    @if (label()) {
      <div
        class="vfi-label"
        >
        <span [class.yellow-6]="required() && !readOnly()">{{ label() }}</span>
        @if (labelIcon()) {
          <i
            class="ml-1 mr-1 {{ labelIcon() }}"
            nz-tooltip
            [nzTooltipTitle]="labelIconTooltip()"
          ></i>
        }
        @if (labelCheck()) {
          <i
            class="ml-1 fa-solid fa-check check-green"
            nz-tooltip
            nzTooltipTitle="Verified"
          ></i>
        }
      </div>
    }
    <nz-date-picker
      class="w-100"
      [class.readOnly]="readOnly()"
      [ngClass]="{'pointer-none': readOnly() || disabled()}"
      [ngModel]="value()"
      [nzPlaceHolder]="placeholder()"
      nzShowTime
      nzFormat="MM/dd/yyyy hh:mm a"
      [nzDisabledDate]="disabledDates()"
      (ngModelChange)="changed($event)"
    ></nz-date-picker>
  </div>
</div>
