import { Component, input, output } from '@angular/core';
import { ButtonIconPosition, ButtonSize, ButtonType } from '@vfi-ui/models';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NgClass } from '@angular/common';

@Component({
  selector: 'atom-vfi-button',
  templateUrl: './vfi-button.component.html',
  styleUrls: ['./vfi-button.component.scss'],
  imports: [NzTooltipDirective, NgClass],
})
export class VfiButtonComponent {
  readonly size = input(ButtonSize.DEFAULT);
  readonly ariaLabel = input<string>(`btn-${this.size()}`);
  readonly type = input(ButtonType.PRIMARY);
  readonly loading = input(false);
  readonly text = input('');
  readonly icon = input<string>(undefined);
  readonly iconStack = input<string[]>(undefined);
  readonly iconPosition = input(ButtonIconPosition.LEFT);
  readonly disabled = input(false);
  readonly classes = input<string>(undefined);
  readonly clicked = output<boolean>();
  buttonIconPosition = ButtonIconPosition;

  constructor() {}
}
