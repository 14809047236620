import { gql } from 'apollo-angular';

export const CREATE_NOTIFICATION_QUERY = gql`
  mutation createLensNotification($data: LensNotificationCreateInput!) {
    createLensNotification(data: $data)
  }
`;

export const DELETE_NOTIFICATION_QUERY = gql`
  mutation deleteLensNotification($id: String!) {
    deleteLensNotification(id: $id)
  }
`;

export const UPDATE_NOTIFICATION_QUERY = gql`
  mutation updateLensNotification(
    $id: String!
    $data: LensNotificationUpdateInput!
  ) {
    updateLensNotification(id: $id, data: $data)
  }
`;

export const GET_NOTIFICATIONS_QUERY = gql`
  query lensNotificationsAndCount($options: LensNotificationOptions!) {
    lensNotificationsAndCount(options: $options) {
      count
      items {
        id
        name
        description
        notes
        criteria {
          exclude
          dayOfWeek
          timeRanges {
            from
            to
          }
          hourInterval
          timezone
          includeRTN
          secondsDelay
        }
        displayOptions
        emailUsersToNotify {
          id
          displayName
          avatarUrl
        }
        pushUsersToNotify {
          id
          displayName
          avatarUrl
        }
        smsUsersToNotify {
          id
          displayName
          avatarUrl
        }
        webhookUrls
      }
    }
  }
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query lensNotificationsAndCount($options: LensNotificationOptions!) {
    lensNotificationsAndCount(options: $options) {
      items {
        id
        name
        description
        createdAt
        criteria {
          exclude
          dayOfWeek
          timeRanges {
            from
            to
          }
          hourInterval
          timezone
          includeRTN
          secondsDelay
        }
        notes
        emailUsersToNotify {
          id
          displayName
          authRole
          company
          phone {
            number
          }
          email {
            address
          }
          role
          teamMembers {
            team {
              name
            }
          }
          status
        }
        pushUsersToNotify {
          id
          displayName
          authRole
          company
          phone {
            number
          }
          email {
            address
          }
          role
          teamMembers {
            team {
              name
            }
          }
          status
        }
        smsUsersToNotify {
          id
          displayName
          authRole
          company
          phone {
            number
          }
          email {
            address
          }
          role
          teamMembers {
            team {
              name
            }
          }
          status
        }
        lens {
          id
          name
          description
          type
          isCustom
          team {
            id
            name
          }
        }
        user {
          displayName
          role
          authRole
        }
        webhookUrls
      }
    }
  }
`;

export const GET_NOTIFICATION_AUDIT_LOGS = gql`
  query lensNotificationAuditLogs {
    lensNotificationAuditLogs
  }
`;

export const TEST_WEBHOOK_NOTIFICATION_MUTATION = gql`
  mutation testWebhookNotification(
    $url: String!
    $lensId: String!
    $alarmId: Int!
  ) {
    testWebhookNotification(url: $url, lensId: $lensId, alarmId: $alarmId) {
      isComplete
      isSuccessful
      result
    }
  }
`;

export const FETCH_NOTIFICATION_TEMPLATES_QUERY = gql`
  query notificationTemplates($options: NotificationTemplateOptions!) {
    notificationTemplates(options: $options) {
      id
      type
      method
      template {
        subject
        preheader
        body
      }
      isEnabled
    }
  }
`;

export const UPDATE_NOTIFICATION_TEMPLATE_QUERY = gql`
  mutation updateNotificationTemplate(
    $id: String!
    $input: NotificationTemplateUpdateInput!
  ) {
    updateNotificationTemplate(id: $id, input: $input) {
      id
      type
      method
      template {
        subject
        preheader
        body
      }
      isEnabled
    }
  }
`;

export const TEST_NOTIFICATION_TEMPLATE_QUERY = gql`
  mutation testNotificationTemplate(
    $input: NotificationTemplateCreateInput!
    $variables: Object
  ) {
    testNotificationTemplate(input: $input, variables: $variables)
  }
`;
