import { Component, input, model, output } from '@angular/core';
import { SavedView } from '@vfi-ui/models';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';
import { NgClass } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'atom-saved-views-selector',
  templateUrl: './saved-views-selector.component.html',
  styleUrls: ['./saved-views-selector.component.scss'],
  imports: [
    NzPopoverDirective,
    DropdownItemComponent,
    InfiniteScrollDirective,
    NgClass,
  ],
})
export class SavedViewsSelectorComponent {
  readonly defaultView = input<SavedView>(undefined);
  readonly selectedOption = input<SavedView>(undefined);
  readonly options = input<SavedView[]>([]);
  readonly disabled = input(false);
  readonly showSavedViews = model(false);
  readonly loading = input(false);
  readonly viewSelected = output<SavedView>();
  readonly editClicked = output<any>();

  constructor() {}

  /**
   * item selection
   *
   * @param {SavedView} option
   * @memberof SavedViewsSelectorComponent
   */
  isChecked(option: SavedView) {
    this.showSavedViews.set(false);
    this.viewSelected.emit(option);
  }
}
