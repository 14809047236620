import {
  Component,
  ElementRef,
  AfterViewInit,
  OnInit,
  input,
  output,
  viewChild,
  model,
} from '@angular/core';
import { ButtonSize } from '@vfi-ui/models';
import { isNil } from '@vfi-ui/util/helpers';
import { debounceTime, Subject } from 'rxjs';
import { NgClass } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NzInputDirective } from 'ng-zorro-antd/input';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { PreventSpecialCharacterDirective } from '../special-character/special-character.directive';
import { VfiButtonComponent } from '../vfi-button/vfi-button.component';

@Component({
  selector: 'atom-vfi-input',
  templateUrl: './vfi-input.component.html',
  styleUrls: ['./vfi-input.component.scss'],
  imports: [
    NgClass,
    NzTooltipDirective,
    NzInputDirective,
    FormsModule,
    NgxMaskDirective,
    PreventSpecialCharacterDirective,
    VfiButtonComponent,
  ],
})
export class VfiInputComponent implements AfterViewInit, OnInit {
  readonly inputElm = viewChild<ElementRef>('inputElm');
  readonly value = model<string>(undefined);
  readonly label = input<string>(undefined);
  readonly placeholder = input('');
  readonly labelCheck = input(false);
  readonly labelIcon = input<string>(undefined);
  readonly labelIconTooltip = input<string>(undefined);
  readonly type = input('text');
  readonly hint = input<string>(undefined);
  readonly required = input(false);
  readonly mask = input(null);
  readonly prefix = input<string>(undefined);
  readonly size = input('medium');
  readonly iconLeft = input<string>(undefined);
  readonly readOnly = input(false);
  readonly disabled = input(false);
  readonly subtle = input(false);
  readonly success = input(false);
  readonly warning = input(false);
  readonly warningMessage = input<string>(undefined);
  readonly error = input(false);
  readonly errorMessage = input<string>(undefined);
  readonly autofocus = input(false);
  readonly showCancel = input(true);
  readonly showEditButtons = input(false);
  readonly allowSpecialCharacters = input(true);
  readonly oninput = input(undefined);
  readonly pattern = input(undefined);
  readonly blurChanged = output<string>();
  readonly valueChanged = output<string>();
  readonly save = output<string>();
  readonly enterPressed = output<boolean>();
  buttonSize = ButtonSize;
  onInputChange = new Subject<string>();

  constructor() {}

  ngOnInit() {
    this.onInputChange.pipe(debounceTime(300)).subscribe((value) => {
      this.value.set(value);
      this.valueChanged.emit(this.value());
    });
  }

  ngAfterViewInit() {
    // Autofocus on input
    if (this.autofocus()) {
      setTimeout(() => this.inputElm().nativeElement.focus(), 500);
    }
  }

  /**
   * handle input changed event
   *
   * @param {string} value
   * @memberof VfiInputComponent
   */
  changed(value: string) {
    this.onInputChange.next(value);
  }

  /**
   * clear input
   *
   * @memberof VfiInputComponent
   */
  clear() {
    this.changed('');
  }

  /**
   * handle blur event
   *
   * @param {FocusEvent} ev
   * @memberof VfiInputComponent
   */
  onBlur(ev: FocusEvent) {
    // Prevent blur event from firing when clicking on buttons
    if (isNil(ev.relatedTarget)) {
      this.onCancel();
    }
  }

  /**
   * handle save event
   *
   * @param {string} value
   * @memberof VfiInputComponent
   */
  onSave(value: string) {
    this.save.emit(value);
  }

  /**
   * handle cancel event
   *
   * @memberof VfiInputComponent
   */
  onCancel() {
    this.blurChanged.emit(this.value());
  }
}
