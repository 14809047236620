<div class="version w-100 d-flex align-content-center justify-content-center">
  <div class="align-self-center">
    <div>
      <div class="version-box">
        <section
          class="version-content d-flex flex-column justify-content-between h-100"
          >
          <div class="form_wrapper d-flex flex-column align-items-center">
            <img
              class="flex-1 vfi-logo mb-3"
              src="/assets/vfi-logo.svg"
              alt="vfi-logo"
              />
            @if (channels?.length) {
              <div
                class="flex-1 flex-grow-1 ng-star-inserted d-flex flex-column justify-content-center w-100 mt-5"
                >
                <p class="regular-20 font-light neutral-1 mb-3">
                  Select App Channel
                </p>
                <div
                  class="input-wrapper caret-wrapper"
                  [class.input-active]="!isNil(selectedChannel)"
                  >
                  <ng-select
                    [items]="channels"
                    [multiple]="false"
                    [closeOnSelect]="true"
                    [clearable]="false"
                    [(ngModel)]="selectedChannel"
                    (ngModelChange)="selectChannel()"
                    >
                  </ng-select>
                  <label>Select Channel</label>
                </div>
                @if (downloadStatus > 0) {
                  <h3 class="mt-3">
                    Downloading: {{ downloadStatus }}%
                  </h3>
                }
                @if (extractStatus > 0) {
                  <h3>
                    Extracting: {{ extractStatus }}%
                  </h3>
                }
                @if (selectedConfig?.channel) {
                  <div>
                    <h4 class="mt-3">Current Config:</h4>
                    @for (key of keys(selectedConfig); track key) {
                      <div>
                        <span class="text-capitalize fw-bold"> {{ key }}:</span
                          ><span class="ml-2">{{ selectedConfig[key] }}</span> <br />
                        </div>
                      }
                    </div>
                  }
                </div>
              }
            </div>
            <br />
            <div class="mt-4 flex-1">
              <p class="gray-1 regular-12">
                &copy; {{ currentYear }} Virtual Facility, Inc. All Rights
                Reserved.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
