export enum TeamMemberRole {
  'Admin' = 'TEAM_ADMIN',
  'Member' = 'TEAM_MEMBER',
}

export enum TeamTabs {
  Location = 'Location',
  Members = 'Members',
}

export const TeamTabOptions = [
  {name: TeamTabs.Location, isVisible: true},
  {name: TeamTabs.Members, isVisible: true},
]

export const NO_TEAM_ERROR =
  'This account is not assigned to a team. Please reach out to your admin for alarm data access.';

export const PagesUrls = {
  // TODO: update permissions to reflect work change
  explorer: '/work',
  analytics: '/analytics',
  automation: '/automation-rules',
  commandCenter: '/command-center',
};
