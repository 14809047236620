@if (show()) {
  <div class="d-flex flex-column andOr pb-8 w-100 h-100">
    <div class="andOrTop"></div>
    <nz-radio-group
      [ngModel]="value()"
      nzSize="small"
      [nzButtonStyle]="'solid'"
      (ngModelChange)="valueChanged.emit($event)"
      >
      <label nz-radio-button [nzValue]="groupClause.AND">AND</label>
      <label nz-radio-button [nzValue]="groupClause.OR">OR</label>
    </nz-radio-group>
    <div class="andOrBottom"></div>
  </div>
}
