import { environment } from '@vfi-ui/environments/environment';
import { Component } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Logout, AuthState } from '@vfi-ui/state';
import { User } from '@vfi-ui/models';
import { Observable } from 'rxjs';
import { RouterLinkActive, RouterLink } from '@angular/router';
import {
  NzDropdownButtonDirective,
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';

@Component({
  selector: 'vfi-admin-dashboard-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss'],
  imports: [
    RouterLinkActive,
    RouterLink,
    NzDropdownButtonDirective,
    NzDropDownDirective,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
  ],
})
export class AdminNavigationComponent {
  @Select(AuthState.user) user$: Observable<User>;
  showUsers = true;
  showParser = true;
  environment = environment;

  constructor(private store: Store) {}

  /**
   * dispatch logout event when signing out
   *
   * @memberof NavigationComponent
   */
  signOut() {
    this.store.dispatch(new Logout());
  }
}
