import {
  Component,
  SimpleChanges,
  OnChanges,
  input,
  model,
} from '@angular/core';
import {
  BLANK_VALUE,
  BLANK_VALUE_TEXT,
  SELECT_ALL_VALUE,
} from '@vfi-ui/models';
import { head, isEmpty } from '@vfi-ui/util/helpers';
import { get } from '@vfi-ui/util/helpers';
import { NgStyle, NgClass, UpperCasePipe } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';

@Component({
  selector: 'atom-disabled-input',
  templateUrl: './disabled-input.component.html',
  styleUrls: ['./disabled-input.component.scss'],
  imports: [
    NgStyle,
    NgClass,
    NzTooltipDirective,
    NzAvatarComponent,
    UpperCasePipe,
  ],
})
export class DisabledInputComponent implements OnChanges {
  readonly placeholder = model<string>(undefined);
  readonly isReadOnly = input(true);
  readonly values = input<string[]>(undefined);
  readonly avatar = input(null);
  readonly wordBreak = input(false);
  readonly ellipsisCount = input<number>(undefined);
  readonly selectAll = input(false);
  readonly selectAllValue = input<string>(undefined);
  readonly scrollHeight = input(0);
  readonly isTextArea = input(false);
  formattedValues: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const placeholderChanged = get(changes.placeholder, 'currentValue', false);
    if (placeholderChanged) {
      this.placeholder.set(this.placeholder().replace('*', ''));
    }
    if (changes.values) {
      this.formattedValues = this.formatValues(this.values());
    }
  }

  /**
   * format disabled values
   *
   * @param {string[]} values
   * @returns
   * @memberof DisabledInputComponent
   */
  formatValues(values: string[]) {
    const formatted = this.selectAll()
      ? this.formatSpecialValues(values)
      : values;
    if (formatted && formatted.length && !isEmpty(head(formatted))) {
      const joined = formatted.join(', ');
      return this.ellipsisCount() > 0 ? this.ellipsisValues(joined) : joined;
    }
    return this.isReadOnly() ? '-' : '';
  }

  /**
   * format special values
   *
   * @param {string[]} values
   * @returns
   * @memberof DisabledInputComponent
   */
  formatSpecialValues(values: string[]) {
    return values.map((value) => {
      if (value === SELECT_ALL_VALUE) {
        return `All ${this.selectAllValue()}`;
      } else if (value === BLANK_VALUE) {
        return BLANK_VALUE_TEXT;
      } else {
        return value;
      }
    });
  }
  /**
   * ellipse string by given count
   *
   * @param {string} val
   * @return {*}
   * @memberof DisabledInputComponent
   */
  ellipsisValues(val: string) {
    if (val.length > this.ellipsisCount()) {
      return val.slice(0, this.ellipsisCount()).trim() + '...';
    } else {
      return val;
    }
  }
}
