import { Component, input, model, output } from '@angular/core';
import { NgStyle } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NzBadgeComponent } from 'ng-zorro-antd/badge';

@Component({
  selector: 'atom-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
  imports: [NgStyle, NzTooltipDirective, NzBadgeComponent],
})
export class DropdownItemComponent {
  readonly showCheckbox = input(true);
  readonly title = input<string>(undefined);
  readonly selected = model<boolean>(undefined);
  readonly showRightIcon = input<boolean>(undefined);
  readonly showLeftIcon = input<boolean>(undefined);
  readonly showBadge = input(true);
  readonly badgeCount = input(0);
  readonly item = input<any>(undefined);
  readonly showDrilldown = input(true);
  readonly disabled = input<boolean>(undefined);
  readonly customRightIcon = input<string>(undefined);
  readonly alwaysShowRightIcon = input(false);
  readonly showTooltips = input(false);
  readonly rightIconClick = output();
  readonly selectChange = output();
  readonly drillDownChange = output();

  constructor() {}

  /**
   * drill down to child
   *
   * @memberof DropdownItemComponent
   */
  drillDown() {
    this.drillDownChange.emit(this.item());
  }

  /**
   * select item
   *
   * @memberof DropdownItemComponent
   */
  selectItem() {
    if (!this.selected()) {
      this.selected.set(true);
      this.selectChange.emit(this.item());
    } else {
      this.selected.set(false);
      this.selectChange.emit(null);
    }
  }
}
