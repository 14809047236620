import { Component, viewChild } from '@angular/core';
import { UntypedFormGroup, FormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  AuthService,
  MfaService,
  NotificationService,
} from '@vfi-ui/data-access/shared';
import { User } from '@vfi-ui/models';
import { Logout, SignIn } from '@vfi-ui/state';
import { CodeInputComponent, CodeInputModule } from 'angular-code-input';
import { TotpMultiFactorGenerator } from 'firebase/auth';

import { VfiButtonComponent } from '@vfi-ui/ui/atoms';
import { QRCodeComponent } from 'angularx-qrcode';

@Component({
  selector: 'vfi-auth-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss', '../login/login.component.scss'],
  imports: [VfiButtonComponent, QRCodeComponent, FormsModule, CodeInputModule],
})
export class MfaComponent {
  readonly codeInput = viewChild.required<CodeInputComponent>('codeInputEl');
  mfaSignInForm: UntypedFormGroup;
  submitted = false;
  totpFactorId = TotpMultiFactorGenerator.FACTOR_ID;
  totpUrl: string;
  selectedFactorId: string;
  mfaVerificationLoading = false;

  constructor(
    public authService: AuthService,
    public mfa: MfaService,
    private notificationService: NotificationService,
    private store: Store
  ) {}

  /**
   * Handler for the back button
   *
   * @memberof MfaComponent
   */
  handleBackButton() {
    if (this.mfa.showSecure && !this.mfa.showVerification) {
      this.store.dispatch(new Logout());
    } else if (
      !this.mfa.showSecure &&
      this.mfa.showVerification &&
      this.mfa.enrollingInMFA
    ) {
      this.mfa.showSecure = true;
      this.mfa.showVerification = false;
      this.selectedFactorId = null;
    } else if (
      !this.mfa.showSecure &&
      this.mfa.showVerification &&
      !this.mfa.enrollingInMFA
    ) {
      this.store.dispatch(new Logout());
    }
  }

  /**
   * Setups selection of factor for mfa
   *
   * @param {string} factorId
   * @memberof MfaComponent
   */
  async selectFactor(factorId: string) {
    this.selectedFactorId = factorId;
    if (factorId === TotpMultiFactorGenerator.FACTOR_ID) {
      await this.enrollInMfa(factorId);
      this.mfa.showVerification = true;
      this.mfa.showSecure = false;
    }
  }

  /**
   * Enroll user in mfa
   *
   * @param {*} value
   * @memberof MfaComponent
   */
  async enrollInMfa(factorId: string) {
    if (factorId === TotpMultiFactorGenerator.FACTOR_ID) {
      const { totpSecret, url } = await this.mfa.initializeTOTPEnroll();
      this.totpUrl = url;
      this.mfa.totpSecret = totpSecret;
    }
  }

  /**
   * Sign current user in with mfa
   *
   * @param {*} value
   * @memberof MfaComponent
   */
  async signInWithMFA(value: any) {
    this.mfaVerificationLoading = true;
    this.submitted = true;
    let user: User;
    if (value && this.mfa.verificationId && this.mfa.verificationResolver) {
      user = await this.mfa.signInWithMFA({
        verificationCode: value,
        verificationId: this.mfa.verificationId,
        resolver: this.mfa.verificationResolver,
      });
    } else {
      // service handles navigation to app
      if (this.selectedFactorId === TotpMultiFactorGenerator.FACTOR_ID) {
        await this.mfa.verifyEnrollWithTOTP({
          totpSecret: this.mfa.totpSecret,
          verificationCode: value,
        });
      }
    }

    if (user) {
      this.mfa.verificationId = null;
      this.mfa.verificationResolver = null;
      this.store.dispatch(new SignIn(user));

      if (this.mfa.showMFASuccessToast) {
        this.notificationService.showSuccess(
          'Multi-Factor Authentication Setup Successful',
          "Next time you log in, you'll need to enter the verification code sent to this mobile number. You can edit the mobile number under settings."
        );
      }
      this.submitted = false;
    }
    this.mfaVerificationLoading = false;
  }

  /**
   * Signs in on completely entering the code
   *
   * @param code
   * @memberof MfaComponent
   */
  onCodeCompleted(code: string | number) {
    this.signInWithMFA(`${code}`);
  }
}
