<div
  aria-label="tabs"
  class="w-100 tabs-wrapper"
  >
  @for (tab of tabs(); track tab) {
    <span>
      @if (tab?.isVisible) {
        <span
          class="tab cursor-pointer"
          [ngClass]="{ selected: selectedTabId() ? selectedTabId() === tab?.id : selectedTab() === tab?.name }"
          (click)="selected(tab?.name, tab?.id)"
        >{{ tab?.name }}</span>
      }
    </span>
  }
  @if (showAddTab()) {
    <span
      class="tab cursor-pointer"
      (click)="addTab()"
      ><i class="fa-solid fa-plus"></i></span>
    }
  </div>

  <nz-modal
    [(nzVisible)]="changeModalVisible"
    nzMaskClosable="false"
    (nzOnCancel)="changeModalVisible = false"
    nzTitle="Leave page without saving?"
    [nzFooter]="footerBtn"
    nzClosable="true"
    [nzWidth]="460"
    [nzZIndex]="modalZIndex + 2"
    nzWrapClassName="vertical-center-modal"
    nzClassName="mobile-confirm-modal dark-modal"
    >
    <ng-template nzModalContent>
      <div class="modal-body mb-2 regular-16">
        <p
          aria-label="discardMessage1"
          class="mb-0"
          >
          If you leave this page your changes will not be saved.
        </p>
      </div>
    </ng-template>
  </nz-modal>

  <ng-template #footerBtn>
    <div class="d-sm-down-flex flex-sm-down-column-reverse">
      <atom-vfi-button
        [text]="'Stay on page'"
        [ariaLabel]="'stayOnPageBtn'"
        [type]="'secondary'"
        [classes]="'block-button-sm'"
        (clicked)="stay()"
      ></atom-vfi-button>
      <atom-vfi-button
        [text]="'Leave'"
        [ariaLabel]="'discardChangesBtn'"
        [type]="'danger'"
        [classes]="'iml-md-1 iml-xs-0 imb-xs-2 block-button-sm'"
        (clicked)="leave()"
      ></atom-vfi-button>
    </div>
  </ng-template>
