// TODO: fix
/* eslint-disable @nx/enforce-module-boundaries */
import {
  EntityType,
  MultiLevelInputOption,
  MultiLevelPath,
} from '@vfi-ui/models';
import { EntityManagementDataService } from '@vfi-ui/data-access/shared';
import { BaseComponent } from '@vfi-ui/feature/core';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  input,
  model,
  output,
} from '@angular/core';
import { Subject, take } from 'rxjs';
import {
  debounce,
  fastParse,
  formatEntityOptionNames,
} from '@vfi-ui/util/helpers';
import { NgTemplateOutlet } from '@angular/common';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { MultiLevelInputComponent } from '../multi-level-input/multi-level-input.component';

@Component({
  selector: 'atom-point-entity-selector',
  templateUrl: './point-entity-selector.component.html',
  styleUrls: ['./point-entity-selector.component.scss'],
  imports: [NzPopoverDirective, NgTemplateOutlet, MultiLevelInputComponent],
})
export class PointEntitySelectorComponent
  extends BaseComponent
  implements OnInit
{
  readonly entityChanged = output<MultiLevelInputOption>();
  readonly pathChanged = output<MultiLevelPath>();
  readonly newSelected = output<string>();
  readonly placeholder = input<string>(undefined);
  readonly value = model<MultiLevelInputOption>(undefined);
  readonly reset = input<Subject<boolean>>(undefined);
  readonly type = model<EntityType>(undefined);
  readonly showMultiLevelSelect = model(false);
  readonly disabled = input(false);
  readonly baseEntity = model<MultiLevelInputOption>(undefined);
  readonly pointEntity = input<MultiLevelInputOption>(undefined);
  readonly showInput = input(true);
  readonly lowestLevel = input<number>(undefined);
  readonly showTypeSelector = input(true);
  readonly multiple = input(false);
  readonly allowNew = input(false);
  parent: MultiLevelInputOption[] = [];
  options = [];
  loading = false;
  assetTypeCount: number;
  spaceTypeCount: number;
  offset = 0;
  limit = 50;
  optionCount: number;

  constructor(
    private entityManagement: EntityManagementDataService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    const reset = this.reset();
    if (reset) {
      reset.pipe(take(1)).subscribe(() => {
        this.value.set(this.pointEntity());
      });
    }

    const baseEntity = this.baseEntity();
    if (baseEntity) {
      this.parent = [baseEntity];
    }

    const type = this.type();
    if (type) {
      this.parent = [null];
    }

    this.getEntities(null, null, type);
  }

  /**
   * get search value from form
   * @param {string} searchTerm
   * @memberof PointEntitySelectorComponent
   */
  searchEntities(searchTerm: string) {
    const debounced = debounce(this.getEntities, 1000);
    debounced.call(
      this,
      this.parent[this.parent.length - 1]?.id,
      searchTerm,
      this.type()
    );
  }

  /**
   * drill down to child
   *
   * @param {MultiLevelInputOption} event
   * @memberof PointEntitySelectorComponent
   */
  drillDown(event) {
    this.loading = true;
    if (!this.parent.length) {
      this.type.set(event.option.name);
    }

    if (event.option.count > 0) {
      this.offset = 0;
      this.parent.push(event.option);
      this.baseEntity.set(event.option);
      this.getEntities(
        this.baseEntity().id as number,
        event.searchTerm,
        this.type()
      );
    }
  }

  /**
   * click back to parent
   *
   * @memberof PointEntitySelectorComponent
   */
  back() {
    this.offset = 0;
    this.options = null;
    this.parent.pop();

    this.getEntities(
      this.parent[this.parent.length - 1]?.id as number,
      null,
      this.type()
    );
  }

  /**
   * scroll to load more
   * @param {string} searchTerm
   * @memberof PointEntitySelectorComponent
   */
  scroll(event: { searchTerm: string }) {
    if (this.options.length < this.optionCount) {
      this.offset += this.limit;
      this.getEntities(
        this.parent[this.parent.length - 1]?.id as number,
        event.searchTerm,
        this.type()
      );
    }
  }

  /**
   * get entities from api
   * @param {number} [baseEntityId]
   * @param {string} [pattern]
   * @param {string} [type]
   * @memberof PointEntitySelectorComponent
   */
  getEntities(baseEntityId?: number, pattern?: string, type?: string) {
    this.assetTypeCount = null;
    this.spaceTypeCount = null;

    if (this.parent.length && type) {
      if (!this.options) {
        this.loading = true;
      }
      this.entityManagement
        .getEntityOptions(
          baseEntityId,
          pattern,
          EntityType[type],
          this.limit,
          this.offset
        )
        .subscribe((res) => {
          this.optionCount = res?.searchEntityCount;
          const searchEntities = fastParse(res?.searchEntities);
          const formattedOptions = formatEntityOptionNames(searchEntities);
          const newOption = {
            id: 0,
            name: `Add New ${type}`,
            count: 0,
            building: '',
            class: '',
          };
          this.loading = false;
          this.options =
            this.offset === 0
              ? formattedOptions
              : [...this.options, ...formattedOptions];
          if (this.allowNew() && this.options.length === this.optionCount) {
            this.options.push(newOption);
          }
          this.cd.detectChanges();
        });
    } else {
      this.options = [
        { name: 'Space', count: 1 },
        { name: 'Asset', count: 1 },
      ];

      if (!baseEntityId && pattern) {
        this.entityManagement
          .searchEntityCount(baseEntityId, pattern, EntityType.Space)
          .pipe(take(1))
          .subscribe((res) => {
            this.options[0].meta = { badge: res };
            this.cd.detectChanges();
          });
        this.entityManagement
          .searchEntityCount(baseEntityId, pattern, EntityType.Asset)
          .pipe(take(1))
          .subscribe((res) => {
            this.options[1].meta = { badge: res };
            this.cd.detectChanges();
          });
      }
    }
  }

  /**
   * emit value on change
   * @param {MultiLevelInputOption} option
   * @memberof PointEntitySelectorComponent
   */
  onValueChange(option: MultiLevelInputOption) {
    this.value.set(option);
    const path = { path: this.parent, value: this.value() };
    this.pathChanged.emit(path);
    this.entityChanged.emit(this.value());
    this.showMultiLevelSelect.set(false);
    this.getEntities(null, null, this.type());
  }
}
