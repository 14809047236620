<div
  class="dropdown-list d-flex flex-column justify-content-between"
  role="listbox"
  aria-expanded="false"
  >
  <div>
    <input
      type="text"
      class="dropdown-search"
      placeholder="Search"
      (keyup)="searched.emit($event.target.value)"
      />

    @if (!options()?.length && loading()) {
      <div class="alert">Loading...</div>
    }
    @if (!options()?.length && !loading()) {
      <div class="alert">No Results</div>
    }

    @if (options()?.length) {
      <div
        class="list-container"
        #listContainer
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="listContainer"
        (scrolled)="scrolled.emit(true)"
        >
        @for (o of options(); track o) {
          <atom-dropdown-item
            [item]="o?.value"
            [title]="o?.label"
            [selected]="isChecked(o)"
            [showCheckbox]="false"
            [showTooltips]="true"
            (selectChange)="optionSelected(o)"
          ></atom-dropdown-item>
        }
      </div>
    }
  </div>
</div>
