import { NotificationTemplateVariable } from '../interfaces/notification.interface';

export const NOTIFICATION_ORDER = {
  field: 'NAME',
  direction: 'ASC',
};

export enum notificationModalMode {
  CREATE = 'create notification',
  EDIT = 'edit notification',
}

export enum NotificationTimeType {
  Always = '24/7',
  Schedule = 'schedule',
}

export const INSTANTANEOUSLY_WARNING =
  'WARNING: Instantaneous notification of reoccurring alarms may cause excessive emails and push notification messages to be sent.';

export const ALLOW_NOTIFICATION_LENS_TYPE = [
  'alarms',
  'alarm',
  'asset',
  'activeWork',
  'allWork',
  'work',
];

export const DISABLE_NOTIFICATION_CATEGORIES = ['all_alarms', 'all_assets'];

export const NotificationTableTabs = [
  { name: 'By Team', isVisible: true },
  { name: 'By User', isVisible: true },
  { name: 'Audit Logs', isVisible: true },
  { name: 'Templates', isVisible: true },
];

export enum NotificationTemplateVariableType {
  Field = 'field',
  Button = 'button',
}

export const NOTIFICATION_BUTTON_TEMPLATE = {
  viewAlarm:
    "<div style='display: flex; justify-content: center; align-items: center; margin-top: 20px;'><button type='button' href='{{alarmLink}}' style='background-color: dodgerblue; color: white; width: 200px; height: 50px; border: none; border-radius: 5px;'>View Alarm</button></div>",
  viewWork:
    "<div style='display: flex; justify-content: center; align-items: center; margin-top: 20px;'><button type='button' href='{{workLink}}' style='background-color: dodgerblue; color: white; width: 200px; height: 50px; border: none; border-radius: 5px;'>View Work</button></div>",
  viewAsset:
    "<div style='display: flex; justify-content: center; align-items: center; margin-top: 20px;'><button type='button' href='{{assetLink}}' style='background-color: dodgerblue; color: white; width: 200px; height: 50px; border: none; border-radius: 5px;'>View Asset</button></div>",
};

export const NOTIFICATION_TEMPLATE_VARIABLES: NotificationTemplateVariable[] = [
  {
    label: 'Alarm ID',
    dbName: 'alarmId',
    value: 'A123',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work ID',
    dbName: 'workId',
    value: 'W123',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Asset ID',
    dbName: 'assetId',
    value: '65287',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Alarm Asset Name',
    dbName: 'alarmAssetName',
    value: 'Chilled Water System',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Alarm Name',
    dbName: 'alarmName',
    value: 'Exhaust Low Flow',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Alarm Contextual Name',
    dbName: 'alarmContextualName',
    value:
      '***** CRITICAL VAV-B-X-8-14 Exh Flow Lo Alarm Contact HVAC Shop (***** CRITICAL VAV-B-X-8-14 Exh Flow Lo Alarm)',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Alarm Text',
    dbName: 'alarmText',
    value:
      'Exhaust VAV Damper Travel Distance performance flag for VAVX-8-37 serving 808',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Location - Building',
    dbName: 'building',
    value: 'Energy',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Location - Floor',
    dbName: 'floor',
    value: '10',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Location - Room',
    dbName: 'room',
    value: 'Loading Dock',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Asset Type',
    dbName: 'assetType',
    value: 'Building',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Space Type',
    dbName: 'spaceType',
    value: 'Control Room',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work Objective',
    dbName: 'workObjective',
    value:
      'Resolve "Science: SHORT_TEXT_:Heat Trace Common Alarm is in Alarm., SOURCE_PATH_:heat_trace_alarm, CATEGORY_:hvac_general hvac_general',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Alarm Time',
    dbName: 'alarmTime',
    value: '2/3/2025 1:35 PM',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Alarm End Time',
    dbName: 'alarmEndTime',
    value: '2/3/2025 6:48 PM',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work Created Date',
    dbName: 'workCreatedDate',
    value: '1/16/2025 9:56 AM',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work Closed Date',
    dbName: 'workClosedDate',
    value: '1/16/2025 7:03 PM',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work Created By',
    dbName: 'workCreatedBy',
    value: 'Lily Garrison',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work Closed By',
    dbName: 'workClosedBy',
    value: 'Davis James',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Alarm State',
    dbName: 'alarmState',
    value: 'Normal',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Alarm Status',
    dbName: 'alarmStatus',
    value: 'Intake',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work State',
    dbName: 'workState',
    value: 'Open',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work Assignee (CMMS)',
    dbName: 'cmmsAssignee',
    value: 'Antony Lam',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Work Assignee (VF)',
    dbName: 'vfAssignee',
    value: 'Sofia Owens',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'CMMS Work ID',
    dbName: 'cmmsWorkId',
    value: '76-87290',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Documentation',
    dbName: 'documentation',
    value: 'Something is on fire',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Lens Name',
    dbName: 'lensName',
    value: 'Active Systems',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Lens Notification Name',
    dbName: 'lensNotificationName',
    value: 'Active Systems Notifications',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Lens Creator',
    dbName: 'lensCreator',
    value: 'Susan Hurley',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Lens Notification Creator',
    dbName: 'lensNotificationCreator',
    value: 'Leo Henson',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Lens Notification Notes',
    dbName: 'lensNotificationNotes',
    value: 'Too many notifications',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'Lens Notification Delay (minutes)',
    dbName: 'lensNotificationDelay',
    value: '60',
    type: NotificationTemplateVariableType.Field,
  },
  {
    label: 'View Alarm',
    dbName: 'viewAlarm',
    type: NotificationTemplateVariableType.Button,
  },
  {
    label: 'View Work',
    dbName: 'viewWork',
    type: NotificationTemplateVariableType.Button,
  },
  {
    label: 'View Asset',
    dbName: 'viewAsset',
    type: NotificationTemplateVariableType.Button,
  },
];

export enum NotificationMethod {
  Email = 'EMAIL',
  Push = 'PUSH_NOTIFICATION',
  Sms = 'SMS',
}

export enum NotificationType {
  Alarm = 'ALARM_LENS',
  Asset = 'ASSET_LENS',
  Work = 'WORK_TICKET_LENS',
}

export const NotificationMethodData = {
  [NotificationType.Asset]: {
    [NotificationMethod.Email]: {
      title: 'Asset Email Notification',
      subtitle: 'Email sent from Asset Lens Notifications',
    },
    [NotificationMethod.Push]: {
      title: 'Asset Push Notification',
      subtitle: 'Push Notifications sent from Asset Lens',
    },
    [NotificationMethod.Sms]: {
      title: 'Asset Text Notification (SMS)',
      subtitle: 'Text Notifications sent from Asset Lens',
    },
  },
  [NotificationType.Alarm]: {
    [NotificationMethod.Email]: {
      title: 'Alarm Email Notification',
      subtitle: 'Email sent from Alarm Lens Notifications',
    },
    [NotificationMethod.Push]: {
      title: 'Alarm Push Notification',
      subtitle: 'Push Notifications sent from Alarm Lens',
    },
    [NotificationMethod.Sms]: {
      title: 'Alarm Text Notification (SMS)',
      subtitle: 'Text Notifications sent from Alarm Lens',
    },
  },
  [NotificationType.Work]: {
    [NotificationMethod.Email]: {
      title: 'Work Email Notification',
      subtitle: 'Email sent from Work Lens Notifications',
    },
    [NotificationMethod.Push]: {
      title: 'Work Push Notification',
      subtitle: 'Push Notifications sent from Work Lens',
    },
    [NotificationMethod.Sms]: {
      title: 'Alarm Text Notification (SMS)',
      subtitle: 'Text Notifications sent from Work Lens',
    },
  },
};
