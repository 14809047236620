import {
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  DropdownMenu,
} from '@vfi-ui/models';
import { Component, input, output } from '@angular/core';
import { VfiButtonComponent } from '../vfi-button/vfi-button.component';
import {
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import {
  NzMenuDirective,
  NzMenuGroupComponent,
  NzSubMenuComponent,
  NzMenuItemComponent,
} from 'ng-zorro-antd/menu';

import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';

@Component({
  selector: 'atom-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
  imports: [
    VfiButtonComponent,
    NzDropDownDirective,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuGroupComponent,
    NzSubMenuComponent,
    ɵNzTransitionPatchDirective,
    NzMenuItemComponent,
  ],
})
export class ActionsMenuComponent {
  readonly title = input('Menu Title');
  readonly mobileIconOnly = input(false);
  readonly mobileIcon = input<string>(undefined);
  readonly dropdownMenu = input<DropdownMenu[]>(undefined);
  readonly buttonSize = input<ButtonSize>(ButtonSize.DEFAULT);
  readonly buttonType = input<ButtonType>(ButtonType.SECONDARY);
  readonly menuVisibleChange = output<boolean>();
  ButtonIconPosition = ButtonIconPosition;
}
