export enum AuthRoles {
  admin = 'ADMIN',
  manager = 'MANAGER',
  standard = 'STANDARD',
  vendor = 'VENDOR',
  occupant = 'OCCUPANT',
  none = 'NONE',
  custom = 'CUSTOM',
}

export enum AuthRolesKey {
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  STANDARD = 'User',
  VENDOR = 'Vendor',
  OCCUPANT = 'Occupant',
  CUSTOM = 'Dispatcher',
}

export enum RoleTabs {
  permissions = 'Permissions',
  users = 'Users',
}

export const RoleTabOptions = [
  {name: RoleTabs.permissions, isVisible: true},
  {name: RoleTabs.users, isVisible: true},
]

export const AuthRolesDescriptions = {
  ADMIN:
    'Full access to the entire Virtual Facility platform, including all administrative abilities',
  MANAGER:
    'Full access to the Virtual Facility platform, including new user configuration and oversight',
  STANDARD:
    'The standard Virtual Facility experience. Access to Alarms, Work, and Reporting',
  VENDOR:
    'A limited experience suitable for users who only need access to Alarms and Work',
  OCCUPANT: 'A view-only experience with access to alarm data only',
};

export const PermissionsKeys = {
  alarms: {
    alarms: 'Alarms',
    clearAlarms: 'Clear Alarms',
    editNotes: 'Edit Notes',
  },
  work: {
    myWork: 'My Work',
    teamWork: 'Team Work',
    activityLog: 'Activity Log',
  },
  pages: {
    explorer: 'Explorer',
    analytics: 'Analytics',
    commandCenter: 'Command Center',
    assetGrid: 'Assets',
  },
  userAdmin: {
    users: 'Users',
  },
  roleAdmin: {
    roles: 'Roles',
  },
  teamAdmin: {
    teams: 'Teams',
  },
  automation: {
    automation: 'Automation',
  },
  entityManagement: {
    entityManagement: 'Entity Management',
    commandCenter: 'Command Center',
  },
  kpiDrilldown: {
    kpiDrilldown: 'KPI Drilldown',
  },
  lenses: {
    editLensNotificationRecipients: 'Edit Lens Notification Recipients',
    editTableConfiguration: 'Edit Table Configuration',
    manageTeamLenses: 'Manage Team Lenses',
    viewMyLenses: 'View My Lenses',
    viewStandardLenses: 'View Standard Lenses',
    viewTeamLenses: 'View Team Lenses',
  },
  savedViews: {
    savedViews: 'Saved Views',
  },
  analytics: {
    advancedAnalytics: 'Advanced Analytics',
  },
};

export const PermissionsPageHeaderKey = {
  alarms: 'Alarms',
  work: 'Work',
  pages: 'Pages',
  userAdmin: 'User Admin',
  roleAdmin: 'Role Admin',
  teamAdmin: 'Team Admin',
  automation: 'Automation',
  entityManagement: 'Entity Management',
  kpiDrilldown: 'KPI Dashboard',
  lenses: 'Lenses',
  savedViews: 'Saved Views',
  analytics: 'Analytics',
  assets: 'Assets',
};

export const PermissionsOptions = {
  VIEW: 'View',
  EDIT: 'Edit',
  DELETE: 'Delete',
  CREATE: 'Create',
  REASSIGN: 'Reassign',
  CLOSE: 'Close',
};

export const PermissionsPagesDescriptions = {
  explorer:
    'Alarm and work access for Explorer is based on permissions in the Alarms and Work section above.',
  analytics:
    'Warning: Analytics access enables users to see all Alarms and work data regardless of their permissions set in the Alarms and Work sections.',
  commandCenter:
    'Command Center access enables users to view the Command Center for Situation Awareness. All critical alarms and related work will be visible.',
  assetGrid:
    'Assets access enables users to view all the assets and spaces known to Virtual Facility.',
};

export const PermissionsBlacklist = [
  'assets',
  'listControl',
  'spaceManagement',
];
