<div class="multi-level-select">
  @if (label()) {
    <div
      class="vfi-label"
      [class.yellow-6]="required() && !readOnly()"
      >
      <span>{{ label() }}</span>
    </div>
  }
  <button
    class="dropdown-button vfi-assignee-selector"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showMultiLevelSelect"
    [nzPopoverPlacement]="['bottom', 'top']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="multiLevelDropdown"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    (nzPopoverVisibleChange)="teamId() ? getUsersByTeams() : getUsers()"
    [disabled]="disabled()"
    [class.gray-8-bg]="disabled()"
    [class.readOnly]="readOnly()"
    >
    @if (
      !displaySelectedValue() ||
      !value() ||
      (isArray(value()) && !value()?.length)
      ) {
      <div
        class="dropdown-label-placeholder dm60"
        >
        {{ placeholder() }}
      </div>
    }
    @if (
      ((!isArray(value()) && value()) ||
      (isArray(value()) && value()?.length)) &&
      displaySelectedValue()
      ) {
      <div
        class="w-100"
        >
        @if (!isArray(value())) {
          <div
            class="dropdown-label d-flex align-items-start"
            >
            {{ value()?.name }}
          </div>
        }
        @if (isArray(value())) {
          <div
            class="dropdown-label d-flex align-items-start"
            >
            {{ formatAssigneeValue(value()) }}
          </div>
        }
      </div>
    }
  </button>
</div>
<ng-template #multiLevelDropdown>
  <atom-multi-level-input
    [options]="optionsByTeams() ? teamOptions : options"
    [unassignedOptions]="unassignedTeamOptions"
    [multiSelect]="multiSelect()"
    [optionsBySection]="optionsByTeams()"
    (searchChange)="searchUser($event)"
    [selectedValue]="selectedValue"
    (valueChange)="onValueChange($event)"
    [loading]="loading"
  ></atom-multi-level-input>
</ng-template>
