<ul class="ml-2 d-flex v-tab-list mb-0">
  <li
    class="v-tab regular-16 mr-1 pt-1 cursor"
    [routerLink]="listRoute() + baseUrl"
    [ngClass]="{ active: isActive(url(), '/list/') }"
    >
    <span>Users</span>
  </li>
  <li
    class="v-tab regular-16 mr-1 pt-1"
    [routerLink]="dashRoute() + baseUrl"
    [ngClass]="{ active: isActive(url(), '/settings/') }"
    >
    <span>Settings</span>
  </li>
  @if (showCmmsConfig()) {
    <li
      class="v-tab regular-16 mr-1 pt-1"
      [routerLink]="adminRoutes.ADMIN_CMMS_CONFIG + baseUrl"
      [ngClass]="{ active: isActive(url(), '/cmms-config/') }"
      >
      <span>CMMS Config</span>
    </li>
  }
  <li
    class="v-tab regular-16 mr-1 pt-1"
    [routerLink]="analyticsGoalsRoute() + baseUrl"
    [ngClass]="{ active: isActive(url(), '/analytics-goals/') }"
    >
    <span>Analytics - Goals</span>
  </li>
  <li
    class="v-tab regular-16 mr-1 pt-1"
    [routerLink]="adminRoutes.ADMIN_COMMAND_CENTER_CONFIG + baseUrl"
    [ngClass]="{ active: isActive(url(), '/command-center-config/') }"
    >
    <span>Command Center</span>
  </li>
  <li
    class="v-tab regular-16 mr-1 pt-1"
    [routerLink]="adminRoutes.ADMIN_INVENTORY + baseUrl"
    [ngClass]="{ active: isActive(url(), '/inventory/') }"
    >
    <span>Inventory</span>
  </li>
  <li
    class="v-tab regular-16 mr-1 pt-1"
    [routerLink]="adminRoutes.ADMIN_MAP_VIEW_CONFIG + baseUrl"
    [ngClass]="{ active: isActive(url(), '/map-view-config/') }"
    >
    <span>Map View</span>
  </li>
</ul>
<hr class="mt-0 ml-2 mb-0" />
