<nz-modal
  [(nzVisible)]="isVisible"
  nzMaskClosable="true"
  (nzOnCancel)="handleCancel()"
  [nzTitle]="mode() === 'edit' ? 'Edit Customer' : 'Create Customer'"
  [nzFooter]="footerBtn"
  nzClosable="true"
  [nzWidth]="700"
  [nzBodyStyle]="{ height: '560px' }"
  [nzZIndex]="modalZIndex"
  nzWrapClassName="vertical-center-modal"
  >
  <ng-template nzModalContent>
    <div class="modal-body mb-2 regular-16 text-start">
      <form [formGroup]="createCustomerForm" class="d-flex">
        <div class="mr-4">
          <span class="neutral-1 regular-14">Name the Customer Account</span>
          <div
            class="input-wrapper w-65 mb-3 mt-1"
            [class.input-active]="createCustomerForm.value?.name"
            >
            <input
              type="text"
              class="form-control"
              maxlength="255"
              [class.required-field]="!createCustomerForm?.value?.name"
              aria-label="customerName"
              formControlName="name"
              />
            <label>Customer Name</label>
          </div>
          <div
            class="input-wrapper w-65 mb-3"
            [class.input-active]="createCustomerForm?.value?.domains"
            >
            <input
              type="text"
              class="form-control"
              maxlength="255"
              formControlName="domains"
              aria-label="domain"
              />
            <label>Customer Email Domain</label>
          </div>
          <label
            attr.aria-label="ssoCheckbox"
            class="ineutral-2 regular-16 imb-4"
            nz-checkbox
            formControlName="isSSOEnabled"
            >SSO
          </label>
        </div>
        <div>
          <label
            attr.aria-label="pilotCheckbox"
            class="ineutral-2 regular-16 pilotCheckbox"
            nz-checkbox
            formControlName="isPilot"
            >Pilot
          </label>
          @if (createCustomerForm?.value?.isPilot) {
            <div
              class="input-wrapper iw-100"
              [class.input-active]="createCustomerForm?.value?.pilotDateEnd"
            [class.required-field]="
              createCustomerForm?.controls['pilotDateEnd'].status === 'INVALID'
            "
              >
              <nz-date-picker
                class="w-100"
                nzSize="large"
                nzPlaceHolder="Pilot End Date"
                formControlName="pilotDateEnd"
                nzFormat="MM/dd/yyyy"
              ></nz-date-picker>
              @if (createCustomerForm?.value?.pilotDateEnd) {
                <label
                  >Pilot End Date</label
                  >
                }
              </div>
            }
          </div>
        </form>
      </div>
    </ng-template>
  </nz-modal>

  <ng-template #footerBtn>
    <atom-vfi-button
      [text]="'Cancel'"
      [type]="'secondary'"
      (clicked)="handleCancel()"
    ></atom-vfi-button>
    <atom-vfi-button
      [text]="mode() === 'edit' ? 'Edit Customer' : 'Create Customer'"
      [ariaLabel]=""
      [type]="'primary'"
      [disabled]="createCustomerForm.invalid || disableAddButton()"
      (clicked)="createCustomer(mode())"
    ></atom-vfi-button>
  </ng-template>
