<div class="menu">
  <ul nz-menu nzMode="inline">
    @for (lens of lenses; track lens; let first = $first; let index = $index) {
      <li
        nz-submenu
        class="inline-menu-header"
        [nzOpen]="menuOpenState[lens.name] || false"
        (nzOpenChange)="menuOpenState[lens.name] = $event"
      >
        <span title class="iregular-16 ellipsis accordion-title">{{
          lens.name
        }}</span>
        <ul
          [dragula]="dragulaGroup + index"
          [(dragulaModel)]="lens.childLens"
          (dragulaModelChange)="lensOrderChanged(index)"
        >
          @for (child of lens.childLens; track child) {
            @if (child.type !== lensType.CREATE) {
              <li
                nz-menu-item
                class="pl-2 submenu-border-spacer"
                [attr.aria-label]="child.name"
                [ngClass]="{
                  activeLink: currentSelectedLens?.id === child.id,
                }"
              >
                <span
                  title
                  class="collapse-icon d-flex align-items-center w-100"
                >
                  @if (lens?.canDrag) {
                    <i
                      class="fa-solid fa-grip-dots-vertical mr-1 gray-7 drag-handle cursor-move"
                    ></i>
                  }
                  <i
                    class="fa-solid fa-star regular-10 mr-1 cursor-pointer"
                    [class.fa-bounce]="
                      showStarAnimation && defaultLensId === child.id
                    "
                    [class.disabled-star]="defaultLensId !== child.id"
                    [class.yellow-6]="defaultLensId === child.id"
                    (click)="setDefaultLens(child.id)"
                  ></i>
                  <div
                    class="d-flex align-items-center w-100 ellipsis"
                    (click)="routeChanged(child)"
                  >
                    <a
                      class="mb-0 mr-1 ellipsis w-100"
                      [attr.aria-label]="child.name"
                      [class.allLenName]="
                        !(triageCounts$ | async)[child.id] ||
                        !showCounts(child?.category)
                      "
                      [class.lenName]="
                        (triageCounts$ | async)[child.id] &&
                        showCounts(child?.category)
                      "
                      nz-tooltip
                      nzTooltipPlacement="top"
                      [nzTooltipTitle]="
                        child.name.length >= 16 ? child.name : null
                      "
                    >
                      {{ child.name }}
                    </a>
                  </div>
                  <div class="d-flex justify-content-end" id="badge-container">
                    @if (canEditLens(child)) {
                      <i
                        class="fas fa-pencil show-on-hover cursor-pointer"
                        (click)="
                          updateLens(child.category, child.isCustom, lens)
                        "
                      ></i>
                    }
                    @if (
                      (triageCounts$ | async)[child.id] &&
                      showCounts(child?.category)
                    ) {
                      <nz-badge
                        nzNoAnimation
                        [class.hide-on-hover]="canEditLens(child)"
                        [nzOverflowCount]="999999"
                        [nzShowZero]="true"
                        [nzCount]="(triageCounts$ | async)[child.id]"
                      ></nz-badge>
                    }
                  </div>
                </span>
              </li>
            }
            @if (lens?.isCustom && canCreateLens(lens, child)) {
              <li
                nz-menu-item
                (click)="openLensModel(child.category, lens)"
                class="create-lens-item"
              >
                <span
                  title
                  class="collapse-icon d-flex align-items-center w-100"
                >
                  <div class="transparent-border"></div>
                  <div
                    class="d-flex align-items-center justify-content-end w-100"
                  >
                    <i
                      class="fas fa-plus-circle blue-4 regular-12"
                      nz-tooltip
                      nzTooltipTitle="Create lens"
                    ></i>
                  </div>
                </span>
              </li>
            }
          }
        </ul>
      </li>
    }
  </ul>
</div>
