@if (change() !== 0 && !inverted()) {
  <span
    class="font-spacer"
  [ngClass]="{
    'red-8': change() < 0 && theme() === themeType.DARK,
    'red-7': change() < 0 && theme() === themeType.LIGHT,
    'green-8': change() > 0
  }"
    ><i
    class="fas"
    [ngClass]="{ 'fa-caret-down': change() < 0, 'fa-caret-up': change() > 0 }"
    ></i
    >{{ abs(change()).toLocaleString() }}%</span
    >
  }
  @if (change() !== 0 && inverted()) {
    <span
      class="font-spacer"
  [ngClass]="{
    'red-8': change() > 0 && theme() === themeType.DARK,
    'red-7': change() > 0 && theme() === themeType.LIGHT,
    'green-8': change() < 0
  }"
      ><i
      class="fas"
      [ngClass]="{ 'fa-caret-down': change() < 0, 'fa-caret-up': change() > 0 }"
      ></i
      >{{ abs(change()).toLocaleString() }}%</span
      >
    }
