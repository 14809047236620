import { last } from '@vfi-ui/util/helpers';
import {
  differenceInDays,
  differenceInMilliseconds,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  set,
  startOfDay,
  subDays,
} from 'date-fns';
import {
  CoreAlarm,
  Tile,
  NoAlarmTitle,
  MultipleAlarmProperty,
  AlarmPriorityIcon,
  NuisanceIcon,
  WorkTicketIcon,
  AlarmPinnedIcon,
  AlarmPriorityColors,
  WorkTicket,
  BaseAlarmProperty,
  WorkTicketStatus,
  WorkTicketCloseIcon,
  WorkTicketOpenIcon,
  WorkOrder,
  StaleIcon,
  TimeValues,
  DatetimeRange,
  SELECT_ALL_VALUE,
  BLANK_VALUE_TEXT,
  BLANK_VALUE,
  Granularity,
  GroupCriteriaInput,
  GroupCriteriaClause,
  NUISANCE_FILTER_KEYS,
  nuisanceType,
  CoreAlarmsWhere,
  ALARM_TILE_ALARM_STATE_DISPLAY_LIST,
  ALARM_STATE_TO_TEXT_MAP,
  DateTimeRangeChange,
  CriterionSelection,
  WorkTicketCMMSFailIcon,
  WorkTicketCmmsSyncStatus,
  AutomationRuleScheduleInput,
  MONTHS,
  Entity,
  EntityClass,
  AlarmTimeWindow,
  PRIORITY_VALUES,
  DateTimeFormat,
  DurationKeys,
  ASSET_CRITICALITY_MAP,
  FastAlarm,
  AlarmEntity,
  FastWorkTicket,
  MenuLens,
  Asset,
  LAST_DATE_SELECTION_MAP,
  NEXT_DATE_SELECTION_MAP,
} from '@vfi-ui/models';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import {
  isNil,
  isNull,
  isEmpty,
  head,
  result,
  lowerFirst,
  isURL,
  keys,
  omit,
  uniq,
  startCaseCap,
  titleCase,
  startCase,
  isEqual,
} from './lodash.util';

/**
 * generates a summary string from automation rule schedule
 *
 * @private
 * @param {AutomationRuleScheduleInput} schedule
 * @returns {string}
 * @memberof Utils
 */
export function timeWindowSummaryFromSchedule(
  schedule: AutomationRuleScheduleInput
) {
  let summary: string;

  if (schedule.everyNthDay) {
    summary = summarizeDay(schedule);
  }

  if (schedule.everyNthWeek) {
    summary = summarizeWeek(schedule);
  }

  if (schedule.everyNthMonth) {
    summary = summarizeMonth(schedule);
  }

  if (schedule.everyNthYear) {
    summary = summarizeYear(schedule);
  }

  if (schedule?.timeRanges) {
    summary += schedule?.timeRanges
      .map(
        (range) =>
          ` ${get12HourFormatTime(range.from)} to ${get12HourFormatTime(range.to)}`
      )
      .join(', ');
  } else {
    summary += ' all day.';
  }

  return summary;
}

/**
 * generates a summary for every nth day
 *
 * @private
 * @param {AutomationRuleScheduleInput} schedule
 * @returns {string}
 * @memberof Utils
 */
function summarizeDay(schedule: AutomationRuleScheduleInput) {
  let summary = 'Active every';

  if (schedule.everyNthDay === 1) {
    summary += ' day';
  } else {
    summary += ` ${schedule.everyNthDay} days`;
  }

  return `${summary},`;
}

/**
 * generates a summary for every nth week
 *
 * @private
 * @param {AutomationRuleScheduleInput} schedule
 * @returns {string}
 * @memberof Utils
 */
function summarizeWeek(schedule: AutomationRuleScheduleInput) {
  let summary = 'Active every';

  if (schedule.everyNthWeek > 1) {
    summary += ` ${ordinal(schedule.everyNthWeek as number)}`;
  }

  if (schedule.dayOfWeek?.length === 1) {
    summary += ` ${capitalize(schedule.dayOfWeek[0])}`;
  } else {
    summary += ` ${schedule.dayOfWeek
      ?.map((day) => capitalize(day))
      .join(', ')}`;
  }

  return `${summary},`;
}

/**
 * generates a summary for every nth month
 *
 * @private
 * @param {AutomationRuleScheduleInput} schedule
 * @returns {string}
 * @memberof Utils
 */
function summarizeMonth(schedule: AutomationRuleScheduleInput) {
  let summary = `Active`;

  if (schedule.dayOfMonth) {
    summary += ` ${ordinal(schedule.dayOfMonth)}`;
  }

  if (schedule.intervalInMonth) {
    summary += ` ${schedule.intervalInMonth.repeatingTimePrefix.toLowerCase()} ${capitalize(
      schedule.intervalInMonth.dayOfWeek.toLowerCase()
    )}`;
  }

  if (schedule.everyNthMonth) {
    summary += ` of every ${
      schedule.everyNthMonth > 1 ? ordinal(schedule.everyNthMonth) : ''
    } month`;
  }

  return `${summary},`;
}

/**
 * generates a summary for every nth year
 *
 * @private
 * @param {AutomationRuleScheduleInput} schedule
 * @returns {string}
 * @memberof Utils
 */
function summarizeYear(schedule: AutomationRuleScheduleInput) {
  let summary = 'Active ';

  if (schedule.everyNthYear === 1) {
    summary += 'every year ';
  } else {
    summary += `every ${ordinal(schedule.everyNthYear as number)} year `;
  }

  if (schedule.timeInYear) {
    summary += `on ${capitalize(
      MONTHS.find((month) => month.value === schedule.timeInYear?.month)
        ?.label as string
    )} ${ordinal(schedule.timeInYear.day)}`;
  }

  if (schedule.intervalInMonth) {
    summary += `on the ${schedule.intervalInMonth.repeatingTimePrefix.toLowerCase()} ${capitalize(
      schedule.intervalInMonth.dayOfWeek
    )} of ${capitalize(
      MONTHS.find((month) => month.value === schedule.intervalInMonth?.month)
        ?.label as string
    )}`;
  }

  return `${summary},`;
}

/**
 * capitlizes the first letter of a string
 *
 * @private
 * @param {string} string
 * @returns {string}
 * @memberof Utils
 */
export function capitalize(string: string) {
  return string
    .toLowerCase()
    .replace(/(^[a-z])/i, (str, firstLetter) => firstLetter.toUpperCase());
}

/**
 * add ordinal suffix to number
 *
 * @private
 * @param {number} n
 * @returns {string}
 * @memberof Utils
 */
export function ordinal(n: number) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

/**
 * returns a string readable in 12 hour time format
 *
 * @param {string} time
 * @returns {string}
 * @memberof Utils
 */
export function get12HourFormatTime(time: string) {
  if (!isNaN(new Date(time).getTime())) {
    return format(new Date(time), 'h:mm a')
  } else {
    return format(new Date(setDateWith24HourFormatTime(time)), 'h:mm a');
  };
}

/**
 * returns an array with n number of items
 *
 * @param {number} n
 * @returns {number[]}
 * @memberof Utils
 */
export function arrayWithNItems(n: number) {
  return Array.from({ length: n }, (_, i) => i + 1);
}

/**
 * returns an array of time strings in nth minute intervals
 *
 * @param {string} n
 * @returns {number}
 * @memberof Utils
 */
export function everyNMinutes(n: number) {
  const result = [];
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes = minutes + n) {
      let h = '',
        m = '';
      h = hours < 10 ? `0${hours}` : hours.toString();
      m = minutes < 10 ? `0${minutes}` : minutes.toString();
      result.push(h + ':' + m);
    }
  }
  return result;
}

/**
 * safely and fast parsing for js objects
 *
 * @private
 * @param {string} str
 * @returns
 * @memberof Utils
 */
export function fastParse<T>(dat: T): T {
  return dat ? JSON.parse(JSON.stringify(dat)) : dat;
}

/**
 * safely parse numbers from a string
 *
 * @private
 * @param {string} str
 * @returns
 * @memberof Utils
 */
export function safeParseNum(str: string) {
  return Number(str) || null;
}

/**
 * safely parse booleans from a string
 *
 * @private
 * @param {} str
 * @returns
 * @memberof Utils
 */
export function safeParseBoolean(str) {
  const isBool = typeof str === 'boolean';
  if (isBool) {
    return str;
  }
  try {
    return JSON.parse(str);
  } catch (error) {
    return str;
  }
}
/**
 * safely parse null from a string
 *
 * @private
 * @param {string} str
 * @returns
 * @memberof Utils
 */
export function safeParseNull(str: string) {
  try {
    return str === 'null' ? null : str;
  } catch (error) {
    return str;
  }
}

/**
 * et the local time zone offset for a particular date and time using getTimezoneOffset
 *
 * @export
 * @returns
 */
export function getCurrentTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * checks if the lens is a parent lens
 *
 * @private
 * @param {string} parent
 * @param {string} len
 * @returns boolean
 */
export function isParentLens(id: string): boolean {
  return id === '0' || isNil(id);
}

/**
 * gets a system lens
 *
 * @param {string} parent
 * @param {string[]} alarmsStatus
 * @returns string
 */
export function getSystemLens(child: string, alarmsStatus: string[]): string {
  return head(
    Object.values(alarmsStatus).filter(
      (d) => d.toLowerCase() === child.toLowerCase()
    )
  );
}

/**
 * checks if the lens is a Nuisance lens
 *
 * @param {string} child
 * @param {string} parent
 * @returns boolean
 */
export function isNuisanceLens(child: string, parent: string): boolean {
  return child.toLowerCase() === 'main' && parent.toLowerCase() === 'nuisance';
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function isDifferent(object, base) {
  return JSON.stringify(object) !== JSON.stringify(base);
}

/**
 * return if 2 objects are the same
 *
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {boolean}
 */
export function compareObject(a, b) {
  // add case for comparing arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  if (typeof a == 'object' && a != null && typeof b == 'object' && b != null) {
    const count = [0, 0];
    for (const _ in a) count[0]++;
    for (const _ in b) count[1]++;
    if (count[0] - count[1] != 0) {
      return false;
    }
    for (const key in a) {
      if (!(key in b) || !compareObject(a[key], b[key])) {
        return false;
      }
    }
    for (const key in b) {
      if (!(key in a) || !compareObject(b[key], a[key])) {
        return false;
      }
    }
    return true;
  } else {
    return a === b;
  }
}

/**
 * deep compare object equality
 *
 * @export
 * @param {*} a
 * @param {*} b
 * @returns
 */
export function deepCompareObject(a, b) {
  if (a === b) {
    return true;
  }

  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
    return a === b;
  }

  if (a.prototype !== b.prototype) {
    return false;
  }

  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) {
    return false;
  }

  return keys.every((k) => deepCompareObject(a[k], b[k]));
}

/**
 * find by key val in an array using deep comparison
 *
 * @export
 * @template T
 * @param {T[]} arr
 * @param {string} key
 * @param {(string | number | boolean)} value
 * @param {string} nestingKey
 * @param {string} [currentPath='']
 * @returns {T}
 */
export function deepFind<T>(
  arr: T[],
  key: string,
  value: string | number | boolean,
  nestingKey: string,
  currentPath = ''
): T {
  return arr.reduce((a, item, i) => {
    if (a) return a;
    if (item[key] && item[key] === value) {
      return { ...item, path: `${currentPath}[${i}]` };
    }
    if (item[nestingKey]) {
      return deepFind(
        item[nestingKey],
        key,
        value,
        nestingKey,
        `${currentPath}[${i}].${nestingKey}`
      );
    }
  }, null);
}

/**
 * converts 24 hour time to 12 hour time
 *
 * @export
 * @param {string} time
 * @returns
 */
export function convertHours(time: string, returnDate = false) {
  const today = format(new Date(), 'MM/d/yyyy');
  const date = new Date(`${today} ${time}`);
  if (returnDate) {
    return date;
  }
  return format(date, 'h:mm a');
}

/**
 * download a blob file
 *
 * @export
 * @param {Blob} file
 * @param {string} fileName
 */
export function downloadFile(file: Blob, fileName: string) {
  const objectUrl = URL.createObjectURL(file);
  const element = document.createElement('a');
  element.href = objectUrl;
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  element.remove();
}

/**
 * Map work ticket to tile
 *
 * @export
 * @param {FastWorkTicket} wt
 * @returns {Tile}
 */
export function MapWorkTicketToTile(wt: FastWorkTicket): Tile {
  const closed = wt.status !== WorkTicketStatus.Open;
  return {
    tileTitle: wt.objective,
    tileDisplayID: wt.displayId,
    tileID: wt.id,
    dateTime: wt.dueAt,
    tileSubText: [],
    closed,
    status: wt.status,
    top1Icon:
      wt.cmmsSyncStatus === WorkTicketCmmsSyncStatus.Failed &&
      isNull(wt.cmmsSyncFailureNotificationDismissedBy) &&
      isNull(wt.cmmsSyncFailureNotificationDismissedAt)
        ? WorkTicketCMMSFailIcon
        : closed
          ? WorkTicketCloseIcon
          : WorkTicketOpenIcon,
    assignee: wt.assignee,
    assigneeAvatarUrl: wt.assigneeAvatarUrl,
    totalCount: wt.alarms.length,
    cmmsSyncStatus: wt.cmmsSyncStatus,
    cmmsHeaderHasBeenDismissed:
      !isNull(wt.cmmsSyncFailureNotificationDismissedBy) &&
      !isNull(wt.cmmsSyncFailureNotificationDismissedAt),
    isExternalSystemActive: wt.isExternalSystemActive,
  } as Tile;
}

/**
 * format work ticket properties to base alarm property
 *
 * @export
 * @template T
 * @param {string} base
 * @param {T} obj
 * @param {string} type
 * @returns
 */
export function getWorkTileProperties<T>(base: string, obj: T, type: string) {
  const values = obj[base].filter((d) => d.type === type).map((r) => r.value);
  return { type, values };
}

/**
 * format alarm properties to base alarm property
 *
 * @export
 * @template T
 * @param {string} base
 * @param {T} obj
 * @param {string} type
 * @returns
 */
export function getFormattedAlarmProperties<T>(
  base: string,
  obj: T,
  type: string
) {
  const prop = obj[base].find((p) => p.type === type);
  return { type, values: prop?.values };
}

/**
 * Map fast alarm to tile
 * @param alarm
 * @param tile
 * @returns
 */
export function MapFastAlarmToTile(alarm: FastAlarm, tile?: Tile): Tile {
  const buildingNames = alarm.building;
  const floorNames = alarm.floor;
  const roomNames = null;
  return getMappedAlarm(alarm, buildingNames, floorNames, roomNames, tile);
}

/**
 * Map core alarm to tile
 *
 * @export
 * @param {CoreAlarm} alarm
 */
export function MapAlarmToTile(alarm: CoreAlarm, tile?: Tile): Tile {
  const buildings = getBuildings<CoreAlarm>('properties', alarm);
  const floors = getFloors<CoreAlarm>('properties', alarm);
  const roomNum = getRoomNum<CoreAlarm>('properties', alarm);
  const roomName = getRoomName<CoreAlarm>('properties', alarm);
  const rooms = !isEmpty(roomNum) ? roomNum : roomName;
  const buildingNames = getBuildingNames(buildings);
  const floorNames = getFloorNames(floors);
  const roomNames = getRoomNames(rooms);
  return getMappedAlarm(alarm, buildingNames, floorNames, roomNames, tile);
}
/**
 * get room names
 *
 * @export
 * @template T
 * @param {string} base
 * @param {T} obj
 * @returns
 */
export function getRoomName<T>(base: string, obj: T) {
  return obj[base]?.find((d) => d.type === 'room_name' && d.values.length);
}

/**
 * get room numbers
 *
 * @export
 * @template T
 * @param {string} base
 * @param {T} obj
 * @returns
 */
export function getRoomNum<T>(base: string, obj: T) {
  return obj[base]?.find((d) => d.type === 'room_number' && d.values.length);
}

/**
 * get floors
 *
 * @export
 * @template T
 * @param {string} base
 * @param {T} obj
 * @returns
 */
export function getFloors<T>(base: string, obj: T) {
  return obj[base]?.find((d) => d.type === 'floor');
}

/**
 * get buildings
 *
 * @export
 * @template T
 * @param {string} base
 * @param {T} obj
 * @returns
 */
export function getBuildings<T>(base: string, obj: T) {
  return obj[base]?.find((d) => d.type === 'building');
}

/**
 * get building names
 *
 * @export
 * @param {BaseAlarmProperty} buildings
 * @returns
 */
export function getBuildingNames(buildings: BaseAlarmProperty) {
  let building = '';
  if (buildings) {
    building =
      buildings?.values?.length > 1
        ? MultipleAlarmProperty
        : head(buildings.values) || '-';
  } else {
    building = '-';
  }
  return building;
}

/**
 * get floor names
 *
 * @export
 * @param {BaseAlarmProperty} floors
 * @returns
 */
export function getFloorNames(floors: BaseAlarmProperty) {
  let floor = '';
  if (floors) {
    floor =
      floors.values.length > 1
        ? MultipleAlarmProperty
        : head(floors.values) || '-';
  } else {
    floor = '-';
  }
  return floor;
}

/**
 * get room values from room names and number
 *
 * @export
 * @param {(BaseAlarmProperty | void)} rooms
 * @returns
 */
export function getRoomNames(rooms: BaseAlarmProperty | void) {
  let room = '';
  if (rooms) {
    room =
      rooms.values.length > 1
        ? MultipleAlarmProperty
        : head(rooms.values) || '-';
  } else {
    room = '-';
  }
  return room;
}

/**
 * map priority related alarm tile properties
 *
 * @export
 * @param {CoreAlarm} alarm
 * @returns
 */
export function mapAlarmTilePriority(priority: number) {
  return {
    priorityIcon: AlarmPriorityIcon,
    priorityIconColor: result(
      AlarmPriorityColors,
      `${priority}`,
      AlarmPriorityColors[1]
    ),
    priority,
  };
}

/**
 * map icon related properties for alarm tiles
 *
 * @export
 * @param {CoreAlarm} alarm
 * @returns
 */
export function mapAlarmTileIcons(alarm: {
  isPinned: boolean;
  isStale: boolean;
  nuisanceCount: number;
  lastWorkTicketClosedAt?: Date;
  activeWorkCount: number;
}) {
  return {
    pinned: alarm.isPinned,
    top1Icon: '',
    top2Icon: alarm.isStale ? StaleIcon : '',
    top3Icon: alarm.nuisanceCount > 0 ? NuisanceIcon : '',
    top4Icon: getAlarmWorkIcon(alarm),
    level3Icon: '',
    level2Icon: alarm.isPinned ? AlarmPinnedIcon : '',
  };
}

/**
 * returns work icon
 *
 * @export
 * @param {CoreAlarm} alarm
 * @returns
 */
export function getAlarmWorkIcon(alarm: {
  lastWorkTicketClosedAt?: Date;
  activeWorkCount: number;
}) {
  let icon = '';
  const sevenDays = subDays(new Date(), 7);
  const closedWithinPeriod = isAfter(
    new Date(alarm.lastWorkTicketClosedAt),
    sevenDays
  );
  if (alarm.activeWorkCount > 0) {
    icon = `fas ${WorkTicketIcon}`;
  } else {
    if (isNull(alarm.lastWorkTicketClosedAt)) {
      icon = '';
    } else if (closedWithinPeriod) {
      icon = `far ${WorkTicketIcon}`;
    }
  }
  return icon;
}

/**
 * map core info for alarm tiles
 *
 * @export
 * @param {CoreAlarm} alarm
 * @param {string} buildingNames
 * @param {string} floorNames
 * @param {string} roomNames
 * @returns
 */
export function mapAlarmTileInfo(
  alarm: {
    contextualName: string;
    formattedRawText?: string;
    id?: string | number;
    latestAlarmTime?: string;
    latestEndTime?: string;
    status: string;
    nuisanceCount: number;
    state?: string;
    entityIds?: number[];
    entities?: AlarmEntity[];
  },
  buildingNames: string,
  floorNames: string,
  roomNames: string,
  tile?: Tile
) {
  return {
    tileTitle: alarm.contextualName,
    altTitle: alarm.contextualName ? null : NoAlarmTitle,
    rawTitle: alarm.formattedRawText,
    tileID: alarm.id,
    tileDisplayID: tile?.tileDisplayID || `A${alarm.id}`,
    dateTime: alarm.latestAlarmTime,
    endDateTime: alarm.latestEndTime,
    tileSubText: [buildingNames, floorNames, roomNames],
    selected: tile?.selected || false,
    status: lowerFirst(alarm.status),
    checked: tile?.checked || false,
    loading: false,
    chatteringFleetingTotal: alarm.nuisanceCount,
    entityIds: alarm?.entityIds,
    entities: alarm?.entities,
    footer:
      alarm.state && ALARM_TILE_ALARM_STATE_DISPLAY_LIST.includes(alarm.state)
        ? ALARM_STATE_TO_TEXT_MAP.get(alarm.state)
        : null,
  };
}

/**
 * returns full mapped alarm tile
 *
 * @export
 * @param {CoreAlarm} alarm
 * @param {string} buildingNames
 * @param {string} floorNames
 * @param {string} roomNames
 * @returns
 */
export function getMappedAlarm(
  alarm: CoreAlarm | FastAlarm,
  buildingNames: string,
  floorNames: string,
  roomNames: string,
  tile?: Tile
) {
  return {
    ...mapAlarmTileInfo(alarm, buildingNames, floorNames, roomNames, tile),
    ...mapAlarmTilePriority(alarm.priority),
    ...mapAlarmTileIcons(alarm),
  };
}

/**
 * map associated alarms
 *
 * @export
 * @param {WorkTicket} workTickets
 * @returns
 */
export function mapAssociatedAlarms(workTickets: WorkTicket) {
  return workTickets.alarms.map((alarm) => MapAlarmToTile(alarm));
}

/**
 * get current year in xxxx format
 *
 * @export
 * @returns {number}
 */
export function getCurrentYear(): number {
  return new Date().getFullYear();
}

/**
 * handles blanks and returns valid value
 *
 * @export
 * @param {string} value
 * @param {string} validValue
 * @returns {string}
 */
export function handleBlank(value: string, validValue: string): string {
  return isNil(value) || value === '' ? validValue : value;
}

/**
 * convert utc epoch dates
 *
 * @export
 * @param {number} utc
 * @returns
 */
export function convertUTCEpochDate(utc: number) {
  if (utc) {
    const date = new Date(0);
    date.setUTCSeconds(utc);
    return date;
  }
  return null;
}

/**
 * navigate to deeplink
 *
 * @export
 * @returns
 */
export function navigateToDeeplink() {
  const deepLink = localStorage.getItem('vfi-deepLink');
  if (deepLink) {
    localStorage.removeItem('vfi-deepLink');
    return (window.location.href = deepLink);
  } else {
    return (window.location.href = '/');
  }
}

/**
 * convert ms to readable format
 *
 * @export
 * @param {number} millisec
 * @returns
 */
export function readableMS(millisec: number) {
  const seconds = (millisec / 1000).toFixed(2);
  const minutes = (millisec / (1000 * 60)).toFixed(2);
  const hours = (millisec / (1000 * 60 * 60)).toFixed(2);
  const days = (millisec / (1000 * 60 * 60 * 24)).toFixed(2);
  if (+seconds < 60) {
    return seconds + ' sec';
  } else if (+minutes < 60) {
    return minutes + ' min';
  } else if (+hours < 24) {
    return hours + ' hrs';
  } else {
    return days + ' days';
  }
}

/**
 * format readable days, hours and minutes
 *
 * @export
 * @param {number} ms
 * @returns
 */
export function readableMSDaysHoursMinutes(ms: number) {
  if (ms > 0) {
    const time = '';
    const timeValues = calculateReadableMSTime(ms);
    return formatReadableDaysHoursMinutes(timeValues, time);
  }
  return '0m';
}

/**
 * format readable dates for days and hours
 *
 * @export
 * @param {TimeValues} timeValues
 * @param {string} time
 * @returns
 */
export function formatReadableDaysHoursMinutes(
  timeValues: TimeValues,
  time: string
) {
  if (timeValues.days > 0) {
    time = `${time} ${timeValues.d} ${timeValues.h}`;
  } else if (timeValues.days <= 0 && timeValues.hours > 0) {
    time = `${time} ${timeValues.h} ${timeValues.m}`;
  } else if (timeValues.hours <= 0 && timeValues.minutes > 0) {
    time = `${time} ${timeValues.m}`;
  } else if (timeValues.minutes === 1 || timeValues.seconds > 0) {
    time = `1m`;
  }
  return time;
}

/**
 * format readable dates for hours and seconds
 *
 * @export
 * @param {TimeValues} timeValues
 * @param {string} time
 * @returns
 */
export function formatReadableHoursSeconds(
  timeValues: TimeValues,
  time: string
) {
  if (timeValues.hours <= 0 && timeValues.minutes > 0) {
    time = `${time} ${timeValues.m} ${timeValues.s}`;
  }
  if (timeValues.minutes <= 0 && timeValues.seconds > 0) {
    time = `${time} ${timeValues.s}`;
  }
  return time;
}

/**
 * get full readable ms h-d-m-s
 *
 * @export
 * @param {number} ms
 * @returns
 */
export function getReadableMS(ms: number) {
  if (!isNil(ms) && ms > 0) {
    const time = '';
    const timeValues = calculateReadableMSTime(ms);
    return formatReadableMS(timeValues, time);
  }
  return '—';
}

/**
 * format full readable ms h-d-m-s
 *
 * @export
 * @param {TimeValues} timeValues
 * @param {string} time
 * @returns
 */
export function formatReadableMS(timeValues: TimeValues, time: string) {
  if (timeValues.days > 0) {
    time = `${time} ${timeValues.d} ${timeValues.h}`;
  }
  if (timeValues.days <= 0 && timeValues.hours > 0) {
    time = `${time} ${timeValues.h} ${timeValues.m}`;
  }
  return formatReadableHoursSeconds(timeValues, time);
}

/**
 * calculate time values for readable MS short time format
 *
 * @export
 * @param {number} ms
 * @returns
 */
export function calculateReadableMSTime(ms: number): TimeValues {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const seconds = Math.floor(minutesms / 1000);

  const d = days > 0 ? `${days}d` : '';
  const h = hours > 0 ? `${hours}h` : '';
  const m = minutes > 0 ? `${minutes}m` : '';
  const s = seconds > 0 ? `${seconds}s` : '';

  return {
    days,
    daysms,
    hours,
    hoursms,
    minutes,
    minutesms,
    seconds,
    d,
    h,
    m,
    s,
  };
}

/**
 * Returns the amount of items within the range
 *
 * @export
 * @param {DatetimeRange} { from, to }
 * @param {Granularity} granularity
 * @returns
 */
export function timeFrameToGranularity(
  { from, to }: DatetimeRange,
  granularity: Granularity
) {
  const ms = new Date(to).valueOf() - new Date(from).valueOf();
  switch (granularity) {
    case Granularity.MONTH:
      return Math.ceil(ms / (12 * 7 * 24 * 60 * 60 * 1000));
    case Granularity.WEEK:
      return Math.ceil(ms / (7 * 24 * 60 * 60 * 1000));
    case Granularity.DAY:
      return Math.ceil(ms / (24 * 60 * 60 * 1000));
    case Granularity.HOUR:
      return Math.ceil(ms / (60 * 60 * 1000));
    default:
      return 0;
  }
}

/**
 * convert ms to readable hours format
 *
 * @export
 * @param {number} ms
 * @returns {TimeValues}
 */
export function msToHours(ms: number): TimeValues {
  let seconds = ms / 1000;
  let minutes = Math.floor(seconds / 60);
  let hours;
  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    hours = hours >= 10 ? hours : 0 + hours;
    minutes = minutes - hours * 60;
    minutes = minutes >= 10 ? minutes : 0 + minutes;
  }
  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : 0 + seconds;

  return {
    hours,
    minutes,
    seconds,
  };
}

/**
 * populate work details form
 *
 * @export
 * @param {WorkTicket} ticket
 * @param {UntypedFormGroup} form
 * @returns
 */
export function populateWorkDetailsForm(
  ticket: WorkTicket,
  form: UntypedFormGroup
) {
  form.setValue({
    objective: ticket.objective,
    problemDetails: ticket.problemDetails,
    assignedTo: result(ticket, 'assignee.id'),
    dueBy: new Date(ticket.dueAt),
  });
  form.markAsPristine();
  form.updateValueAndValidity();

  return form;
}

/**
 * populate additional work details form
 *
 * @export
 * @param {WorkOrder} val
 * @param {UntypedFormGroup} form
 * @returns
 */
export function populateAdditionalWorkDetailsValues(
  val: WorkOrder,
  form: UntypedFormGroup
) {
  keys(form.value).forEach((field) => {
    if (val) {
      form.controls[field].setValue(val[field]);
      form.controls[field].clearValidators();
      form.controls[field].updateValueAndValidity();
    }
  });
  return form;
}

/**
 * update work details for update work query
 *
 * @export
 * @param {UntypedFormGroup} form
 * @returns
 */
export function formatUpdateWorkTicketDetails(form: UntypedFormGroup) {
  let details = {};
  keys(form.controls).forEach((name) => {
    const control = form.controls[name];
    if (control.dirty) {
      details = { ...details, [name]: control.value };
    }
  });
  return details;
}

/**
 * format affected space details for update work query
 *
 * @export
 * @param {UntypedFormGroup} form
 * @returns
 */
export function formatUpdateSpaces(form: UntypedFormGroup) {
  return keys(form.controls).map((r) => ({
    type: r,
    values: form.controls[r].value || [],
  }));
}

/**
 * return percentage value based on pre reoccurences and post reoccurences for work history tables
 *
 * @param {number} pre
 * @param {number} post
 * @param {string} closedAt
 * @returns
 */
export function getReductionPercentage(
  pre: number,
  post: number,
  closedAt: string
) {
  let percentage = 100 * (1 - post / pre);
  if (percentage % 1 === 0) {
    percentage = parseFloat(percentage.toFixed(1));
  }
  let percent = `${Math.round(percentage)}%`;

  if (pre === 0 || isNull(closedAt) || (isNull(pre) && isNull(post))) {
    percent = '—';
  }
  if (post === 0) {
    percent = '100%';
  }
  if (pre === 0 && post === 0) {
    percent = '0%';
  }
  return percent;
}

/**
 * returns property values as string
 *
 * @param {CoreAlarm} alarm
 * @param {string} type
 * @returns
 */
export function getPropertyValues(alarm: CoreAlarm, type: string) {
  if (alarm) {
    const prop = alarm.properties.find((p) => p.type === type);
    if (prop) {
      const values = prop?.values?.join(', ');
      return prop?.values.length ? `- ${values}` : values;
    }
  }
  return '';
}

/**
 * format dates for disabled fields
 *
 * @export
 * @param {Date} date
 * @returns
 */
export function formatDisabledDates(date: Date | string) {
  if (date) {
    return [format(new Date(date), 'MM/dd/yyyy p')];
  }
  return [];
}
/**
 * catch errors from API that have 200 status code
 *
 * @export
 * @template T
 * @param {T} data
 * @return {*}  {T}
 */
export function catchUndisclosedErrors<T>(data: T): T {
  if (data['errors']?.length) {
    throw new Error(data['errors'][0]);
  }
  return data;
}

/**
 * set autofocus for content editable input
 *
 * @export
 * @param {string} type
 * @param {boolean} setCursor
 */
export function autoFocusContentEditable(type: string, setCursor: boolean) {
  setTimeout(() => {
    const x = document.getElementById(type);
    x.setAttribute('tabindex', '1');
    x.focus();
    if (setCursor) {
      setEndOfContenteditable(x);
    }
  }, 0);
}

/**
 * set cursor to end on content editable input
 *
 * @export
 * @param {HTMLElement} contentEditableElement
 */
export function setEndOfContenteditable(contentEditableElement) {
  if (document.createRange) {
    const elm = contentEditableElement as Node;
    // Firefox, Chrome, Opera, Safari, IE 9+
    const range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(elm); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    const selection = window.getSelection(); // get the selection object (allows you to change selection)
    selection.removeAllRanges(); // remove any selections already made
    selection.addRange(range); // make the range you have just created the visible selection
  }
}

/**
 * format work resolution ticket time
 *
 * @export
 * @param {*} dates
 * @returns
 */
export function formatWorkResolutionTicketTime(dates) {
  const closedTime = { from: '', to: '' };
  if (typeof dates === 'number') {
    closedTime.from = startOfDay(subDays(new Date(), dates)).toISOString();
    closedTime.to = endOfDay(new Date()).toISOString();
  } else {
    closedTime.from = startOfDay(new Date(dates[0])).toISOString();
    closedTime.to = endOfDay(new Date(dates[1])).toISOString();
  }
  return closedTime;
}

/**
 * format work resolution percentage
 *
 * @export
 * @param {number} prev
 * @param {number} curr
 * @returns
 */
export function formatResolutionReductionChange(prev: number, curr: number) {
  const percent = (prev / curr) * 100;
  return !isFinite(percent) || isNaN(percent) ? 0 : Math.round(percent);
}

/**
 * return rate of change percentage
 *
 * @export
 * @param {number} prev
 * @param {number} curr
 * @returns
 */
export function formatRateOfChange(prev: number, curr: number) {
  const percent = ((prev - curr) / prev) * -100;
  return !isFinite(percent) || isNaN(percent) ? 0 : Math.round(percent);
}

/**
 * returns percentae for confidence
 *
 * @export
 * @returns
 */
export function getConfidencePercentValue(percentage: number) {
  return isNil(percentage) || percentage === 0
    ? `—`
    : `${Math.floor(percentage * 100)}%`;
}

/**
 * custom validator for validating URLs
 *
 * @export
 * @param {FormControl} control
 * @returns
 */
export function urlValidator(control: FormControl) {
  if (!isURL(control.value)) {
    return { url: 'Invalid url' };
  }
  return null;
}

/**
 * returns values for special options for multi select dropdown
 *
 * @export
 * @param {string} label
 * @param {string} name
 * @returns
 */
export function getSpecialValues(label: string, name: string) {
  let val = label;
  if (label === `All ${name}s`) {
    val = SELECT_ALL_VALUE;
  } else if (label === BLANK_VALUE_TEXT) {
    val = BLANK_VALUE;
  }
  return val;
}

/**
 * returns labels for special options for multiselect
 *
 * @export
 * @param {string} value
 * @param {string} name
 * @returns
 */
export function getSpecialLabels(value: string, name: string) {
  let val = value;
  if (value === SELECT_ALL_VALUE) {
    val = `All ${name}s`;
  } else if (value === BLANK_VALUE) {
    val = BLANK_VALUE_TEXT;
  }
  return val;
}

/**
 * make a timeline of from and to by given day
 *
 * @private
 * @param {number} days
 * @return {*} DatetimeRange
 */
export function lastXDaysTimeline(days: number): DatetimeRange {
  const from = startOfDay(subDays(new Date(), days)).toISOString();
  const to = endOfDay(new Date()).toISOString();
  return { from, to };
}

/**
 * generate prev timeline by days
 *
 * @private
 * @param {number} days
 * @returns {DatetimeRange}
 */
export function lastXDaysPrevTimeline(days: number): DatetimeRange {
  const to = endOfDay(subDays(new Date(), days)).toISOString();
  const from = startOfDay(subDays(new Date(to), days)).toISOString();
  return { from, to };
}

/**
 * generate a timeline from a given date
 *
 * @export
 * @param {number} days
 * @param {Date} toDate
 * @returns {DatetimeRange}
 */
export function lastXTimelineByDate(days: number, toDate: Date): DatetimeRange {
  const to = endOfDay(subDays(new Date(toDate), days)).toISOString();
  const from = startOfDay(subDays(new Date(to), days)).toISOString();
  return { from, to };
}

/**
 * format filter timeline by given date
 *
 * @export
 * @param {Date} toTime
 * @param {Date} fromTime
 * @returns {DatetimeRange}
 */
export function generateFilterTimelineByDate(
  toTime: Date,
  fromTime: Date
): DatetimeRange {
  const to = endOfDay(new Date(toTime)).toISOString();
  const from = startOfDay(new Date(fromTime)).toISOString();
  return { from, to };
}

/**
 * Converts date from UTC to local time
 *
 * @param {Date} date
 * @returns {Date}
 */
export function convertDate(date: Date): Date {
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
}

/**
 * fetch date range to end of range
 *
 * @export
 * @param {*} data
 * @param {string} type
 * @returns
 */
export function fetchDateRange(data, type: string) {
  let endOf;
  if (type === 'week') {
    endOf = endOfWeek;
  } else if (type === 'month') {
    endOf = endOfMonth;
  }
  const start = format(convertDate(new Date(data.x)), 'MMM dd');
  const endRange = format(endOf(convertDate(new Date(data.x))), 'MMM dd');
  const range = `${start} - ${endRange} <br />
    <b>${data.point.series.name}:</b> ${data.y}`;

  return {
    date: start,
    range,
  };
}

/**
 * get lookback date range
 *
 * @export
 * @param {DatetimeRange} date
 * @returns {DatetimeRange}
 */
export function getLookbackDate(date: DatetimeRange): DatetimeRange {
  const dayDifference = differenceInDays(
    new Date(date.to),
    new Date(date.from)
  );
  const to = endOfDay(subDays(new Date(date.from), 1));
  const from = startOfDay(subDays(to, dayDifference));
  return { from: from.toISOString(), to: to.toISOString() };
}

/**
 * remove null or undefined values from an object
 *
 * @param {object} object
 */
export const removeEmptyValues = (object: object) =>
  JSON.parse(JSON.stringify(object, (_, value) => value ?? undefined));

/**
 * add/remove group ID from criteria builder
 *
 * @export
 * @param {GroupCriteriaInput} criteria
 * @param {boolean} [removeGroupId=false]
 * @returns
 */
export function handleCriteriaBuilderGroupId(
  criteria: GroupCriteriaInput,
  removeGroupId = false
) {
  if (criteria.clause === GroupCriteriaClause.WHERE) {
    const formattedCriteria = formatAutomationFilters(fastParse(criteria));
    return removeGroupId
      ? omit(formattedCriteria, ['groupId'])
      : { ...formattedCriteria };
  }
  return {
    ...criteria,
    data: criteria.data.map((crit, index) =>
      helperAssignGroupId(crit, removeGroupId, '' + index, criteria)
    ),
  };
}

/**
 * helper for assigning/removing group ID
 *
 * @export
 * @param {GroupCriteriaInput} criteria
 * @param {boolean} [removeGroupId=false]
 * @param {string} [groupId='0']
 * @returns
 */
export function helperAssignGroupId(
  criteria: GroupCriteriaInput,
  removeGroupId = false,
  groupId = '0',
  parent: GroupCriteriaInput
) {
  if (criteria.clause === GroupCriteriaClause.WHERE) {
    const formattedCriteria = formatAutomationFilters(fastParse(criteria));
    const idGroup =
      parent?.groupId &&
      parent?.data.every((d) => d?.clause === GroupCriteriaClause.WHERE)
        ? {}
        : { groupId };
    return removeGroupId
      ? omit(formattedCriteria, ['groupId'])
      : { ...formattedCriteria, ...idGroup };
  }
  criteria = { ...criteria, groupId };
  const formatted = {
    ...criteria,
    data: criteria.data.map((crit, index) =>
      helperAssignGroupId(crit, removeGroupId, groupId + index, criteria)
    ),
    groupId,
  };
  return removeGroupId ? omit(formatted, ['groupId']) : formatted;
}

/**
 * format automation where clause for specific properties
 *
 * @export
 * @param {GroupCriteriaInput} criteria
 * @returns
 */
export function formatAutomationFilters(criteria: GroupCriteriaInput) {
  const type = Object.keys(criteria).find(
    (k) => k === 'where' || k === 'whereNot'
  );
  const key = Object.keys(criteria[type])[0];
  if (key === 'priorities') {
    criteria[type]['priorities'] = criteria[type]['priorities'].map((p) => +p);
  } else if (key === 'isNuisance') {
    criteria[type] = formatNuisance(criteria[type]['isNuisance']);
  } else if (key === 'ids') {
    criteria[type] = {
      ids: criteria[type]['ids'].map((id) =>
        typeof id === 'string' && id?.charAt(0) === 'A' ? +id.slice(1) : id
      ),
    };
  } else if (NUISANCE_FILTER_KEYS.includes(key)) {
    criteria[type] = {
      isNuisance: formatNuisanceKey(criteria[type]),
    };
  } else if (key === 'alarmTypes') {
    criteria[type]['alarmTypes'] = criteria[type]['alarmTypes'].map((type) =>
      type === 'null' ? null : type
    );
  }
  return criteria;
}

/**
 * format Nuisance Alarm default sort
 *
 * @export
 * @param {nuisanceType} type
 * @returns {CoreAlarmsWhere}
 */
export function formatNuisance(type: nuisanceType): CoreAlarmsWhere {
  let where = {};
  switch (type) {
    case nuisanceType.CHATTERING:
      where = { minChattering: 1, maxFleeting: 0 };
      break;
    case nuisanceType.FLEETING:
      where = { minFleeting: 1, maxChattering: 0 };
      break;
    case nuisanceType.CHATTERING_AND_FLEETING:
      where = { minFleeting: 1, minChattering: 1 };
      break;
    case nuisanceType.CHATTERING_OR_FLEETING:
      where = { minNuisance: 1 };
      break;
    case nuisanceType.NONE:
      where = { maxChattering: 0, maxFleeting: 0 };
      break;
  }
  return where;
}

/**
 * get nuisance key based on where clause
 *
 * @export
 * @param {*} where
 * @returns {nuisanceType}
 */
export function formatNuisanceKey(where): nuisanceType {
  let key;

  if (isEqual(where, { minChattering: 1, maxFleeting: 0 })) {
    key = nuisanceType.CHATTERING;
  } else if (isEqual(where, { minFleeting: 1, maxChattering: 0 })) {
    key = nuisanceType.FLEETING;
  } else if (isEqual(where, { minFleeting: 1, minChattering: 1 })) {
    key = nuisanceType.CHATTERING_AND_FLEETING;
  } else if (isEqual(where, { minNuisance: 1 })) {
    key = nuisanceType.CHATTERING_OR_FLEETING;
  } else if (isEqual(where, { maxChattering: 0, maxFleeting: 0 })) {
    key = nuisanceType.NONE;
  }

  return key;
}

/**
 * return if criteria is the default builder criteria
 *
 * @export
 * @param {GroupCriteriaInput} criteria
 * @returns
 */
export function isDefaultBuilderCriteria(criteria: GroupCriteriaInput) {
  return (
    (Object.keys(criteria).length === 1 &&
      criteria?.clause === GroupCriteriaClause.WHERE) ||
    (criteria?.data && !criteria.data.length)
  );
}

/**
 * format tooltip for work resolution trend widget
 *
 * @export
 * @param {*} data
 * @returns
 */
export function formatWorkResolutionTrendTooltip(data) {
  const options = data.series.options.chartOptions;
  const index = data.point.index;
  const series = data.series.name;
  const percentValues =
    series === 'Resolved'
      ? options.yAxisValues.resolvedPercent
      : options.yAxisValues.unresolvedPercent;
  const countValues =
    series === 'Resolved'
      ? options.yAxisValues.resolvedCount
      : options.yAxisValues.unresolvedCount;
  const dateFormat =
    options?.granularity === Granularity.HOUR ? 'MM/d p' : 'MM/d/y';

  return `${format(new Date(data.x), dateFormat)} <br /> Split: ${Math.round(
    percentValues[index]
  )}% <br /> Count: ${countValues[index]}`;
}

/**
 * calculate change in metrics
 *
 * @private
 * @param {number} current
 * @param {number} lookback
 * @returns
 */
export function calculateMetricsChange(current: number, lookback: number) {
  return (current - lookback) / lookback;
}

/**
 * format period text based on selected date range
 *
 * @private
 * @param {DateTimeRangeChange} dateRange
 * @returns
 */
export function formatPeriodText(dateRange: DateTimeRangeChange) {
  let text = '';
  const dates = dateRange?.dates?.timeFrame;
  const period = dateRange?.range;
  if (period === 7 || period === 30) {
    text = `Last ${period} days`;
  } else if (period === 1) {
    text = 'Today';
  } else if (period === 0) {
    text = `${format(new Date(dates?.from), 'PP')} - ${format(
      new Date(dates?.to),
      'PP'
    )}`;
  }
  return text;
}

/**
 * generate range time window
 *
 * @private
 * @param {Date[]} range
 * @returns
 */
export function generateRangeTimeWindows(range: Date[]) {
  return {
    from: startOfDay(head(range)),
    to: endOfDay(last(range)),
  };
}

/**
 * returns filter text button based on criterion
 *
 * @export
 * @param {CriterionSelection[]} criterion
 * @param {boolean} showPills
 * @returns
 */
export function getFilterButtonText(
  criterion: CriterionSelection[],
  showPills: boolean
) {
  const text = showPills
    ? 'Hide filters'
    : criterion.length > 1 && !showPills
      ? 'Show Filters'
      : 'Add filters';
  const count =
    criterion.length > 1 && !showPills ? ` • ${criterion.length - 1}` : '';
  return text + count;
}

/**
 * set core criterion for buildings
 *
 * @export
 * @param {string[]} ev
 * @param {*} locations
 * @param {*} coreCriterion
 * @returns
 */
export function setCoreBuildingsCriterion(
  ev: string[],
  locations,
  coreCriterion
) {
  let buildings = [];
  if (ev.includes(SELECT_ALL_VALUE)) {
    buildings = ['All Buildings'];
  } else {
    ev.forEach((b) => {
      const properties = locations
        .find((l) => l?.id === b)
        ?.alarmProperties.map((p) => p?.value);
      buildings = uniq([...buildings, ...properties]);
    });
  }

  const core = fastParse(coreCriterion);

  core[0].model.selection.name = buildings;
  core[0].model.selection.value = buildings;
  core[0].detail.selection.name = buildings;
  core[0].detail.selection.value = buildings;

  return core;
}

/**
 * checks if criterion selections have an invalid value
 *
 * @export
 * @param {*} crit
 * @returns
 */
export function checkInvalidCriterion(crit) {
  const valid = crit
    .filter((c) => !isNull(c?.model))
    .map((r) => {
      const type = r?.model?.type;
      const selection = r?.model?.selection;
      if (type === 'minMax' || type === 'dateTime') {
        return selection.min && selection.max;
      } else {
        return selection?.value.length > 0;
      }
    });

  return valid.length === 1 && !valid[0];
}

/**
 * show error header for cmms sync
 *
 * @export
 * @param {WorkTicket} wt
 * @returns {boolean}
 */
export function showErrorHeader(wt: WorkTicket): boolean {
  if (
    !isNull(wt?.cmmsSyncFailureNotificationDismissedAt) &&
    !isNull(wt?.cmmsSyncFailureNotificationDismissedBy)
  ) {
    return false;
  }
  if (!wt?.externalSystem?.isActive) {
    return false;
  }

  return (
    wt?.cmmsSyncStatus === WorkTicketCmmsSyncStatus.Failed ||
    wt?.cmmsSyncStatus === WorkTicketCmmsSyncStatus.InProgress
  );
}

/**
 * converts hours/minutes/seconds to milliseconds
 *
 * @export
 * @param {number} hrs
 * @param {number} min
 * @param {number} sec
 * @returns {number}
 */
export function toMS(hrs: number, min: number, sec: number): number {
  return (hrs * 60 * 60 + min * 60 + sec) * 1000;
}

/**
 * debounce method
 *
 * @param func
 * @param wait
 * @returns {Function}
 */
export function debounce<F extends (...params: any[]) => void>(
  fn: F,
  delay: number
) {
  let timeoutID: number = null;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

/**
 * truncate display name
 *
 * @export
 * @param {string} firstName
 * @param {string} lastName
 * @returns {string}
 */
export function truncateDisplayName(
  firstName: string,
  lastName: string
): string {
  if (firstName && lastName) {
    const dot = '.';
    let first = firstName;
    const last = `${lastName.substr(0, 1)}${dot}`;
    if (firstName.length > 4) {
      first = `${firstName.substring(0, 4)}${dot}`;
    }
    return `${first} ${last}`;
  }
  return '';
}

/**
 * truncate name as a single string
 *
 * @export
 * @param {string} name
 * @returns
 */
export function truncateFullName(name: string) {
  const nameParts = name.split(' ');
  const firstName = nameParts[0].slice(0, 4);
  const lastName = nameParts[nameParts.length - 1];

  return `${firstName}. ${lastName[0]}`;
}

/**
 * truncate text
 *
 * @export
 * @param {string} text
 * @param {number} [length=10]
 * @returns
 */
export function truncateText(text: string, length = 10) {
  return text.length > length ? `${text.substring(0, length)}...` : text;
}

/**
 * generate text for entity location
 *
 * @export
 * @param {Partial<Entity>} entity
 * @returns
 */
export function generateEntityLocationText(entity: Partial<Entity>) {
  const locations = [];
  getEntityBuildingValue(entity, locations);
  getEntityFloorValue(entity, locations);
  getEntityRoomValue(entity, locations);
  return locations.join(' | ');
}

/**
 * return entity building location value
 *
 * @export
 * @param {Partial<Entity>} entity
 * @param {string[]} locations
 */
export function getEntityBuildingValue(
  entity: Partial<Entity>,
  locations: string[]
) {
  let buildingName = null;
  if (entity?.class === EntityClass.Building) {
    buildingName = entity?.name;
  } else {
    buildingName = entity?.locationEntities.find(
      (e) => e?.class === EntityClass.Building
    )?.name;
  }
  if (buildingName) {
    locations.push(buildingName);
  }
}

/**
 * return entity floor location value
 *
 * @export
 * @param {Partial<Entity>} entity
 * @param {string[]} locations
 */
export function getEntityFloorValue(
  entity: Partial<Entity>,
  locations: string[]
) {
  let floorName = null;
  if (entity?.class === EntityClass.Floor) {
    floorName = entity?.name;
  } else {
    floorName = entity?.locationEntities.find(
      (e) => e?.class === EntityClass.Floor
    )?.name;
  }
  if (floorName) {
    floorName = isNaN(+floorName) ? floorName : `${floorName}F`;
    locations.push(floorName);
  }
}

/**
 * return entity room location value
 *
 * @export
 * @param {Partial<Entity>} entity
 * @param {string[]} locations
 */
export function getEntityRoomValue(
  entity: Partial<Entity>,
  locations: string[]
) {
  let roomName = null;
  if (entity?.class === EntityClass.Room) {
    roomName = entity?.roomName ? entity?.roomName : entity?.roomNumber;
  } else {
    roomName = entity?.locationEntities.find(
      (e) => e?.class === EntityClass.Room
    )?.name;
  }
  if (roomName) {
    roomName = addEntityRoomPrefix(roomName);
    locations.push(roomName);
  }
}

/**
 * add prefix to entity room name
 *
 * @export
 * @param {string} name
 * @returns
 */
export function addEntityRoomPrefix(name: string) {
  return name.split(' ')[0].toLowerCase() === 'Rm'.toLowerCase() || isNaN(+name)
    ? name
    : `Rm ${name}`;
}

/**
 * update entity option names based on class
 *
 * @export
 * @param {*} entities
 * @returns
 */
export function formatEntityOptionNames(entities) {
  if (entities.length) {
    if (entities[0].class === 'Floor') {
      return entities.map((e) => ({
        ...e,
        name: addEntityFloorSuffix(e?.name),
      }));
    }
    return entities;
  }
  return entities;
}

/**
 * add suffix to entity floor name
 *
 * @export
 * @param {string} name
 * @returns
 */
export function addEntityFloorSuffix(name: string) {
  return isNaN(+name) ? name : `${name}F`;
}

/**
 * case sensitive sorting for array
 * @param {any[]} arr
 * @param {string} key
 * @param {boolean} [asc=true]
 * @returns {any[]}
 */
export function caseSensitiveSort(arr: any[], key: string, asc = true) {
  if (!arr) {
    return [];
  }

  arr = [...arr];
  return arr.sort((a, b) => {
    if (asc) {
      return a[key].localeCompare(b[key], 'en', { numeric: true });
    }

    return b[key].localeCompare(a[key], 'en', { numeric: true });
  });
}

/**
 * return date range text
 *
 * @export
 * @param {AlarmTimeWindow} ev
 * @param {boolean} [useColon=true]
 * @returns
 */
export function getDateRangeText(ev: AlarmTimeWindow, useColon = true) {
  return ev
    ? `${useColon ? ':' : ''} ${format(new Date(ev.from), 'PP')} - ${format(
        new Date(ev.to),
        'PP'
      )}`
    : '';
}

/**
 * return text for group by sort
 *
 * @export
 * @param {*} groupBySort
 * @returns
 */
export function getSortGroupByText(groupBySort) {
  const text = groupBySort === 'GROUP_BY' ? 'Alphanumerical' : this.groupBySort;
  return `Sort Groups by ${startCaseCap(text)}`;
}

/**
 * construct location value
 *
 * @export
 * @param {Partial<Asset>} asset
 * @returns
 */
export function constructLocationValue(asset: Partial<Asset>) {
  const building = asset.building || '';
  const floor = asset.floor;
  const roomName = asset.roomName;
  const roomNumber = asset.roomNumber;
  const roomValue = constructRoomLocationValue(roomName, roomNumber);
  const floorValue = `${constructFloorLocationValue(floor)}${
    roomValue ? ',' : ''
  }`;
  return `${building} ${floorValue} ${roomValue}`;
}

/**
 * construct floor location value
 *
 * @export
 * @param {string} floor
 * @returns
 */
export function constructFloorLocationValue(floor: string) {
  if (!floor) {
    return '';
  }
  if (isNaN(+floor)) {
    return floor;
  } else {
    return `${floor} Flr`;
  }
}

/**
 *
 *
 * @export
 * @param {string} roomName
 * @param {string} roomNumber
 * @returns
 */
export function constructRoomLocationValue(
  roomName: string,
  roomNumber: string
) {
  if (!roomName && !roomNumber) {
    return '';
  } else if (roomNumber && roomName) {
    return `Room ${roomNumber} (${roomName})`;
  } else if (roomNumber && !roomName) {
    return `Room ${roomNumber}`;
  } else if (!roomNumber && roomName) {
    return `Room ${roomName}`;
  }
}

/**
 * format filter criterion pills
 *
 * @export
 * @param {*} criterion
 * @returns
 */
export function formatCriterionPills(criterion) {
  const pills = [];
  criterion.forEach((crit) => {
    if (!crit.detail) {
      return;
    }

    const selection = result(crit, 'detail.selection', {});
    const excludeText = crit.detail.isExclusion ? ' Exclude' : '';
    if (crit.detail.query === 'assetNames') {
      pills.push({
        name: crit.detail.name + excludeText,
        value: crit.detail.selectValues.map((value) => value.name),
      });
    } else if (
      ['select', 'alarmSource', 'cmmsSource'].includes(crit.detail.type)
    ) {
      pills.push({
        name: crit.detail.name + excludeText,
        value: truncatePillText(selection.name || selection.value),
      });
    } else if (
      crit.detail.type === 'customValueSelect' &&
      crit.detail.dbName === 'nuisanceBehavior'
    ) {
      const values = crit?.detail?.selection?.value.map((v) => nuisanceType[v]);
      pills.push({
        name: crit.detail.name + excludeText,
        value: truncatePillText(values),
      });
    } else if (
      crit.detail.type === 'customValueSelect' &&
      crit.detail.dbName === 'assetCriticality'
    ) {
      const values = crit?.detail?.selection?.value.map((v) =>
        ASSET_CRITICALITY_MAP.get(+v)
      );
      pills.push({
        name: crit.detail.name + excludeText,
        value: truncatePillText(values),
      });
    } else if (
      crit.detail.type === 'customValueSelect' &&
      crit.detail.dbName === 'isActive'
    ) {
      const value = crit?.value ? 'Active' : 'Inactive';
      pills.push({
        name: crit.detail.name + excludeText,
        value,
      });
    } else if (
      crit.detail.type === 'customValueSelect' &&
      crit.detail.dbName === 'isCmmsComplete'
    ) {
      const value = crit?.value ? 'Complete' : 'Incomplete';
      pills.push({
        name: crit.detail.name + excludeText,
        value,
      });
    } else if (
      crit.detail.type === 'customValueSelect' &&
      crit.detail.dbName === 'hadActiveAlarmsOnClose'
    ) {
      const value = crit?.value ? 'Yes' : 'No';
      pills.push({
        name: crit.detail.name + excludeText,
        value,
      });
    } else if (
      crit.detail.type === 'customValueSelect' &&
      ['dueAtLastXHours', 'closedAtLastXHours', 'createdAtLastXHours'].includes(
        crit.detail.dbName
      )
    ) {
      const value = LAST_DATE_SELECTION_MAP.get(+crit?.value);
      pills.push({
        name: crit.detail.name + excludeText,
        value,
      });
    } else if (
      crit.detail.type === 'customValueSelect' &&
      ['dueAtNextXHours'].includes(crit.detail.dbName)
    ) {
      const value = NEXT_DATE_SELECTION_MAP.get(+crit?.value);
      pills.push({
        name: crit.detail.name + excludeText,
        value,
      });
    } else if (
      crit.detail.type === 'customValueSelect' &&
      crit.detail.dbName === 'isServiceImpacting'
    ) {
      const name = crit.detail.selectValues.find(
        (value) => selection.value === value?.value
      )?.label;
      pills.push({
        name: crit.detail.name + excludeText,
        value: name,
      });
    } else if (crit.detail.type === 'customValueSelect') {
      const filters = crit.detail.selectValues.filter((value) =>
        selection.value.includes(value?.value)
      );
      const names = filters.map((filter) => filter.label);
      pills.push({
        name: crit.detail.name + excludeText,
        value: truncatePillText(names),
      });
    } else if (crit.detail.type === 'status') {
      pills.push({
        name: crit.detail.name + excludeText,
        value: truncatePillText(selection.value),
      });
    } else if (crit.detail.type === 'dateTime') {
      pills.push({
        name: crit.detail.name + excludeText,
        value: `${format(
          new Date(selection.min),
          DateTimeFormat.DATE_GRT_THAN_24H
        )} - ${format(
          new Date(selection.max),
          DateTimeFormat.DATE_GRT_THAN_24H
        )}`,
      });
    } else if (crit.detail.type === 'minMax') {
      let val = '';
      if (!isNil(selection.min) && !isNil(selection.max)) {
        val = `${selection.min} - ${selection.max}`;
      } else if (!isNil(selection.min) && isNil(selection.max)) {
        val = `≥ ${selection.min}`;
      } else if (isNil(selection.min) && !isNil(selection.max)) {
        val = `≤ ${selection.max}`;
      }
      pills.push({
        name: crit.detail.name + excludeText,
        value: val,
      });
    } else if (crit.detail.type === 'priority') {
      const priorityVal = selection.value.map((p) => PRIORITY_VALUES[p]);
      pills.push({
        name: crit.detail.name + excludeText,
        value: priorityVal.join(', '),
      });
    } else if (crit.detail.type === 'text') {
      pills.push({
        name: crit.detail.name + excludeText,
        value: selection.value,
      });
    } else if (crit.detail.type === 'durationMinMax') {
      pills.push({
        name: crit.detail.name + excludeText,
        value: generateMinMaxFilterPillText(crit),
      });
    } else if (crit.detail.type === 'bool') {
      pills.push({
        name: crit.detail.name + excludeText,
        value: titleCase(selection.value),
      });
    } else if (crit.detail.type === 'userSelect') {
      const users = crit.detail.selectValues.filter((user) =>
        selection.value.includes(user?.id)
      );
      const names = users.map((user) => user.displayName);
      pills.push({
        name: crit.detail.name + excludeText,
        value: truncatePillText(names),
      });
    } else if (crit.detail.query === 'cmmsAssignee') {
      pills.push({
        name: crit.detail.name + excludeText,
        value: selection.value.join(', '),
      });
    }
  });
  return pills;
}

/**
 * generate pill text for min max filters
 *
 * @export
 * @param {CriterionSelection} crit
 * @returns {string}
 */
export function generateMinMaxFilterPillText(crit: CriterionSelection): string {
  if (crit?.detail?.type === 'minMax') {
    return getMinMaxSelectPillText(
      crit?.detail?.selection,
      crit?.detail?.dbName
    );
  } else if (
    crit?.detail?.type === 'durationMinMax' ||
    crit?.detail?.type === 'timeMinMax'
  ) {
    return getTimeMinMaxSelectPillText(crit?.detail?.selection);
  }
}

/**
 * format time min max select pill text
 *
 * @export
 * @param {*} selection
 * @returns
 */
export function getTimeMinMaxSelectPillText(selection) {
  const minDays = selection?.minDays ? `${selection?.minDays}d ` : '';
  const maxDays = selection?.maxDays ? `${selection?.maxDays}d ` : '';
  const minHrs = selection?.minHrs ? `${selection?.minHrs}h ` : '';
  const minMins = selection?.minMins ? `${selection?.minMins}m` : '';
  const maxHrs = selection?.maxHrs ? `${selection?.maxHrs}h ` : '';
  const maxMins = selection?.maxMins ? `${selection?.maxMins}m` : '';
  const min =
    isEmpty(minDays) && isEmpty(minHrs) && isEmpty(minMins)
      ? null
      : `${minDays}${minHrs}${minMins}`;
  const max =
    isEmpty(maxDays) && isEmpty(maxHrs) && isEmpty(maxMins)
      ? null
      : `${maxDays}${maxHrs}${maxMins}`;
  let pillText = `Between ${min} and ${max}`;
  if (min && !max) {
    pillText = `Greater than ${min}`;
  } else if (max && !min) {
    pillText = `Less than ${max}`;
  }
  return pillText;
}

/**
 * format min max select pill text
 *
 * @export
 * @param {*} selection
 * @param {string} dbName
 * @returns
 */
export function getMinMaxSelectPillText(selection, dbName: string) {
  const days = dbName === 'ageDays' ? 'day(s)' : '';
  return `Between ${selection?.min} ${days} and ${selection?.max} ${days}`;
}

/**
 * truncate pill text
 *
 * @export
 * @param {string[]} values
 * @returns
 */
export function truncatePillText(values: string[]) {
  const mappedValues = values.map((v) =>
    v == null || v === 'null' ? `(blanks)` : v
  );
  if (mappedValues.length > 5) {
    const v = mappedValues.slice(0, 5);
    return `${v.join(', ')}...+${mappedValues.length - 5}`;
  }
  return mappedValues.join(', ');
}

/**
 * format time in alarm value
 *
 * @export
 * @param {string} latestAlarmTime
 * @param {string} latestEndTime
 * @returns {number}
 */
export function formatTimeInAlarmValue(
  latestAlarmTime: string,
  latestEndTime: string
): number {
  if (!latestAlarmTime) {
    return 0;
  }
  const endTime = latestEndTime ? new Date(latestEndTime) : new Date();
  return differenceInMilliseconds(endTime, new Date(latestAlarmTime));
}

/**
 * return if filter apply button is disabled
 *
 * @export
 * @param {CriterionSelection[]} criterion
 * @param {boolean} criterionUpdated
 * @returns
 */
export function isFilterApplyDisabled(
  criterion: CriterionSelection[],
  criterionUpdated: boolean
) {
  const filtered = criterion.filter((d) => d.model);
  const every = !filtered.every((d) => {
    if (d?.detail?.type === 'minMax') {
      return !isNil(d.detail.selection?.min) || !isNil(d.detail.selection?.max);
    }
    if (d?.detail && (d?.detail?.selection || d?.detail?.selection?.value)) {
      return (
        d?.detail?.selection?.value?.length > 0 ||
        d?.detail?.selection?.value === true ||
        DurationKeys.some((key) => d?.detail?.selection?.[key]) ||
        !isNil(d?.detail?.selection?.value)
      );
    }
    return !isNil(d.detail && d.detail?.selection);
  });
  return every || (isNil(criterion[0]?.model) && !criterionUpdated);
}

/**
 * process alarm properties to get name and dbName
 *
 * @export
 * @param {*} prop
 * @returns
 */
export function processAlarmProperties(prop) {
  const name = startCase(prop.name);

  const property = {
    name,
    dbName: prop.name,
  };

  return {
    name: property.name,
    show: true,
    dbName: property.dbName,
    type: 'select',
    dbProperty: 'name',
  };
}

/**
 * return menu team name based on lens
 *
 * @export
 * @param {MenuLens} lens
 * @returns
 */
export function fetchLensTeamName(lens: MenuLens) {
  if (!lens.team) {
    return lens.isCustom
      ? `My ${titleCase(lens.type)} Lenses`
      : 'Standard Lenses';
  } else {
    return lens.team.name;
  }
}

/**
 * return hours and minutes from HH:mm format
 *
 * @export
 * @param {string} time
 * @returns
 */
export function getTimeFromHoursMinutes(time: string) {
  const [hours, minutes] = time.split(':').map(Number);
  return { hours, minutes };
}

/**
 *  get current date with hour and minute
 *
 * @export
 * @param {number} hours
 * @param {number} minutes
 * @returns
 */
export function getDateWithHourMinute(hours: number, minutes: number) {
  const currentDate = new Date();
  currentDate.setHours(hours, minutes, 0, 0);
  return currentDate;
}

/**
 * takes a 24 hour format timestamp and create a date object with it
 *
 * @export
 * @param {string} time
 */
export function setDateWith24HourFormatTime(time: string) {
  const [hours, minutes] = time.split(':').map(Number);
  return set(new Date(), { hours, minutes, seconds: 0, milliseconds: 0 }).toISOString();
}

/**
 * sort lens by order value
 *
 * @export
 * @param {number} aOrder
 * @param {number} bOrder
 * @returns
 */
export function sortLensOrder(aOrder: number, bOrder: number) {
  if (aOrder === null) {
    return 1;
  }
  if (bOrder === null) {
    return -1;
  }
  return aOrder - bOrder;
}
