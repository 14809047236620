//TODO: DELETE WHEN OTHER DROPDOWN COMPONENTS ARE READY
// TODO: fix
/* eslint-disable @nx/enforce-module-boundaries */
import { take } from 'rxjs/operators';
import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  input,
  model,
  output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { EntityManagementDataService } from '@vfi-ui/data-access/shared';
import { Entity, EntityType, SearchEntityResult } from '@vfi-ui/models';
import { debounce } from '@vfi-ui/util/helpers';
import { BaseComponent } from '@vfi-ui/feature/core';

import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'atom-multi-level-select-input',
  templateUrl: './multi-level-select-input.component.html',
  styleUrls: ['./multi-level-select-input.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DropdownItemComponent,
    InfiniteScrollDirective,
  ],
})
export class MultiLevelSelectInputComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  readonly showTypeSelector = input(true);
  readonly type = model<string>(undefined);
  readonly currentValue = model<SearchEntityResult>(undefined);
  readonly baseEntity = input<SearchEntityResult>(undefined);
  readonly pointEntity = input<Entity[]>(undefined);
  readonly buttonLabel = input('Select');
  readonly currentValueChange = output<SearchEntityResult>();
  entityTypeOptions: SearchEntityResult[] = [
    { name: 'Space', count: 1 },
    { name: 'Asset', count: 1 },
  ];
  infiniteScrollDistance = 2;
  infiniteScrollThrottle = 50;
  limit = 50;
  offset = 0;
  options: SearchEntityResult[];
  parent: SearchEntityResult[] = [null];
  dropdownOpen = false;
  loading = false;
  spaceTypeCount: number;
  assetTypeCount: number;
  formGroup = new FormGroup({
    search: new FormControl(''),
    selected: new FormControl(null, Validators.required),
  });

  constructor(
    private entityManagement: EntityManagementDataService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    const showTypeSelector = this.showTypeSelector();
    const type = this.type();
    if (!showTypeSelector && type) {
      this.searchEntities(null, null, type);
    }

    const currentValue = this.currentValue();
    if (currentValue) {
      this.formGroup.get('selected').setValue(currentValue);
    }

    const baseEntity = this.baseEntity();
    if (baseEntity) {
      this.parent = [baseEntity];
    }

    if (showTypeSelector) {
      this.parent = [];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pointEntity?.currentValue?.length > 0) {
      this.currentValue.set({
        id: changes.pointEntity?.currentValue[0]?.id,
        name: changes.pointEntity?.currentValue[0]?.name,
      });
      this.formGroup.get('selected').setValue(this.currentValue());
    }
  }

  /**
   * click back to parent
   *
   * @memberof MultiLevelSelectInputComponent
   */
  back() {
    this.offset = 0;
    this.options = null;
    this.parent.pop();
    if (this.showTypeSelector() && !this.parent.length) {
      this.type.set(null);
    } else {
      this.searchEntities(
        this.parent[this.parent.length - 1]?.id,
        null,
        this.type()
      );
    }
  }

  /**
   * drill down to child
   *
   * @param {SearchEntityResult} entity
   * @memberof MultiLevelSelectInputComponent
   */
  drillDown(entity: SearchEntityResult) {
    if (entity.count > 0) {
      this.offset = 0;
      const searchTerm = this.formGroup.get('search').value;
      this.parent.push(entity);
      this.searchEntities(entity.id, searchTerm, this.type());
    }
  }

  /**
   * select entity
   * @param {SearchEntityResult} value
   * @memberof MultiLevelSelectInputComponent
   */
  select(value: SearchEntityResult) {
    this.closeDropdown();
    const baseEntity = this.baseEntity();
    this.parent = baseEntity ? [baseEntity] : [null];
    this.currentValue.set(value);
    this.currentValueChange.emit(value);
  }

  /**
   * handle entity selected event
   *
   * @param {{ name: EntityType }} $event
   * @memberof MultiLevelSelectInputComponent
   */
  pickEntityType($event: { name: EntityType }) {
    this.type.set($event.name);
    this.parent = [$event];
    const searchTerm = this.formGroup.get('search').value;
    this.searchEntities(null, searchTerm, this.type());
  }

  /**
   * get search value from form
   * @param {string} searchTerm
   * @memberof MultiLevelSelectInputComponent
   */
  search(searchTerm: string) {
    const debounced = debounce(this.searchEntities, 1000);
    debounced.call(
      this,
      this.parent[this.parent.length - 1]?.id,
      searchTerm,
      this.type()
    );
  }

  /**
   * infinite scroll
   *
   * @memberof MultiLevelSelectInputComponent
   */
  scroll() {
    this.offset += this.limit;
    this.searchEntities(
      this.parent[this.parent.length - 1]?.id,
      this.formGroup.get('search').value,
      this.type()
    );
  }

  /**
   * search entities
   *
   * @optional {number} baseEntityId
   * @optional {string} pattern
   * @memberof MultiLevelSelectInputComponent
   */
  searchEntities(baseEntityId?: number, pattern?: string, type?: string) {
    this.assetTypeCount = null;
    this.spaceTypeCount = null;

    if (!baseEntityId && pattern) {
      this.entityManagement
        .searchEntityCount(baseEntityId, pattern, EntityType.Space)
        .pipe(take(1))
        .subscribe((res) => {
          this.spaceTypeCount = res;
          this.cd.detectChanges();
        });
      this.entityManagement
        .searchEntityCount(baseEntityId, pattern, EntityType.Asset)
        .pipe(take(1))
        .subscribe((res) => {
          this.assetTypeCount = res;
          this.cd.detectChanges();
        });
    }

    if (type) {
      if (!this.options) {
        this.loading = true;
      }
      this.entityManagement
        .searchEntities(
          baseEntityId,
          pattern,
          EntityType[type],
          this.limit,
          this.offset
        )
        .subscribe((res) => {
          this.loading = false;
          this.options = this.offset === 0 ? res : [...this.options, ...res];
          this.cd.detectChanges();
        });
    }
  }

  /**
   * close dropdown
   *
   * @memberof MultiLevelSelectInputComponent
   */
  closeDropdown() {
    this.dropdownOpen = false;
  }

  /**
   * toggle dropdown
   *
   * @memberof MultiLevelSelectInputComponent
   */
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    this.formGroup.reset();
    if (this.showTypeSelector() && this.type()) {
      this.searchEntities(this.baseEntity()?.id);
    }
  }

  /**
   * format asset name
   *
   * @param {SearchEntityResult} option
   * @returns
   * @memberof MultiLevelSelectInputComponent
   */
  formatAssetName(option: SearchEntityResult) {
    return `${option.building} - ${option.name} (${option.class})`;
  }
}
