import {
  Component,
  ChangeDetectionStrategy,
  SimpleChanges,
  OnChanges,
  input,
  output,
} from '@angular/core';
import { VfiButtonComponent } from '@vfi-ui/ui/atoms';
import {
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { NgClass } from '@angular/common';
import { NzMenuDirective, NzMenuItemComponent } from 'ng-zorro-antd/menu';
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch';

@Component({
  selector: 'nuclei-include-exclude',
  templateUrl: './include-exclude.component.html',
  styleUrls: ['./include-exclude.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    VfiButtonComponent,
    NzDropDownDirective,
    NgClass,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
    ɵNzTransitionPatchDirective,
  ],
})
export class IncludeExcludeComponent implements OnChanges {
  readonly selected = output<string>();
  readonly isExclusion = input<boolean>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly option = input(['includes', 'excludes']);

  value: string = this.option()[0];
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isExclusion?.currentValue) {
      this.value = this.option()[1];
    } else {
      this.value = this.option()[0];
    }
  }

  /**
   * select options for selected value
   *
   * @param { string } option
   * @memberof IncludeExcludeComponent
   */
  optionSelected(option: string): void {
    this.value = option;
    this.selected.emit(this.value);
  }
}
