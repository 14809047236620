import { Component, input } from '@angular/core';
import {
  WorkTicket,
  WorkTicketProcessState,
  WorkTicketStatus,
} from '@vfi-ui/models';

import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'nuclei-work-icon',
  templateUrl: './work-icon.component.html',
  styleUrls: ['./work-icon.component.scss'],
  imports: [NzTooltipDirective],
})
export class WorkIconComponent {
  readonly workTicket = input<WorkTicket>(undefined);
  readonly size = input(14);
  workTicketState = WorkTicketProcessState;
  workTicketStatus = WorkTicketStatus;

  constructor() {}
}
