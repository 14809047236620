<button
  class="vfi-button-defaults vfi-button-dark-mode {{ type() }}-btn mr-1 {{
    size()
  }} {{ classes() }}"
  [disabled]="disabled() || loading()"
  [ngClass]="{ disabledBtn: disabled() || loading(), 'icon-only': !text()?.length }"
  [attr.aria-label]="ariaLabel()"
  nz-tooltip
  (click)="clicked.emit(true)"
  >
  <span
    class="d-flex align-items-center justify-content-center"
    [ngClass]="{
      'flex-row-reverse': iconPosition() === buttonIconPosition.RIGHT
    }"
    >
    @if (icon() && !loading()) {
      <i
        [ngClass]="[icon(), iconPosition()]"
        [class.iconOnly]="!text()?.length"
      ></i>
    }
    @if (iconStack() && !loading()) {
      <span
        class="left fa-stack regular-10"
        [class.iconOnly]="!text()?.length"
        >
        @for (iconClass of iconStack(); track iconClass) {
          <i
            [ngClass]="[iconClass]"
          ></i>
        }
      </span>
    }
    @if (loading()) {
      <i
        class="left fas fa-spinner-third fa-spin"
        [class.iconOnly]="!text()?.length"
      ></i>
    }
    @if (text()?.length) {
      <span>{{ text() }}</span>
    }
  </span>
</button>
