@if (formattedValues || !isReadOnly()) {
  <div
    aria-label="disabled-input"
    class="d-flex flex-column w-100 cursor-default"
    [class.disabledField]="!isReadOnly()"
    >
    <span class="placeholder regular-12 gray-3 font-bold pl-1 pr-1">{{
      placeholder() | uppercase
    }}</span>
    <div
      class="d-flex align-items-center justify-content-between input-wrapper pr-1 pl-1"
      >
      <span
        class="regular-16 neutral-1 fw-500 overflow-x-scroll w-100"
      [ngStyle]="{
        'max-height': scrollHeight() > 0 ? scrollHeight() + 'px' : 'auto'
      }"
      [ngClass]="{
        'word-break': wordBreak(),
        'white-space-nowrap': !wordBreak(),
        scrolling: scrollHeight() > 0
      }"
        >
        <span [nzTooltipTitle]="formattedValues" nz-tooltip>
          @if (isTextArea()) {
            <span [innerHTML]="formattedValues"></span>
          }
          @if (!isTextArea()) {
            <span>{{ formattedValues }}</span>
          }
        </span>
      </span>
      @if (avatar()) {
        <nz-avatar [nzSrc]="avatar()"></nz-avatar>
      }
    </div>
  </div>
}
