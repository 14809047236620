import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  input,
  model,
  output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  CmmsInternalFieldType,
  ExternalCmmsField,
  ExternalCmmsFieldMappingCreateInput,
  MODAL_Z_INDEX,
} from '@vfi-ui/models';
import { AlarmsState } from '@vfi-ui/state';
import { get, startCaseCap } from '@vfi-ui/util/helpers';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { NgClass } from '@angular/common';
import { DropdownMenuComponent, VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'nuclei-create-cmms-mapping',
  templateUrl: './create-cmms-mapping.component.html',
  styleUrls: ['./create-cmms-mapping.component.scss'],
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    DropdownMenuComponent,
    VfiButtonComponent,
  ],
})
export class CreateCmmsMappingComponent implements OnInit, OnChanges {
  readonly isVisible = model(false);
  readonly disableAddButton = input(false);
  readonly selectedCmmsField = input<ExternalCmmsField>(undefined);
  readonly closeModal = output();
  readonly createMapping = output<ExternalCmmsFieldMappingCreateInput>();
  form: UntypedFormGroup;
  options = [
    {
      value: 'Alarm Attribute',
      label: 'Alarm Attribute',
    },
    {
      value: 'CMMS Attribute',
      label: 'CMMS Attribute',
    },
    { value: CmmsInternalFieldType.State, label: 'Work Ticket State' },
  ];
  attributeOptions = [];
  modalZIndex = MODAL_Z_INDEX;
  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      internalFieldType: [null, Validators.required],
      internalFieldName: [null],
    });
    this.attributeOptions = this.store
      .selectSnapshot(AlarmsState.getAlarmProperties)
      .map((attribute) => ({
        label: startCaseCap(attribute.name),
        value: attribute.name,
      }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes.isVisible, 'currentValue', false)) {
      this.form.reset();
    }
  }
  /**
   * Emits event to create cmms mapping
   *
   * @memberof CreateCmmsMappingComponent
   */
  handleCreateCmmsMapping() {
    this.createMapping.emit({
      ...this.form.value,
      internalFieldType: ['Alarm Attribute', 'CMMS Attribute'].includes(
        this.form.value.internalFieldType
      )
        ? CmmsInternalFieldType.Attribute
        : this.form.value.internalFieldType,
      externalId: this.selectedCmmsField().id,
    });
  }
  /**
   * Handle external value selected event.
   *
   * @param {*} ev
   * @memberof DetailsUserComponent
   */
  updateInternalType(ev) {
    this.form.patchValue({ internalFieldType: ev });
    this.form.markAsDirty();
  }
  /**
   *  Handles internal value selected event.
   * @param ev
   */
  updateInternalValue(ev) {
    this.form.patchValue({ internalFieldName: ev });
    this.form.markAsDirty();
  }

  /**
   * Invoked when the cancel button is selected. Closes
   * the modal and resets the form.
   *
   * @memberof CreateCmmsMappingComponent
   */
  handleCancel() {
    this.form.reset();
    this.closeModal.emit();
  }
}
