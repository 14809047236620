<div class="form-group">
  <div
    class="input-wrapper"
    [class.input-active]="hasValue(value()) || focused"
    >
    <nz-select
      [attr.name]="name()"
      [class.clearMargin]="!allowClear()"
      [class.required-field]="required()"
      [class.no-placeholder]="!showPlaceholder()"
      [ngStyle]="{ 'min-width': '200px', width: width() + 'px' }"
      [nzShowSearch]="showSearch()"
      [nzSize]="'large'"
      [nzOpen]="open()"
      [nzClearIcon]="customClearIcon"
      [nzRemoveIcon]="customClearIcon"
      [(ngModel)]="value"
      [nzAllowClear]="allowClear()"
      (ngModelChange)="valueChanged($event)"
      [nzDisabled]="disabled()"
      [nzMode]="multiple() ? 'multiple' : 'default'"
      (nzFocus)="focused = true"
      (nzBlur)="focused = false"
      (nzOpenChange)="openChange(true)"
      [nzDropdownClassName]="'dropdownContainer'"
      (nzOnSearch)="fetchOptions($event, options(), newValues)"
      >
      @for (option of options(); track option) {
        <nz-option
          nzCustomContent
          [nzLabel]="option.label"
          [nzValue]="option.value"
          >
          @if (showTooltips()) {
            <span
              attr.aria-label="{{ option?.label }}-option"
              [nzTooltipTitle]="option?.label"
              nzTooltipPlacement="left"
              nz-tooltip
              >{{ option?.label }}</span
              >
            }
            @if (!showTooltips()) {
              <span>{{ option?.label }}</span>
            }
          </nz-option>
        }
      </nz-select>
      @if (showPlaceholder()) {
        <label
          class="mb-1"
          [ngClass]="{ 'text-capitalize': capitalizePlaceholder() }"
          >{{ label() }}</label
          >
        }
      </div>
    </div>

    <ng-template #customClearIcon>
      <i class="neutral-1 fas fa-times-circle"></i>
    </ng-template>
