<div
  class="dropdown-list d-flex flex-column justify-content-between"
  role="listbox"
  aria-expanded="false"
  >
  @if (
    (parent()?.length && type() && showTypeSelector()) ||
    (parent()?.length > 1 && type())
    ) {
    <div
      class="back"
      (click)="back()"
      >
      <i class="fas fa-chevron-left"></i>
      <span>{{ parent()[parent()?.length - 1]?.name }}</span>
    </div>
  }

  <div>
    @if (showSearch()) {
      <form [formGroup]="formGroup">
        <input
          type="text"
          class="dropdown-search"
          placeholder="Search"
          formControlName="search"
          (keyup)="search($event.target.value)"
          />
      </form>
    }

    @if (loading()) {
      <div class="alert">Loading...</div>
    }
    @if (options()?.length === 0 && !loading()) {
      <div class="alert">
        No Results
      </div>
    }

    @if (!loading() && !optionsBySection()) {
      <div
        class="list-container"
        #listContainer
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="listContainer"
        (scrolled)="scroll()"
        >
        @for (o of options(); track o) {
          <atom-dropdown-item
            [item]="o"
            [title]="type() === 'Asset' && parent()?.length ? getAssetName(o) : o?.name"
        [showCheckbox]="
          (multiSelect() && !showTypeSelector()) ||
          (multiSelect() && showTypeSelector() && parent()?.length)
        "
            [selected]="isChecked(o)"
            [showBadge]="o.meta?.badge"
            [badgeCount]="o.meta?.badge"
            [showRightIcon]="o?.count > 0 && showDrilldown()"
            [showDrilldown]="showDrilldown()"
            [disabled]="o?.meta?.disabled"
            (selectChange)="pickOption(o)"
            (drillDownChange)="o.count > 0 ? drillDown(o) : pickOption(o)"
        [nzTooltipTitle]="
          type() === 'Asset' && parent()?.length ? getAssetName(o) : o?.name
        "
            nz-tooltip
            [nzTooltipMouseEnterDelay]="0.5"
            [ngClass]="{ 'cursor-not-allowed': o?.meta?.disabled }"
          ></atom-dropdown-item>
        }
      </div>
    }

    @if (!loading() && optionsBySection()) {
      <div
        class="list-container"
        #listContainer
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="listContainer"
        (scrolled)="scroll()"
        >
        @for (key of getOptionKeys(options()); track key; let last = $last) {
          <div
            class="mb-3 pb-1"
            [class.team-section]="unassignedOptions() || !last"
            >
            <div class="mb-2">{{ key === 'null' ? '-' : key }}</div>
            @for (o of options()[key]; track o; let first = $first) {
              <atom-dropdown-item
                [item]="o"
                [title]="o?.name"
                [showCheckbox]="multiSelect() || parent()?.length"
                [selected]="isChecked(o)"
                [showBadge]="o.meta?.badge"
                [badgeCount]="o.meta?.badge"
                [showRightIcon]="o?.count > 0"
                [disabled]="o?.meta?.disabled"
                (selectChange)="pickOption(o)"
                (drillDownChange)="o.count > 0 ? drillDown(o) : pickOption(o)"
                [nzTooltipTitle]="getAssigneeTooltipText(o)"
                nz-tooltip
                [nzTooltipMouseEnterDelay]="0.5"
                [ngClass]="{ 'cursor-not-allowed': o?.meta?.disabled }"
              ></atom-dropdown-item>
            }
          </div>
        }
        @if (unassignedOptions()?.length) {
          <div class="mb-3 pb-1">
            <div class="mb-2">-</div>
            @for (o of unassignedOptions(); track o; let last = $last) {
              <atom-dropdown-item
                [item]="o"
                [title]="o?.name"
                [showCheckbox]="multiSelect() || parent()?.length"
                [selected]="isChecked(o)"
                [showBadge]="o.meta?.badge"
                [badgeCount]="o.meta?.badge"
                [showRightIcon]="o?.count > 0"
                [disabled]="o?.meta?.disabled"
                (selectChange)="pickOption(o)"
                (drillDownChange)="o.count > 0 ? drillDown(o) : pickOption(o)"
                [nzTooltipTitle]="getAssigneeTooltipText(o)"
                nz-tooltip
                [nzTooltipMouseEnterDelay]="0.5"
                [ngClass]="{ 'cursor-not-allowed': o?.meta?.disabled }"
              ></atom-dropdown-item>
            }
          </div>
        }
      </div>
    }
  </div>

  <button
    class="select-btn"
    [disabled]="formGroup.get('selected').invalid"
    (click)="select(formGroup.get('selected').value)"
    >
    {{ buttonLabel() }}
    {{
    multiSelect() && formGroup.get('selected').value?.length
    ? '(' + formGroup.get('selected').value?.length + ')'
    : ''
    }}
  </button>
</div>
