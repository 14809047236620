<span class="ellipsis {{ color() }} regular-{{ fontSize() }}">
  @if (timeValues?.days > 0) {
    <span>{{ timeValues?.d }} </span>
  }
  @if (timeValues?.hours > 0) {
    <span>{{ timeValues?.h }} </span>
  }
  @if (timeValues?.minutes > 0) {
    <span>{{ timeValues?.m }} </span>
  }
  @if (
    (!timeValues?.days &&
    !timeValues?.hours &&
    !timeValues?.minutes &&
    timeValues?.seconds) ||
    (!isNull(duration()) &&
    duration() !== '' &&
    (duration() === 0 || duration() < minuteInMs))
    ) {
    <span
      >&lt;1m</span
      >
    }
    @if (
      !timeValues?.days &&
      !timeValues?.hours &&
      !timeValues?.minutes &&
      !timeValues?.seconds &&
      (isNull(duration()) || duration() === '')
      ) {
      <span
        >—</span
        >
      }
    </span>
