// TODO: fix
/* eslint-disable @nx/enforce-module-boundaries */
import { caseSensitiveSort, fastParse, isNil } from '@vfi-ui/util/helpers';
import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  input,
  model,
  output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthService } from '@vfi-ui/data-access/shared';
import { BaseComponent } from '@vfi-ui/feature/core';
import {
  GlobalFilterSort,
  MultiLevelInputOption,
  TeamMember,
  TeamMemberRole,
  Users,
} from '@vfi-ui/models';
import { TeamsState, AuthState } from '@vfi-ui/state';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { MultiLevelInputComponent } from '../multi-level-input/multi-level-input.component';

@Component({
  selector: 'atom-assignee-selector',
  templateUrl: './assignee-selector.component.html',
  styleUrls: ['./assignee-selector.component.scss'],
  imports: [NzPopoverDirective, MultiLevelInputComponent],
})
export class AssigneeSelectorComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  readonly placeholder = input<string>(undefined);
  readonly value = model<MultiLevelInputOption | MultiLevelInputOption[]>(
    undefined
  );
  readonly reset = input<Subject<boolean>>(undefined);
  readonly disabled = input(false);
  readonly required = input(false);
  readonly showMultiLevelSelect = model(false);
  readonly multiSelect = input(false);
  readonly optionsByTeams = input(true);
  readonly teamId = input<string>(undefined);
  readonly displaySelectedValue = input(true);
  readonly showCurrentUser = input(true);
  readonly displayName = input<string>(undefined);
  readonly activeUsers = input<boolean>(undefined);
  readonly sort = input<GlobalFilterSort>(undefined);
  readonly vendorUsers = input<boolean>(undefined);
  readonly disabledUsers = input<boolean>(undefined);
  readonly pendingUsers = input<boolean>(undefined);
  readonly removeSelectedUsers = input(false);
  readonly selectedUsers = input<string[]>(undefined);
  readonly disablePushUsers = input(false);
  readonly disableTextUsers = input(false);
  readonly simpleMode = input(false);
  readonly assigneeChanged = output<
    MultiLevelInputOption | MultiLevelInputOption[]
  >();
  users: Users[];
  options = [];
  teamOptions = {};
  unassignedTeamOptions = [];
  loading = false;
  isArray = Array.isArray;
  selectedValue: MultiLevelInputOption | MultiLevelInputOption[];

  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private store: Store
  ) {
    super();
  }

  ngOnInit() {
    if (!this.teamId()) {
      this.getUsers();
    } else {
      this.getUsersByTeams();
    }

    const value = this.value();
    if (value) {
      this.selectedValue = value;
    }

    const reset = this.reset();
    if (reset) {
      reset.pipe(take(1)).subscribe(() => {
        this.value.set(null);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value?.currentValue) {
      this.selectedValue = changes.value.currentValue;
    }
  }

  /**
   * search for users
   * @param {string} displayName
   * @memberof AssigneeSelectorComponent
   */
  searchUser(displayName: string) {
    this.unassignedTeamOptions = [];
    if (!this.teamId()) {
      this.getUsers(displayName);
    } else {
      this.getUsersByTeams(displayName);
    }
  }

  /**
   * get users from api
   * @param {string} [displayName]
   * @memberof AssigneeSelectorComponent
   */
  getUsers(displayName?: string) {
    this.loading = true;
    this.authService
      .getUsers({
        displayName: displayName || this.displayName(),
        showActive: this.activeUsers(),
        sort: this.sort(),
        showVendors: this.vendorUsers(),
        showDisabled: this.disabledUsers(),
        showPending: this.pendingUsers(),
      })
      .pipe(take(1))
      .subscribe((users) => this.handleFormatUsers(users));
  }

  /**
   * get users by teams from api
   * @memberof AssigneeSelectorComponent
   */
  getUsersByTeams(displayName?: string) {
    this.loading = true;
    this.authService
      .getUsersByTeams([this.teamId()], displayName || this.displayName())
      .pipe(take(1))
      .subscribe((users) => this.handleFormatUsers(users));
  }

  /**
   * emit value on changes
   * @param {MultiLevelInputOption | MultiLevelInputOption[]} option
   * @memberof AssigneeSelectorComponent
   */
  onValueChange(option: MultiLevelInputOption | MultiLevelInputOption[]) {
    this.value.set(option);
    this.assigneeChanged.emit(this.value());
    this.showMultiLevelSelect.set(false);
  }

  /**
   * format users to multi level input option
   * @param {Users[]} users
   * @memberof AssigneeSelectorComponent
   */
  formatUsers(users: Users[]) {
    this.options = users.map((user) => ({
      id: user.id,
      name: user.displayName,
      meta: {
        avatarUrl: user.avatarUrl,
        disabled: this.determineDisable(user),
        disabledText: this.getDisabledUserText(),
      },
    }));
    this.loading = false;
    this.cd.detectChanges();
  }

  /**
   * format sectioned to multi level input option
   * @param {Users[]} users
   * @memberof AssigneeSelectorComponent
   */
  formatSectionedUsers(users: Users[]) {
    this.store.selectSnapshot(TeamsState.getTeams)?.forEach((team) => {
      const teamId = this.teamId();
      if ((teamId && teamId === team.id) || !teamId) {
        this.teamOptions[team.name] = [];
      }
    });

    caseSensitiveSort(users, 'displayName').forEach((user) => {
      if (!user.teamMembers.length) {
        this.addToOptions(null, user);
      }

      user.teamMembers.forEach((teamMember) => {
        const teamId = this.teamId();
        if ((teamId && teamId === teamMember.team.id) || !teamId) {
          this.addToOptions(teamMember, user);
        }
      });
    });

    for (const prop in this.teamOptions) {
      if (!this.teamOptions[prop].length) {
        delete this.teamOptions[prop];
      }
    }

    this.loading = false;
    this.cd.detectChanges();
  }

  getArrayValues(values: MultiLevelInputOption | MultiLevelInputOption[]) {
    return values as MultiLevelInputOption[];
  }

  /**
   * set disabled meta data for users
   *
   * @private
   * @param {Users} user
   * @returns
   * @memberof AssigneeSelectorComponent
   */
  private determineDisable(user: Users) {
    if (this.disablePushUsers()) {
      return !user.isPushNotificationEnabled;
    }
    if (this.disableTextUsers()) {
      return isNil(user.phone.number);
    }
    return false;
  }

  /**
   * return disabled user text
   *
   * @private
   * @returns
   * @memberof AssigneeSelectorComponent
   */
  private getDisabledUserText() {
    if (this.disablePushUsers()) {
      return 'User has opted out of push notifications';
    }
    if (this.disableTextUsers()) {
      return 'User does not have a mobile number';
    }
    return null;
  }

  private handleFormatUsers(users: Users[]) {
    this.users = fastParse(users);
    if (!this.showCurrentUser()) {
      const userId = this.store.selectSnapshot(AuthState.getId);
      users = users.filter((user) => user.id !== userId);
    }

    if (this.removeSelectedUsers()) {
      users = users.filter(
        (user) => !this.selectedUsers().includes(String(user?.id))
      );
    }

    if (this.optionsByTeams()) {
      this.formatSectionedUsers(users);
    } else {
      this.formatUsers(users);
    }
  }

  /**
   * adds user to team options
   * @param {teamMember} teamMember
   * @param {Users} user
   * @memberof AssigneeSelectorComponent
   */
  private addToOptions(teamMember: TeamMember, user: Users) {
    if (!teamMember) {
      const unassignedUser = this.unassignedTeamOptions.find(
        (option) => option.id === user.id
      );

      if (!unassignedUser) {
        this.unassignedTeamOptions.push({
          id: user.id,
          name: user.displayName,
          meta: {
            avatarUrl: user.avatarUrl,
            disabled: this.determineDisable(user),
            disabledText: this.getDisabledUserText(),
          },
        });
      }
    }

    if (teamMember && teamMember?.permissions.role === TeamMemberRole.Admin) {
      this.teamOptions?.[teamMember.team.name]?.unshift({
        id: user.id,
        name: user.displayName,
        meta: {
          avatarUrl: user.avatarUrl,
          teamLead: true,
          disabled: this.determineDisable(user),
          disabledText: this.getDisabledUserText(),
        },
      });
    } else if (teamMember) {
      this.teamOptions?.[teamMember.team.name]?.push({
        id: user.id,
        name: user.displayName,
        meta: {
          avatarUrl: user.avatarUrl,
          disabled: this.determineDisable(user),
          disabledText: this.getDisabledUserText(),
        },
      });
    }
  }
}
