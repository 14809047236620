<div class="login">
  <div class="login_wrapper">
    <div class="login_form">
      <div class="login_box">
        <section class="login_content">
          <img src="/assets/vfi-logo-med.png" width="220" height="88" alt="" />
          @if (user || !validatedToken) {
            <div class="form-container">
              <div class="d-flex align-items-center mt-5 mb-5">
                <nz-avatar [nzSize]="64" class="fas fa-user"></nz-avatar>
                <div
                  class="d-flex flex-column align-items-start justify-content-start ml-3"
                  >
                  <p class="neutral-2 fw-500 regular-20 mb-1">Welcome!</p>
                  <div
                    class="d-flex flex-column justify-content-start align-items-start"
                    >
                    <span>{{ user?.displayName }}</span>
                    <span>{{ user?.emailAddress }}</span>
                  </div>
                </div>
              </div>
              <div class="mb-5">
                <nuclei-set-password
                  (formValid)="checkFormValid($event)"
                  [passwordForm]="passwordForm"
                ></nuclei-set-password>
              </div>
              @if (user) {
                <atom-vfi-button
                  class="w-100"
                  text="Register"
                  ariaLabel="registerButton"
                  type="secondary"
                  classes="block-button"
                  [disabled]="!formValid"
                  (click)="activateUser()"
                ></atom-vfi-button>
              }
            </div>
          }
          @if (!user && validatedToken) {
            <div
              class="fw-500 regular-20 neutral-2 expiredMessage"
              >
              <p>Sorry, it looks like your access link expired or is invalid.</p>
              <span>Please reach out to your</span>
              <p>Virtual Facility Admin to request a new invitation email!</p>
            </div>
          }
          <div class="gray-1 regular-12 fw-normal mt-5" [class.mt-4]="!user">
            <p>
              &copy; {{ currentYear }} Virtual Facility, Inc. All Rights
              Reserved.
            </p>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
