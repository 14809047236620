<atom-vfi-button
  nz-dropdown
  [nzDropdownMenu]="menu"
  nzPlacement="bottomRight"
  nzTrigger="click"
  [text]="mobileIconOnly() ? '' : title()"
  icon="fa {{ mobileIconOnly() ? mobileIcon() : 'fa-chevron-down' }}"
  type="secondary"
  [iconPosition]="ButtonIconPosition.RIGHT"
  [size]="buttonSize()"
  [type]="buttonType()"
  nzOverlayClassName="vf-actions-menu"
  (nzVisibleChange)="menuVisibleChange.emit($event)"
>
</atom-vfi-button>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    @for (item of dropdownMenu(); track item) {
    <!-- grouped items -->
    @if (!item.hide) {
    @if (item.grouped) {
    <li
      nz-menu-group
      [nzTitle]="item.label"
    >
      <ul>
        @for (child of item.children; track child) {
        @if (!child.hide && child.children && !child.grouped) {
        <li
          nz-submenu
          [nzTitle]="child.label"
          [nzDisabled]="child.disabled"
        >
          <ul>
            @for (subChild of child.children; track subChild) {
            @if (!subChild.hide) {
            <li
              nz-menu-item
              class="d-flex"
              [nzDisabled]="subChild.disabled"
              (click)="subChild.disabled ? null : subChild.action()"
            >
              @if (subChild.icon) {
              <i class="mr-2 fa {{ subChild.icon }}"></i>
              }
              {{ subChild.label }}
            </li>
            }
            }
          </ul>
        </li>
        }
        @if (!child.hide && !child.children) {
        <li
          nz-menu-item
          class="d-flex"
          [nzDisabled]="child.disabled"
          (click)="child.disabled ? null : child.action()"
        >
          @if (child.icon) {
          <i class="mr-2 fa {{ child.icon }}"></i>
          }
          {{ child.label }}
        </li>
        }
        }
      </ul>
    </li>
    }
    <!-- single items -->
    @if (!item.hide) {
    @if (!item.children && !item.grouped) {
    <li
      nz-menu-item
      class="d-flex"
      [nzDisabled]="item.disabled"
      (click)="item.disabled ? null : item.action()"
    >
      @if (item.icon) {
      <i class="mr-2 fa {{ item.icon }}"></i>
      }
      {{ item.label }}
    </li>
    }
    }
    <!-- sub-menu -->
    @if (item.children && !item.grouped) {
    <li
      nz-submenu
      [nzTitle]="item.label"
    >
      <ul>
        @for (child of item.children; track child) {
        @if (!child.hide) {
        <li
          nz-menu-item
          class="d-flex"
          [nzDisabled]="child.disabled"
          (click)="child.disabled ? null : child.action()"
        >
          @if (child.icon) {
          <i class="mr-2 fa {{ child.icon }}"></i>
          }
          {{ child.label }}
        </li>
        }
        }
      </ul>
    </li>
    }
    }
    }
  </ul>
</nz-dropdown-menu>