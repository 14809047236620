<atom-vfi-button
  [text]="value"
  type="secondary"
  [disabled]="disabled()"
  size="large"
  classes="imr-0"
  nzTrigger="click"
  nz-dropdown
  [nzDropdownMenu]="optionsMenu"
  [nzDisabled]="disabled()"
  [ngClass]="{ disableInput: disabled() }"
></atom-vfi-button>
<nz-dropdown-menu #optionsMenu="nzDropdownMenu">
  <ul nz-menu>
    @for (option of option(); track option) {
      <li
        nz-menu-item
        (click)="optionSelected(option)"
        >
        {{ option }}
      </li>
    }
  </ul>
</nz-dropdown-menu>
