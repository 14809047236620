@if (!userError) {
  <div
    class="gray-9-bg d-flex flex-column justify-content-center align-items-center h-100"
    >
    <p class="mb-4 gray-1 regular-144 font-lighter">
      4
      <i class="fal fa-bell-exclamation"></i>
      4
    </p>
    <p class="mb-3 gray-1 regular-30 text-center">Page not found</p>
    <p class="gray-1 regular-14 text-center font-light">
      We are sorry but the page you are looking for doesn’t exist or has been
      moved. <br />Please check back later or click below to go to the home page.
    </p>
    <atom-vfi-button
      text="Home Page"
      [type]="'primary'"
      (clicked)="navigateToHome()"
    ></atom-vfi-button>
  </div>
}
@if (userError && !verifyingSso && !hasSsoActivated) {
  <div
    class="gray-9-bg d-flex flex-column justify-content-center align-items-center h-100"
    >
    <p class="mb-4 gray-1 regular-144 font-lighter">
      4
      <i class="fal fa-bell-exclamation"></i>
      4
    </p>
    <p class="mb-3 gray-1 regular-30 text-center">Account not found!</p>
    <p class="gray-1 regular-14 text-center font-light">
      Please contact your administrator to set up a Virtual Facility account.
    </p>
    <atom-vfi-button
      text="Return"
      [type]="'primary'"
      (clicked)="navigateToHome()"
    ></atom-vfi-button>
  </div>
}
@if (userError && verifyingSso) {
  <div
    class="h-100 w-100 d-flex align-items-center justify-content-center"
    >
    <div class="user-error d-flex flex-column p-5 regular-16">
      <span class="mb-1">Authentication in progress</span>
      <span>Virtual Facility will load shortly</span>
    </div>
  </div>
}
<span class="title bottom-left-absolute neutral-1"> Virtual Facility</span>
