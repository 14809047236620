<div [ngClass]="{ 'cursor-not-allowed': disabled() }">
  <div
    class="vfi-input {{ size() }}"
    [ngClass]="{ disabled: disabled() }"
  >
    @if (label()) {
    <div class="vfi-label">
      <span [class.yellow-6]="required() && !readOnly()">{{ label() }}</span>
      @if (labelIcon()) {
      <i
        class="ml-1 mr-1 {{ labelIcon() }}"
        nz-tooltip
        [nzTooltipTitle]="labelIconTooltip()"
      ></i>
      }
      @if (labelCheck()) {
      <i
        class="ml-1 fa-solid fa-check check-green"
        nz-tooltip
        nzTooltipTitle="Verified"
      ></i>
      }
    </div>
    }
    <div
      class="input-icons position-relative"
      [ngClass]="{
        subtle: subtle(),
        success: success(),
        warning: warning(),
        error: error(),
        'pointer-none': readOnly() || disabled(),
      }"
    >
      <span [ngClass]="{ hasIcon: iconLeft() }"></span>
      @if (!allowSpecialCharacters()) {
      <input
        #inputElm
        atomPreventSpecialCharacter
        nz-input
        [ngClass]="{
          'input-icon-left-spacing': iconLeft(),
          'input-icon-right-spacing':
            value() && !success() && !error() && !warning(),
          'input-icon-right-state-spacing':
            value() && !subtle() && (success() || warning() || error()),
          readOnly: readOnly(),
          disabled: disabled(),
        }"
        [placeholder]="placeholder()"
        [ngModel]="value()"
        min="0"
        oninput="oninput"
        [pattern]="pattern()"
        [mask]="mask()"
        [prefix]="prefix()"
        (blur)="onBlur($event)"
        (keyup.enter)="enterPressed.emit(true)"
        (ngModelChange)="changed($event)"
      />
      }
      @if (allowSpecialCharacters()) {
      <input
        #inputElm
        nz-input
        [type]="type()"
        [ngClass]="{
          'input-icon-left-spacing': iconLeft(),
          'input-icon-right-spacing':
            value() && !success() && !error() && !warning(),
          'input-icon-right-state-spacing':
            value() && !subtle() && (success() || warning() || error()),
          readOnly: readOnly(),
          disabled: disabled(),
        }"
        [placeholder]="placeholder()"
        [mask]="mask()"
        [prefix]="prefix()"
        [ngModel]="value()"
        (blur)="onBlur($event)"
        (keyup.enter)="enterPressed.emit(true)"
        (ngModelChange)="changed($event)"
      />
      }
      @if (iconLeft()) {
      <i class="{{ iconLeft() }} icon icon-left"></i>
      }
      <span class="action-icons">
        @if (!value() && value() === '' && !readOnly() && !disabled()) {
        <i
          class="fa-solid fa-pen icon icon-right edit-icon"
          [ngClass]="{
            'right-icon-no-state-spacing':
              !success() && !warning() && !error() && !subtle(),
            'right-icon-state-spacing':
              (success() || warning() || error()) && !subtle(),
          }"
        ></i>
        }
        @if (value() && !readOnly() && !disabled() && showCancel()) {
        <i
          class="fa-regular fa-circle-xmark icon icon-right cursor-pointer clear-icon"
          [ngClass]="{
            'right-icon-no-state-spacing':
              !success() && !warning() && !error() && !subtle(),
            'right-icon-state-spacing':
              (success() || warning() || error()) && !subtle(),
          }"
          (click)="clear()"
        ></i>
        }
      </span>
      <span>
        @if (success() && !subtle()) {
        <i class="fa-solid fa-check icon cursor-pointer success-icon icon-right"></i>
        }
        @if (warning() && !subtle()) {
        <i class="fa-solid fa-triangle-exclamation icon cursor-pointer warning-icon icon-right"></i>
        }
        @if (error() && !subtle()) {
        <i class="fa-solid fa-circle-exclamation icon cursor-pointer error-icon icon-right"></i>
        }
      </span>
    </div>
    @if (showEditButtons()) {
    <div class="edit-btns">
      <atom-vfi-button
        icon="fa-solid fa-check"
        type="secondary"
        [size]="buttonSize.SMALL"
        (click)="onSave(value())"
      ></atom-vfi-button>
      <atom-vfi-button
        icon="fa-solid fa-x"
        type="secondary"
        [size]="buttonSize.SMALL"
        (click)="onCancel()"
      ></atom-vfi-button>
    </div>
    }
    @if (hint() && !warning() && !error()) {
    <p class="mb-0 hint-text iregular-10">
      {{ hint() }}
    </p>
    }
    @if (warning() && warningMessage() && !subtle()) {
    <p class="mb-0 hint-text">
      {{ warningMessage() || hint() }}
    </p>
    }
    @if (error() && errorMessage() && !subtle()) {
    <p class="mb-0 error-text">
      {{ errorMessage() || hint() }}
    </p>
    }
  </div>
</div>
