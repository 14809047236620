@if (show()) {
  <div
    nz-tooltip
    nzTooltipTitle="{{ abs(delta()) | number: '':'en' }} alarms"
    nzTooltipPlacement="right"
    class="performance-badge text-center pl-1 pr-1"
  [ngClass]="{
    'green-8-bg': percentage() > 0,
    'red-7-bg': percentage() < 0,
    'gray-4-bg': percentage() === 0 || !isFinite(percentage())
  }"
    aria-label="performance-indicator"
    >
    @if (isFinite(percentage())) {
      <div
        class="d-flex align-items-center justify-content-center"
        >
        <i
          class="regular-12 neutral-1 fas"
      [ngClass]="{
        'fa-arrow-down': percentage() > 0,
        'fa-arrow-up': percentage() < 0
      }"
        ></i>
        <p class="percentage neutral-1 regular-14 mb-0 fw-500 cursor-default">
          {{ abs(percentage()) | number: '':'en' }}%
        </p>
      </div>
    } @else {
      <span class="neutral-1 regular-14 text-center"> - </span>
    }
  </div>
}

