<div class="vfi-multi-select">
  @if (label()) {
    <div
      class="vfi-label"
      [class.yellow-6]="required() && !readOnly()"
      >
      <span>{{ label() }}</span>
    </div>
  }
  <button
    class="vfi-multi-select-input"
    aria-haspopup="listbox"
    aria-label="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showDropdown"
    [nzPopoverPlacement]="['bottom', 'top']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="dropdownContent"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    (nzPopoverVisibleChange)="fetchFields()"
    [disabled]="disabled()"
    [class.gray-8-bg]="disabled()"
    [class.required-field]="required() && !value()"
    >
    @if (!value()?.length) {
      <div
        class="vfi-multi-select-placeholder d-flex"
        >
        {{ placeholder() }}
      </div>
    }
    @if (value()?.length) {
      <div
        class="vfi-multi-select-value"
        >
        {{ formatDisplayValue(value()) }}
      </div>
    }
  </button>
</div>
<ng-template #dropdownContent>
  <atom-vfi-select-popup
    [options]="options"
    [selectedValues]="selectedOptions"
    [loading]="loading"
    (scrolled)="onScroll(searchValue)"
    (searched)="onSearchChange($event)"
    (valueChanged)="valueChanged($event)"
  ></atom-vfi-select-popup>
</ng-template>
