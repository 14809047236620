import { Component, input, model, output } from '@angular/core';
import { SelectDropdownOption } from '@vfi-ui/models';

import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';

@Component({
  selector: 'atom-vfi-select-popup',
  templateUrl: './vfi-select-popup.component.html',
  styleUrls: ['./vfi-select-popup.component.scss'],
  imports: [InfiniteScrollDirective, DropdownItemComponent],
})
export class VfiSelectPopupComponent {
  readonly options = input<SelectDropdownOption[]>([]);
  readonly selectedValues = model<SelectDropdownOption[]>([]);
  readonly loading = input(false);
  readonly scrolled = output<boolean>();
  readonly searched = output<string>();
  readonly valueChanged = output<SelectDropdownOption[]>();

  constructor() {}

  /**
   * handle option selected event
   *
   * @param {SelectDropdownOption} ev
   * @memberof VfiSelectPopupComponent
   */
  optionSelected(ev: SelectDropdownOption) {
    const index = this.selectedValues().findIndex(
      (option) => option?.value === ev?.value
    );
    if (index > -1) {
      this.selectedValues.update((selectedValues) =>
        selectedValues.filter((option) => option?.value !== ev?.value)
      );
    } else {
      this.selectedValues.update((selectedValues) => [...selectedValues, ev]);
    }
    this.valueChanged.emit(this.selectedValues());
  }

  /**
   * check if option is selected
   *
   * @param {SelectDropdownOption} ev
   * @returns
   * @memberof VfiSelectPopupComponent
   */
  isChecked(ev: SelectDropdownOption) {
    return (
      this.selectedValues().findIndex((option) => option?.value === ev?.value) >
      -1
    );
  }
}
