<label
  nz-checkbox
  [ngModel]="value()"
  [nzDisabled]="disabled()"
  (ngModelChange)="changed($event)"
  >{{ label() }}
</label>
@if (labelIcon()) {
  <i
    class="ml-1 mr-1 iregular-12 {{ labelIcon() }}"
    nz-tooltip
    [nzTooltipTitle]="labelIconTooltip()"
  ></i>
}
@if (labelCheck()) {
  <i
    class="ml-1 fa-solid fa-check check-green iregular-12"
    nz-tooltip
    nzTooltipTitle="Verified"
  ></i>
}
