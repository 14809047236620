<div class="multi-level-select">
  <button
    class="dropdown-button"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showMultiLevelSelect"
    [nzPopoverPlacement]="['bottom', 'top']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="multiLevelDropdown"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    [disabled]="disabled()"
    [class.igray-8-bg]="disabled()"
  >
    @if (!pointEntityValue() && !value()) {
    <div class="dropdown-label-placeholder">
      {{ placeholder() }}
    </div>
    }
    @if (pointEntityValue() || value()) {
    <div class="value w-100">
      <label>{{ placeholder() }}</label>
      <div class="dropdown-label d-flex align-items-start">
        {{ pointEntityValue()?.name || value() }}
      </div>
    </div>
    }
  </button>
</div>
<ng-template #multiLevelDropdown>
  <atom-multi-level-select-input
    [pointEntity]="pointEntity()"
    (currentValueChange)="
      pointEntityValue.set($event);
      onValueChange();
      showMultiLevelSelect = false
    "
  ></atom-multi-level-select-input>
</ng-template>
