import {
  Component,
  SimpleChanges,
  OnChanges,
  input,
  output,
  model,
} from '@angular/core';
import {
  CriterionSelection,
  GlobalSortAndFiltersCriterion,
  filterModelMode,
  DEFAULT_ADD_CRITERION,
  Priorities,
  BOOL_OPTIONS,
  Users,
  SelectDropdownOption,
  DeviceAgent,
  LensCategory,
  criterionSelectionType,
  DEFAULT_NONE_CRITERION,
  AUTOMATION_RULE_CREATOR,
  MultiLevelInputOption,
  INCLUDES_EXCLUDES_OPTIONS,
} from '@vfi-ui/models';
import {
  fastParse,
  hasReqFields,
  isNil,
  get,
  set,
  last,
  orderBy,
} from '@vfi-ui/util/helpers';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import {
  VfiSelectComponent,
  VfiSelectMultipleComponent,
  SelectMultipleComponent,
  PointEntitySelectorComponent,
  VfiMultiSelectComponent,
  VfiAssigneeSelectorComponent,
  VfiInputComponent,
} from '@vfi-ui/ui/atoms';
import { IncludeExcludeComponent } from '../include-exclude/include-exclude.component';
import {
  NzDatePickerComponent,
  NzRangePickerComponent,
} from 'ng-zorro-antd/date-picker';
import { FormsModule } from '@angular/forms';
import { NzInputNumberLegacyComponent } from 'ng-zorro-antd/input-number-legacy';
import { NzRowDirective } from 'ng-zorro-antd/grid';

@Component({
  selector: 'nuclei-criterion-selection',
  templateUrl: './criterion-selection.component.html',
  styleUrls: ['./criterion-selection.component.scss'],
  imports: [
    NgTemplateOutlet,
    VfiSelectComponent,
    VfiSelectMultipleComponent,
    IncludeExcludeComponent,
    SelectMultipleComponent,
    PointEntitySelectorComponent,
    NzDatePickerComponent,
    NzRangePickerComponent,
    FormsModule,
    NgClass,
    NzInputNumberLegacyComponent,
    VfiMultiSelectComponent,
    VfiAssigneeSelectorComponent,
    NzRowDirective,
    VfiInputComponent,
  ],
})
export class CriterionSelectionComponent implements OnChanges {
  readonly mode = input<filterModelMode>(filterModelMode.FILTERS);
  readonly coreCriterion = model<CriterionSelection[]>([]);
  readonly filtersCriterion = model<GlobalSortAndFiltersCriterion[]>([]);
  readonly criterion = input<CriterionSelection[]>([]);
  readonly users = input<Users[]>([]);
  readonly allowDelete = input(true);
  readonly showCoreCriterion = input(true);
  readonly showNone = input(false);
  readonly showGroupLabels = input(false);
  readonly type = input<string>(undefined);
  readonly isFilterModalOpen = input(false);
  readonly isClone = input(false);
  readonly alarmStatuses = input<string[]>(undefined);
  readonly device = input(DeviceAgent.DESKTOP);
  readonly createLensCategory = input<LensCategory>(undefined);
  readonly required = input(false);
  readonly teamName = input<string>(undefined);
  readonly appliedFilters = output<CriterionSelection[]>();
  readonly addedFilters = output<CriterionSelection[]>();
  readonly selectedCriterion = output<CriterionSelection[]>();
  readonly selectedCriterionName = output<CriterionSelection[]>();
  readonly _criterion = model<CriterionSelection[]>([]);
  filterModelMode = filterModelMode;
  criterionSelectionType = criterionSelectionType;
  lensCategory = LensCategory;
  statusFilters = [];
  priorities = Priorities;
  isNil = isNil;
  boolOptions = BOOL_OPTIONS;
  userOptions: SelectDropdownOption[];
  creatorOptions: SelectDropdownOption[];
  deviceAgent = DeviceAgent;
  mobileDateTime = [];
  filtersCriterionOptions: SelectDropdownOption[];
  includeExcludeOptions = INCLUDES_EXCLUDES_OPTIONS;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const criterionChanged = get(changes._criterion, 'currentValue', false);
    const filterModalChanged = get(
      changes.isFilterModalOpen,
      'currentValue',
      false
    );
    if (
      (changes.criterion && changes.criterion.currentValue) ||
      (criterionChanged && filterModalChanged)
    ) {
      this.setCriterionByMode();
    }
    if (changes.coreCriterion && changes.coreCriterion.currentValue) {
      this.setCoreCriterion();
    }
    if (changes.filtersCriterion && changes?.filtersCriterion?.currentValue) {
      this.setFilterCriterionOptions();
    }

    this.setUserOptions(changes);
    this.setAlarmStatusFilters();
    this.addNewCriterion();
    this.checkForMissingCriterion();
  }

  /**
   * remove a _criterion from _criterion list by index
   *
   * @param {number} criterionIndex
   * @memberof CriterionSelectionComponent
   */
  removeCriterion(criterionIndex: number) {
    const _criterion = this._criterion();
    if (_criterion.length !== 1 && !isNil(_criterion[0].model)) {
      _criterion.splice(criterionIndex, 1);

      this.checkForMissingCriterion();
      this.selectedCriterion.emit(_criterion);
    }
  }

  /**
   * a _criterion was selected so add details type here
   *
   * @param {string} GlobalSortAndFiltersCriterion
   * @param {number} criterionIndex
   * @memberof CriterionSelectionComponent
   */
  criterionSelected(filtersCriterion, criterionIndex: number) {
    const crit = fastParse(this._criterion());
    const filtersCriterionIndex = this.filtersCriterion().findIndex(
      (d) =>
        d.dbName === filtersCriterion?.value?.dbName &&
        d.category === filtersCriterion?.value?.category
    );
    if (filtersCriterion?.value?.dbName === 'none') {
      crit[criterionIndex] = DEFAULT_NONE_CRITERION;
    } else {
      crit[criterionIndex].detail =
        this.filtersCriterion()[filtersCriterionIndex];
    }
    this._criterion.set(crit);
    this.checkForMissingCriterion();
    this.filtersCriterion()[filtersCriterionIndex].show = false;
    this.selectedCriterionName.emit(this._criterion());
  }

  /**
   * set a select type value for a _criterion
   *
   * @param {*} changes
   * @param {number} criterionIndex
   * @param {*} [selection]
   * @memberof CriterionSelectionComponent
   */
  valueInputChanged(changes, criterionIndex: number, selection?) {
    const values = Array.isArray(changes)
      ? changes.map((change) => change?.value)
      : !isNil(changes?.value)
        ? changes?.value
        : changes;
    selection.value = values;
    const _criterion = this._criterion();
    set(_criterion, `[${criterionIndex}].detail.selection`, {});
    set(_criterion, `[${criterionIndex}].detail.selection.value`, values);
    this.addMoreCriterion();
    this.selectedCriterion.emit(_criterion);
  }
  /**
   * set a select type value for a _criterion
   *
   * @param {string | number | boolean} changes
   * @param {number} criterionIndex
   * @memberof CriterionSelectionComponent
   */
  userInputChanged(
    changes: string | number | boolean | MultiLevelInputOption[],
    criterionIndex: number
  ) {
    const _criterion = this._criterion();
    set(_criterion, `[${criterionIndex}].detail.selectValues`, this.users());
    set(_criterion, `[${criterionIndex}].detail.selection`, {});
    set(
      _criterion,
      `[${criterionIndex}].detail.selection.value`,
      (changes as MultiLevelInputOption[]).map((user) => user.id)
    );
    this.addMoreCriterion();
    this.selectedCriterion.emit(_criterion);
  }

  /**
   * set a select type value for a full _criterion
   *
   * @param SelectDropdownOption[] changes
   * @param {number} criterionIndex
   * @memberof CriterionSelectionComponent
   */
  fullValuesInputChanged(
    changes: SelectDropdownOption[],
    criterionIndex: number
  ) {
    const _criterion = this._criterion();
    set(_criterion, `[${criterionIndex}].detail.selection`, {});
    set(
      _criterion,
      `[${criterionIndex}].detail.selection.value`,
      changes.map((ch) => ch.value)
    );
    set(
      _criterion,
      `[${criterionIndex}].detail.selection.name`,
      changes.map((ch) => ch.label)
    );
    set(
      _criterion,
      `[${criterionIndex}].detail.selection.fullValues`,
      changes.map((ch) => ({
        name: ch.label,
        value: ch.value,
      }))
    );
    this.addMoreCriterion();
    this.selectedCriterion.emit(_criterion);
  }

  /**
   * set values for multi level selector change
   *
   * @param {MultiLevelInputOption} changes
   * @param {number} criterionIndex
   * @memberof CriterionSelectionComponent
   */
  multiLevelSelectorChanged(
    changes: MultiLevelInputOption,
    criterionIndex: number
  ) {
    const _criterion = this._criterion();
    set(_criterion, `[${criterionIndex}].detail.selection.value`, [
      changes?.id,
    ]);
    set(_criterion, `[${criterionIndex}].detail.selection.name`, [
      changes?.name,
    ]);
    this.addMoreCriterion();
    this.selectedCriterion.emit(_criterion);
  }

  /**
   * set a date range type value for a _criterion
   *
   * @param {string[]} changes
   * @param {number} criterionIndex
   * @memberof CriterionSelectionComponent
   */
  rangeInputChanged(changes: string[], criterionIndex: number) {
    const _criterion = this._criterion();
    set(_criterion, `[${criterionIndex}].detail.selection`, {});
    set(_criterion, `[${criterionIndex}].detail.selection.min`, changes[0]);
    set(_criterion, `[${criterionIndex}].detail.selection.max`, changes[1]);
    this.addMoreCriterion();
    this.selectedCriterion.emit(_criterion);
  }

  /**
   * set a min max range type value for a _criterion
   *
   * @param {string} changes
   * @param {number} criterionIndex
   * @param {('min' | 'max' | 'minHrs' | 'minMins' | 'maxHrs' | 'maxMins')} type
   * @memberof CriterionSelectionComponent
   */
  minMaxInputChanged(
    changes: string,
    criterionIndex: number,
    type:
      | 'min'
      | 'max'
      | 'maxDays'
      | 'minDays'
      | 'minHrs'
      | 'minMins'
      | 'maxHrs'
      | 'maxMins'
  ) {
    const _criterion = this._criterion();
    set(
      _criterion,
      `[${criterionIndex}].detail.selection[${type}]`,
      !Number.isNaN(+changes) && changes !== '' ? +changes : null
    );
    this.addMoreCriterion();
    this.selectedCriterion.emit(_criterion);
  }

  /**
   * add new row for criterion
   *
   * @memberof CriterionSelectionComponent
   */
  addMoreCriterion() {
    this.addedFilters.emit(fastParse(this._criterion()));
    this.addNewCriterion();
  }

  /**
   * checks if selected filter has a valid value
   *
   * @param {string} dbName
   * @returns
   * @memberof CriterionSelectionComponent
   */
  hasReqCriterion(dbName: string) {
    const crit = this._criterion().find((c) => c.model === dbName);
    if (crit) {
      if (!isNil(crit.detail.selection)) {
        return hasReqFields([crit]);
      }
      return false;
    }
    return false;
  }

  /**
   * returns if value is valid
   *
   * @param {*} value
   * @returns
   * @memberof CriterionSelectionComponent
   */
  hasValue(value) {
    return !isNil(value);
  }

  /**
   * mark a criterion as an exclusion
   *
   * @param {boolean} isExclusion
   * @param {*} criterionIndex
   * @memberof CriterionSelectionComponent
   */
  markExclusion(isExclusion: boolean, criterionIndex) {
    const _criterion = this._criterion();
    set(_criterion, `[${criterionIndex}].detail.isExclusion`, isExclusion);
    if (_criterion[criterionIndex]?.detail?.selection) {
      this.selectedCriterion.emit(_criterion);
    }
  }

  /**
   * handle selection selected event
   *
   * @param {*} index
   * @param {SelectDropdownOption} ev
   * @memberof CriterionSelectionComponent
   */
  handleSelectionChange(index, ev: SelectDropdownOption) {
    const crit = fastParse(this._criterion());
    crit[index].model = ev?.value;
    this._criterion.set(crit);
  }

  /**
   * handles date time change for mobile
   *
   * @param {Date} date
   * @param {number} dateTimeindex
   * @param {number} index
   * @returns
   * @memberof CriterionSelectionComponent
   */
  mobileDateTimeChanged(date: Date, dateTimeindex: number, index: number) {
    this.mobileDateTime[dateTimeindex] = date;
    if (
      this.mobileDateTime.length === 2 &&
      !this.mobileDateTime.includes(undefined)
    ) {
      this.rangeInputChanged(this.mobileDateTime, index);
    }
    return this.mobileDateTime;
  }

  /**
   * Checks if filters should be readonly
   * during editing of lenses
   *
   * @param {CriterionSelection} selection
   * @param {filterModelMode} mode
   * @param {LensCategory} createLensCategory
   * @return {*}  {boolean}
   * @memberof CriterionSelectionComponent
   */
  isReadonlyFilter(
    selection: CriterionSelection,
    mode: filterModelMode,
    createLensCategory: LensCategory
  ): boolean {
    if (createLensCategory === LensCategory.ACTIVE_WORK) {
      return (
        selection?.detail?.dbName === 'state' &&
        mode === filterModelMode.EDIT_LENS
      );
    }

    if (createLensCategory === LensCategory.ACTIVE_ALARMS) {
      return (
        selection?.detail?.dbName === 'isActive' &&
        mode === filterModelMode.EDIT_LENS
      );
    }

    if (createLensCategory === LensCategory.ACTIVE_ASSETS) {
      return (
        selection?.detail?.dbName === 'isActive' &&
        mode === filterModelMode.EDIT_LENS
      );
    }

    return false;
  }

  /**
   * set filter criterion options
   *
   * @private
   * @memberof CriterionSelectionComponent
   */
  private setFilterCriterionOptions() {
    this.filtersCriterionOptions = this.filtersCriterion()
      .filter((crit) => crit?.show)
      .map((c) => ({
        label: c?.name,
        value: c as any,
      }));
  }

  /**
   * sort criteria based on disabled state
   *
   * @private
   * @param {CriterionSelection[]} crit
   * @returns
   * @memberof CriterionSelectionComponent
   */
  private sortCriteriaByDisabledState(crit: CriterionSelection[]) {
    return crit.sort((a: CriterionSelection, b: CriterionSelection) => {
      if (isNil(a.model)) {
        return 1;
      } else if (isNil(b.model)) {
        return -1;
      } else {
        return (
          +this.isReadonlyFilter(b, this.mode(), this.createLensCategory()) -
            +this.isReadonlyFilter(a, this.mode(), this.createLensCategory()) ||
          b?.detail?.name?.localeCompare(a?.detail?.name)
        );
      }
    });
  }

  /**
   * Add blank new criterion
   *
   * @private
   * @memberof CriterionSelectionComponent
   */
  private addNewCriterion() {
    const canAddMore = this.filtersCriterion()?.every((filter) => !filter.show);
    const _criterion = this._criterion();
    if (
      _criterion.length &&
      this.type() !== criterionSelectionType.NOTIFICATION_EXCLUDES &&
      !canAddMore
    ) {
      if (!isNil(last(_criterion).model)) {
        _criterion.push({
          model: null,
        });
      }
    }
  }

  /**
   * check for missing filters in the dropdown so we can show when they deselect or clear all
   *
   * @private
   * @memberof CriterionSelectionComponent
   */
  private checkForMissingCriterion() {
    const filtersCriterion = this.filtersCriterion();
    const mode = this.mode();
    if (filtersCriterion && mode !== filterModelMode.CREATE_LENS) {
      const newFiltersCriterion = filtersCriterion.map((crit) => {
        const isSelected = this._criterion().findIndex(
          (selected) =>
            selected?.detail?.dbName === crit?.dbName &&
            selected?.detail?.category === crit?.category
        );
        const isCoreSelected = this.coreCriterion().findIndex(
          (selected) =>
            selected?.detail?.dbName === crit.dbName &&
            selected?.detail?.category === crit?.category
        );

        return { ...crit, show: isSelected === -1 && isCoreSelected === -1 };
      });
      this.filtersCriterion.set(newFiltersCriterion);
    } else if (filtersCriterion && mode === filterModelMode.CREATE_LENS) {
      const newFiltersCriterion = filtersCriterion.map((crit) => {
        const isSelected = this._criterion().findIndex(
          (selected) =>
            selected?.detail?.dbName === crit.dbName &&
            selected?.detail?.category === crit?.category
        );

        return {
          ...crit,
          show: isSelected === -1,
        };
      });
      this.filtersCriterion.set(newFiltersCriterion);
    }
    this.setFilterCriterionOptions();
  }

  /**
   * format criterion full values
   *
   * @private
   * @param {CriterionSelection[]} criterion
   * @returns
   * @memberof CriterionSelectionComponent
   */
  private formatCriterionFullValues(criterion: CriterionSelection[]) {
    let formattedCrit = fastParse(criterion);

    formattedCrit = formattedCrit.map((crit) => {
      const hasCriterion = !isNil(crit.model) && crit.model !== 'none';

      if (hasCriterion) {
        const critName = get(crit.detail.selection, 'name', null);
        const critValue = get(crit.detail.selection, 'value', null);

        if (critName && critValue) {
          const fullValues = critName.map((name, i) => ({
            name,
            value: critValue[i],
          }));
          crit.detail.selection.fullValues = fullValues;
        }
      }

      return crit;
    });
    return formattedCrit;
  }

  /**
   * set criterion based on mode
   *
   * @private
   * @memberof CriterionSelectionComponent
   */
  private setCriterionByMode() {
    const crit = this.sortCriteriaByDisabledState(
      fastParse(this.criterion())
    ).filter((c) => c?.model);
    if (this.mode() === filterModelMode.CREATE_LENS && !this.isClone()) {
      if (this.teamName) {
        this._criterion.set([...crit, DEFAULT_ADD_CRITERION]);
      } else {
        this._criterion.set(fastParse([DEFAULT_ADD_CRITERION]));
      }
    } else {
      this._criterion.set(crit);
    }
    this._criterion.set(this.formatCriterionFullValues(this._criterion()));
  }

  /**
   * set core criterion
   *
   * @private
   * @memberof CriterionSelectionComponent
   */
  private setCoreCriterion() {
    this.checkForMissingCriterion();
    this.coreCriterion.update((coreCriterion) =>
      this.formatCriterionFullValues(coreCriterion)
    );
  }

  /**
   * set user options
   *
   * @private
   * @param {SimpleChanges} changes
   * @memberof CriterionSelectionComponent
   */
  private setUserOptions(changes: SimpleChanges) {
    if (changes.users && changes.users.currentValue) {
      this.userOptions = changes.users.currentValue.map((user) => ({
        value: user.id,
        label: user.displayName,
      }));
      this.creatorOptions = orderBy(
        [AUTOMATION_RULE_CREATOR, ...this.userOptions],
        ['label'],
        ['asc']
      );
    }
  }

  /**
   * set status filters for alarm statuses
   *
   * @private
   * @memberof CriterionSelectionComponent
   */
  private setAlarmStatusFilters() {
    const alarmStatuses = this.alarmStatuses();
    if (alarmStatuses) {
      this.statusFilters = alarmStatuses.map((status) => ({
        label: status,
        value: status,
      }));
    }
  }
}
