import { environment } from '@vfi-ui/environments/environment';
import { adminTabRoutes, Customers } from '@vfi-ui/models';
import { Component, SimpleChanges, OnChanges, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
  selector: 'nuclei-admin-tabs-main',
  templateUrl: './admin-tabs-main.component.html',
  styleUrls: ['./admin-tabs-main.component.scss'],
  imports: [RouterLink, NgClass],
})
export class AdminTabsMainComponent implements OnChanges {
  readonly url = input<string>(undefined);
  readonly listRoute = input<string>(undefined);
  readonly dashRoute = input<string>(undefined);
  readonly analyticsGoalsRoute = input<string>(undefined);
  readonly currentCustomer = input<Customers>(undefined);
  readonly showCmmsConfig = input(false);
  baseUrl: string;
  environment = environment;
  adminRoutes = adminTabRoutes;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentCustomer && changes.currentCustomer.currentValue) {
      this.baseUrl = `/${this.currentCustomer().name}/${this.currentCustomer().id}`;
    }
  }

  /**
   * show active status based off url
   *
   * @param {string} url
   * @param {string} type
   * @returns {boolean}
   * @memberof AlarmTabsMainComponent
   */
  isActive(url: string, type: string): boolean {
    return url.includes(type);
  }
}
