<div [ngClass]="{ 'cursor-not-allowed': disabled() }">
  <div
    class="vfi-input"
    [ngClass]="{
      disabled: disabled(),
    }"
  >
    @if (label()) {
      <div class="vfi-label">
        <span [class.yellow-6]="required() && !readOnly()">{{ label() }}</span>
        @if (labelIcon()) {
          <i
            class="ml-1 mr-1 {{ labelIcon() }}"
            nz-tooltip
            [nzTooltipTitle]="labelIconTooltip()"
          ></i>
        }
        @if (labelCheck()) {
          <i
            class="ml-1 fa-solid fa-check check-green"
            nz-tooltip
            nzTooltipTitle="Verified"
          ></i>
        }
      </div>
    }
    <div
      class="input-icons position-relative"
      [ngClass]="{
        subtle: subtle(),
        success: success(),
        warning: warning(),
        error: error(),
        'pointer-none': readOnly() || disabled(),
      }"
    >
      <textarea
        [ngModel]="value()"
        (ngModelChange)="onInputChange($event)"
        [ngClass]="{ 'disabled-input': disabled() }"
        class="vfi-textarea"
        [class.emailBody]="emailBody()"
        [class.readOnly]="readOnly()"
        [maximumHeight]="1000"
        [minHeight]="48"
        [maxlength]="maxLength()"
        [placeholder]="placeholder()"
        [reset]="reset()"
        atomAutoGrow
        [isReadOnly]="readOnly()"
        tabindex="-1"
      ></textarea>
    </div>
    @if (hint() && !warning() && !error()) {
      <p class="mb-0 hint-text">{{ hint() }}</p>
    }
    @if (warning() && warningMessage() && !subtle()) {
      <p class="mb-0 hint-text">
        {{ warningMessage() || hint() }}
      </p>
    }
    @if (error() && errorMessage() && !subtle()) {
      <p class="mb-0 error-text">
        {{ errorMessage() || hint() }}
      </p>
    }
  </div>
</div>
