import {
  Component,
  OnChanges,
  SimpleChanges,
  input,
  model,
} from '@angular/core';
import {
  WEEKDAYS,
  FREQUENCIES,
  INSTANTANEOUSLY_WARNING,
  WEEKDAY_OPTIONS,
  NotificationTimeType,
  TimeRange,
} from '@vfi-ui/models';
import { UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { isBefore, isSameHour, isSameMinute } from 'date-fns';
import { isNil } from '@vfi-ui/util/helpers';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { NzRadioGroupComponent, NzRadioComponent } from 'ng-zorro-antd/radio';
import { NzTimePickerComponent } from 'ng-zorro-antd/time-picker';

import { VfiMultiSelectComponent, VfiInputComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'nuclei-notification-time',
  templateUrl: './notification-time.component.html',
  styleUrls: ['./notification-time.component.scss'],
  imports: [
    NzTooltipDirective,
    NzRadioGroupComponent,
    FormsModule,
    NzRadioComponent,
    VfiMultiSelectComponent,
    NzTimePickerComponent,
    VfiInputComponent,
  ],
})
export class NotificationTimeComponent implements OnChanges {
  readonly isVisible = input<boolean>(undefined);
  readonly form = input<UntypedFormGroup>(undefined);
  readonly timezone = input<string>(undefined);
  readonly timeRange = input<string>(undefined);
  readonly isWork = input<boolean>(false);
  readonly timeType = model<NotificationTimeType>(NotificationTimeType.Always);
  dayOptions = WEEKDAYS;
  weekdayOptions = WEEKDAY_OPTIONS;
  frequencies = FREQUENCIES;
  showError = false;
  instantaneouslyWarning = INSTANTANEOUSLY_WARNING;
  notificationTimeType = NotificationTimeType;
  timeRanges: TimeRange[] = [{ from: null, to: null }];
  showTimeRange = false;

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    if (change?.timeRange?.currentValue) {
      this.timeRanges = change?.timeRange?.currentValue;
      this.showTimeRange = this.timeRanges.length === 2;
    }
  }

  /**
   * handle value changed event
   *
   * @param {string} formName
   * @param {*} value
   * @memberof NotificationTimeComponent
   */
  valueChanged(formName: string, value) {
    let formValue = value;
    if (Array.isArray(value)) {
      formValue = value.map((v) => v.label);
    }
    this.form().get(formName).setValue(formValue);
    this.form().markAsDirty();
  }

  /**
   * determines if dates are within valid time range
   *
   * @param {*} startTime
   * @param {*} endTime
   * @returns
   * @memberof NotificationTimeComponent
   */
  checkDates(from: string, to: string) {
    if (isNil(from) && isNil(to)) {
      return false;
    }
    const startTime = new Date(from);
    const endTime = new Date(to);
    const beforeTime = isBefore(endTime, startTime);
    const sameTime =
      isSameHour(endTime, startTime) && isSameMinute(endTime, startTime);
    return beforeTime || sameTime;
  }

  /**
   * update form validators
   *
   * @param {*} startTime
   * @param {*} endTime
   * @memberof NotificationTimeComponent
   */
  setValidators() {
    this.form().get('timeRanges').setValidators(Validators.required);
    this.form().get('timeRanges').updateValueAndValidity();
  }

  /**
   * clear time ranges form validators
   *
   * @memberof NotificationTimeComponent
   */
  clearValidators() {
    this.form().get('timeRanges').clearValidators();
    this.form().get('timeRanges').updateValueAndValidity();
  }

  /**
   * handle time type change event
   *
   * @param {NotificationTimeType} type
   * @memberof NotificationTimeComponent
   */
  timeTypeChanged(type: NotificationTimeType) {
    const scheduleFields = ['dayOfWeek', 'timeRanges'];
    if (type === NotificationTimeType.Always) {
      scheduleFields.forEach((field) => {
        this.form().get(field).clearValidators();
        this.form().get(field).setValue([]);
        this.form().get(field).updateValueAndValidity();
      });
      this.clearValidators();
    }
    if (type === NotificationTimeType.Schedule) {
      scheduleFields.forEach((field) => {
        this.form().get(field).setValidators(Validators.required);
        this.form().get(field).updateValueAndValidity();
      });
      this.setValidators();
    }
    this.form().updateValueAndValidity();
  }

  /**
   * handle time range changed event
   *
   * @param {number} index
   * @param {string} key
   * @param {Date} time
   * @memberof NotificationTimeComponent
   */
  timeRangeChanged(index: number, key: string, time: Date) {
    this.timeRanges[index] = { ...this.timeRanges[index], [key]: time };
    this.checkTimeRangesValidity();
    this.setValidators();
  }

  /**
   * add time range
   *
   * @memberof NotificationTimeComponent
   */
  addTimeRange() {
    this.timeRanges.push({ from: null, to: null });
    this.showTimeRange = true;
    this.form().get('timeRanges').setValue([]);
    this.setValidators();
  }

  /**
   * remove time range
   *
   * @memberof NotificationTimeComponent
   */
  removeTimeRange() {
    this.showTimeRange = false;
    this.timeRanges.pop();
    this.checkTimeRangesValidity();
  }

  /**
   * check time range validity
   *
   * @private
   * @memberof NotificationTimeComponent
   */
  private checkTimeRangesValidity() {
    if (this.timeRanges.every((range) => range?.from && range?.to)) {
      this.showError = this.timeRanges.every((range) =>
        this.checkDates(range?.from, range?.to)
      );
      if (!this.showError) {
        this.form().get('timeRanges').setValue(this.timeRanges);
        this.clearValidators();
        this.form().markAsDirty();
        this.form().updateValueAndValidity();
      }
    } else {
      this.form().get('timeRanges').setValue([]);
    }
  }
}
