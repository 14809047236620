@if (showForm) {
<div>
  <form [formGroup]="passwordForm()">
    @if (changePassword()) {
    <div
      class="input-wrapper mb-5"
      [class.input-active]="passwordForm().value.oldPassword"
    >
      <input
        type="password"
        class="form-control"
        formControlName="oldPassword"
        [class.required-field]="
              passwordForm().controls['oldPassword'].errors?.noMatch
            "
      />
      <label>Old Password</label>
      @if (passwordForm().controls['oldPassword'].errors?.noMatch) {
      <p
        aria-label="oldPasswordError"
        class="mt-2 red-5 mb-0"
      >
        Your old password is incorrect
      </p>
      }
    </div>
    }
    <div
      class="input-wrapper"
      [class.input-active]="passwordForm().value.password"
      [class.required-field]="
          passwordForm().controls['confirmPassword'].errors?.noMatch
        "
    >
      <input
        type="password"
        class="form-control"
        formControlName="password"
      />
      <label class="d-flex">New Password</label>
    </div>
    <div
      class="input-wrapper mt-2"
      [class.input-active]="passwordForm().value.confirmPassword"
    >
      <input
        type="password"
        class="form-control"
        formControlName="confirmPassword"
        [class.required-field]="
            passwordForm().controls['confirmPassword'].errors?.noMatch
          "
      />
      <label class="d-flex">Repeat New Password</label>
      <p
        aria-label="repeatError"
        *ngIf="passwordForm().controls['confirmPassword'].errors?.noMatch"
        class="mt-2 mb-0 red-5"
      >
        Your new passwords don't match
      </p>
    </div>
    <div class="conditions d-flex mt-2 neutral-2">
      <div class="d-flex flex-column align-items-start mr-3">
        <span><i [ngClass]="
                passwordForm().dirty &&
                passwordForm().controls['password'].value &&
                !passwordForm().controls['password'].hasError('hasSmallCase')
                  ? 'fa fa-check-circle'
                  : 'far fa-circle'
              "></i>
          One lowercase character</span>
        <span><i [ngClass]="
                passwordForm().dirty &&
                passwordForm().controls['password'].value &&
                !passwordForm().controls['password'].hasError('hasCapitalCase')
                  ? 'fa fa-check-circle'
                  : 'far fa-circle'
              "></i>
          One uppercase character</span>
        <span><i [ngClass]="
                passwordForm().dirty &&
                passwordForm().controls['password'].value &&
                !passwordForm().controls['password'].hasError('hasNumber')
                  ? 'fa fa-check-circle'
                  : 'far fa-circle'
              "></i>
          One number</span>
      </div>
      <div class="conditions_sec d-flex flex-column ml-5">
        <span><i [ngClass]="
                passwordForm().dirty &&
                passwordForm().controls['password'].value &&
                !passwordForm().controls['password'].hasError(
                  'hasSpecialCharacters'
                )
                  ? 'fa fa-check-circle'
                  : 'far fa-circle'
              "></i>
          One special character</span>
        <span><i [ngClass]="
                passwordForm().dirty &&
                passwordForm().controls['password'].value &&
                !passwordForm().controls['password'].hasError('minlength')
                  ? 'fa fa-check-circle'
                  : 'far fa-circle'
              "></i>&nbsp;8 character minimum</span>
      </div>
    </div>
    @if (showWarning()) {
    <p class="yellow-6 mb-0 mt-2">Changing your password will log you out</p>
    }
  </form>
</div>
}
