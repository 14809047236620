@for (user of selectedUsers; track user; let i = $index) {
  <div>
    @if (user) {
      <div class="d-flex align-items-center w-100 mt-3">
        <i
          attr.aria-label="removeRecirpientIcon-{{ i }}"
          class="fa-solid fa-minus red-6 regular-14 cursor-pointer mr-2"
          (click)="removeRecipient(user.id)"
        ></i>
        <p
          attr.aria-label="recipientName-{{ i }}"
          class="fw-400 regular-14 dm40 mb-0"
          >
          {{ user.displayName }}
        </p>
      </div>
    }
  </div>
}
@if (usersList?.length) {
  <div class="d-flex align-items-center mt-3">
    <div class="assignee-selector">
      <atom-assignee-selector
        [simpleMode]="true"
        [vendorUsers]="true"
        [activeUsers]="true"
        aria-label="addRecipientInput"
        [displaySelectedValue]="false"
        placeholder="Add Recipient"
        [multiSelect]="true"
        [showCurrentUser]="true"
        [removeSelectedUsers]="true"
        [selectedUsers]="selectedUserIds"
        [disabled]="disabled()"
        [disablePushUsers]="disablePushUsers()"
        [disableTextUsers]="disableTextUsers()"
        (assigneeChanged)="addRecipient(null, $event)"
      ></atom-assignee-selector>
    </div>
  </div>
}
