<div
  [ngClass]="{ 'cursor-not-allowed': disabled() }"
  #select
  nz-popover
  [(nzPopoverVisible)]="showDropdown"
  nzPopoverTrigger="click"
  [nzPopoverContent]="vfiDropdown"
  [nzPopoverPlacement]="['bottom']"
  [nzPopoverOverlayStyle]="{ width: select?.offsetWidth + 'px' }"
  nzPopoverOverlayClassName="dropdown-container"
  (nzPopoverVisibleChange)="onPopoverVisibleChange($event)"
  [ngClass]="{
    'pointer-none': readOnly() || disabled(),
  }"
  >
  <div class="vfi-input {{ size() }}" [ngClass]="{ disabled: disabled() }">
    @if (label()) {
      <div class="label" [class.iyellow-6]="required() && !readOnly()">
        <span>{{ label() }}</span>
      </div>
    }
    <div
      class="select-icons position-relative cursor-pointer"
      [ngClass]="{ subtle: subtle(), success: success(), warning: warning(), error: error() }"
      >
      <span [ngClass]="{ hasIcon: iconLeft() }"></span>
      <div
        tabindex="0"
        class="select d-flex align-items-center"
        [ngClass]="{
          'select-icon-left-spacing': iconLeft(),
          'select-icon-right-spacing': value() && !success() && !error() && !warning(),
          'select-icon-right-state-spacing':
            value() && !subtle() && (success() || warning() || error()),
          readOnly: readOnly(),
          disabled: disabled(),
        }"
        >
        @if (placeholder()?.length && !value()?.length) {
          <span
            class="placeholder ellipsis"
            >{{ placeholder() }}</span
            >
          }
          @if (value()?.length) {
            <span class="value">{{
              formatDisplayValues()
            }}</span>
          }
        </div>
        <i class="{{ iconLeft() }} icon icon-left"></i>
        <span>
          @if (success() && !subtle()) {
            <i
              class="fa-solid fa-check icon cursor-pointer success-icon icon-right"
            ></i>
          }
          @if (warning() && !subtle()) {
            <i
              class="fa-solid fa-triangle-exclamation icon cursor-pointer warning-icon icon-right"
            ></i>
          }
          @if (error() && !subtle()) {
            <i
              class="fa-solid fa-circle-exclamation icon cursor-pointer error-icon icon-right"
            ></i>
          }
          @if (
            value() &&
            (showDropdown || canClear()) &&
            value() &&
            canClear() &&
            !success() &&
            !warning() &&
            !error() &&
            !readOnly()
            ) {
            <i
              class="fa-solid fa-circle-x icon first-icon-right icon-right regular-12 clear-icon"
              (click)="$event.stopPropagation(); cleared.emit()"
            ></i>
          }
          @if (!success() && !warning() && !error() && !readOnly()) {
            <i
              class="fa-solid fa-chevron-down icon icon-right regular-12"
            ></i>
          }
        </span>
        @if (hint() && !warning() && !error()) {
          <p class="mb-0 hint-text">
            {{ hint() }}
          </p>
        }
        @if (warning() && warningMessage() && !subtle()) {
          <p class="mb-0 hint-text">
            {{ warningMessage() || hint() }}
          </p>
        }
        @if (error() && errorMessage() && !subtle()) {
          <p class="mb-0 error-text">
            {{ errorMessage() || hint() }}
          </p>
        }

        <ng-template #vfiDropdown>
          <div class="dropdown">
            <div class="dropdown-content">
              @if (parentData()?.name?.length) {
                <div
                  class="back-label d-flex align-items-center"
                  >
                  <atom-vfi-button
                    size="small"
                    [text]="parentData()?.name"
                    ariaLabel="back-btn"
                    type="tertiary"
                    icon="fa-solid fa-chevron-left"
                    (clicked)="onBackSelected(parentData())"
                  ></atom-vfi-button>
                </div>
              }
              @if (showSearch()) {
                <div class="search">
                  <input
                    #searchInput
                    class="dropdown-search"
                    type="text"
                    placeholder="Search"
                    (keyup)="onSearch($event.target.value)"
                    />
                </div>
              }
              @if (formattedOptions.length) {
                <div
                  class="options-container"
                  #optionsContainer
                  infiniteScroll
                  [infiniteScrollDistance]="2"
                  [infiniteScrollThrottle]="50"
                  [infiniteScrollContainer]="optionsContainer"
                  (scrolled)="onOptionsScroll()"
                  >
                  @for (o of formattedOptions; track o) {
                    <div
                      class="option d-flex align-items-center justify-content-between"
                      [class.selected]="isChecked(o)"
                      (click)="onOptionSelect(o)"
                      >
                      <span class="ellipsis" [title]="o?.label">{{ o?.label }}</span>
                      @if (o?.hasChildren) {
                        <i
                          class="fa-solid fa-chevron-right ml-1"
                        ></i>
                      }
                      @if (value()?.includes(o?.label)) {
                        <i
                          class="fa-solid fa-check ml-1"
                        ></i>
                      }
                    </div>
                  }
                </div>
              }
              @if (!formattedOptions?.length) {
                <div
                  class="d-flex align-items-center justify-content-center results"
                  >
                  @if (!loading()) {
                    <span class="no-results">No results</span>
                  }
                  @if (loading()) {
                    <span class="no-results">Loading...</span>
                  }
                </div>
              }
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
