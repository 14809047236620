import { Apollo } from 'apollo-angular';
import { POST_WORK_TICKET_QUERY } from './../queries/work-order.query';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import {
  WorkTicketCreateInput,
  ERROR_SAVE_WORK_TICKETS,
  WorkTicket,
} from '@vfi-ui/models';
import { catchError, filter, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
@Injectable({
  providedIn: 'root',
})
export class WorkOrderDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * create/post a work ticket
   *
   * @param {WorkTicketCreateInput} input
   * @param {string} externalSystem
   * @returns
   * @memberof WorkOrderDataService
   */
  postWorkTicket(input: WorkTicketCreateInput, externalSystem: string) {
    return this.apollo
      .mutate<{ createWorkTicket: WorkTicket }>({
        mutation: POST_WORK_TICKET_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(ERROR_SAVE_WORK_TICKETS);
          return throwError(err);
        }),
        tap((r) => {
          const wt = `W${r?.data?.createWorkTicket?.id}`;
          const text = input?.isExternalSyncEnabled
            ? `Work Ticket ${wt} was created successfully and is currently syncing with ${externalSystem}.`
            : `Work Ticket ${wt} was created successfully.`;
          this.notification.showSuccess(`${wt} - Work Ticket Created!`, text);
        })
      );
  }
}
