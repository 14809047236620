<div class="dropdown-list" role="listbox" aria-expanded="false">
  @if (
    (parent.length === 1 && type()) ||
    (baseEntity()?.id ? parent[0] : parent[parent?.length - 1])
    ) {
    <div
      class="back"
      (click)="back()"
      >
      <i class="fas fa-chevron-left"></i>
      <span>{{ parent[parent?.length - 1]?.name }}</span>
    </div>
  }

  <form [formGroup]="formGroup">
    <input
      type="text"
      class="dropdown-search"
      placeholder="Search"
      formControlName="search"
      (keyup)="search($event.target.value)"
      />
  </form>

  @if (loading) {
    <div class="alert">Loading...</div>
  }
  @if (options?.length === 0 && !loading) {
    <div class="alert">No Results</div>
  }

  @if (showTypeSelector() && !type()) {
    <div class="list-container">
      @for (o of entityTypeOptions; track o) {
        <atom-dropdown-item
          [showCheckbox]="!showTypeSelector()"
          [item]="o"
          [title]="o?.name"
          [showBadge]="formGroup.get('search').value"
          [badgeCount]="o?.name === 'Space' ? spaceTypeCount : assetTypeCount"
          [showRightIcon]="true"
          (drillDownChange)="pickEntityType($event)"
        ></atom-dropdown-item>
      }
    </div>
  }

  @if (!loading && type()) {
    <div
      class="list-container"
      #listContainer
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="listContainer"
      (scrolled)="scroll()"
      >
      @for (o of options; track o) {
        <atom-dropdown-item
          [item]="o"
          [title]="type() === 'Asset' ? formatAssetName(o) : o?.name"
          [selected]="formGroup.get('selected').value === o"
          [showBadge]="formGroup.get('search').value"
          [showRightIcon]="o?.count > 0"
          (selectChange)="formGroup.get('selected').setValue($event)"
          (drillDownChange)="drillDown($event)"
        ></atom-dropdown-item>
      }
    </div>
  }

  @if (options?.length && !loading && type()) {
    <button
      class="select-btn"
      [disabled]="formGroup.get('selected').invalid"
      (click)="select(formGroup.get('selected').value)"
      >
      {{ buttonLabel() }}
    </button>
  }
</div>
