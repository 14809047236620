<div class="login w-100 d-flex align-content-center justify-content-center">
  <div class="align-self-center">
    <div class="login_wrapper">
      <div class="login_form">
        <div class="login_box">
          <section
            class="login_content h-100 d-flex flex-column align-items-center justify-content-between"
            >
            <div
              class="form_wrapper w-100 d-flex flex-column align-items-center justify-around"
              >
              <img src="/assets/vfi-logo.svg" alt="vfi-logo" class="vfi-logo" />
              @if (user$ | async) {
                <div class="form-container w-100">
                  <div
                    class="d-flex align-items-center justify-content-center mt-5 mb-5"
                    >
                    <p class="reset_title regular-20 font-light neutral-1 mb-1">
                      Reset Your Password
                    </p>
                  </div>
                  <div class="mb-5">
                    <nuclei-set-password
                      (formValid)="checkFormValid($event)"
                      [passwordForm]="passwordForm"
                    ></nuclei-set-password>
                  </div>
                  @if (user$ | async; as user) {
                    <atom-vfi-button
                      class="w-100"
                      text="Change Password"
                      ariaLabel="changePassword"
                      type="secondary"
                      classes="block-button"
                      [disabled]="!formValid"
                      (clicked)="changePassword()"
                    ></atom-vfi-button>
                  }
                </div>
              }
              @if ((user$ | async) === null) {
                <div
                  class="fw-500 regular-20 neutral-2 expiredMessage"
                  >
                  <p>
                    Sorry, it looks like your change password link expired or is
                    invalid.
                  </p>
                  <span>Please reset your password again</span>
                </div>
              }
            </div>
            <div
              class="gray-1 regular-12 fw-normal pt-2"
              [class.mt-4]="(user$ | async) === null"
              >
              <p class="copyright">
                &copy; {{ currentYear }} Virtual Facility, Inc. All Rights
                Reserved.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
