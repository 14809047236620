import { Component, OnChanges, SimpleChanges, input } from '@angular/core';
import { MINUTE_IN_MILLISECONDS, TimeValues } from '@vfi-ui/models';
import { calculateReadableMSTime, isNull } from '@vfi-ui/util/helpers';

@Component({
  selector: 'nuclei-duration-value',
  templateUrl: './duration-value.component.html',
  styleUrls: ['./duration-value.component.scss'],
  imports: [],
})
export class DurationValueComponent implements OnChanges {
  readonly duration = input(undefined);
  readonly color = input('dm00');
  readonly fontSize = input(14);
  timeValues: TimeValues;
  isNull = isNull;
  minuteInMs = MINUTE_IN_MILLISECONDS;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.duration) {
      this.timeValues = null;
      this.timeValues = calculateReadableMSTime(this.duration());
    }
  }
}
