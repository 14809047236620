// TODO: fix this
/* eslint-disable @nx/enforce-module-boundaries */

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  input,
  model,
  output,
  viewChild,
} from '@angular/core';
import {
  EntityManagementDataService,
  SpaceManagementDataService,
} from '@vfi-ui/data-access/shared';
import { BaseComponent } from '@vfi-ui/feature/core';
import { VfiSelectOption, SelectSize, AssetClass } from '@vfi-ui/models';
import { fastParse, isNil } from '@vfi-ui/util/helpers';
import { Subject, debounceTime } from 'rxjs';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { NgClass } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'atom-vfi-select-single',
  templateUrl: './vfi-select-single.component.html',
  styleUrls: ['./vfi-select-single.component.scss'],
  imports: [NzPopoverDirective, NgClass, InfiniteScrollDirective],
})
export class VfiSelectSingleComponent extends BaseComponent implements OnInit {
  readonly searchInput = viewChild<ElementRef>('searchInput');
  readonly size = input(SelectSize.MEDIUM);
  readonly ariaLabel = input('vfi-select');
  readonly placeholder = input('');
  readonly value = input<string>(undefined);
  readonly label = input<string>(undefined);
  readonly type = input<string>(undefined);
  readonly hint = input<string>(undefined);
  readonly required = input(false);
  readonly iconLeft = input<string>(undefined);
  readonly readOnly = input(false);
  readonly disabled = input(false);
  readonly subtle = input(false);
  readonly success = input(false);
  readonly warning = input(false);
  readonly warningMessage = input<string>(undefined);
  readonly error = input(false);
  readonly errorMessage = input<string>(undefined);
  readonly showSearch = input(true);
  readonly loading = model(false);
  readonly canClear = input(false);
  readonly enableDropdown = input(true);
  readonly autofocus = input(false);
  readonly closeOnSelect = input(false);
  readonly placement = input(null);
  readonly queryType = input(null);
  readonly cleared = output();
  readonly dropdownVisibleChanged = output<boolean>();
  readonly scrolled = output<boolean>();
  readonly optionSelected = output<VfiSelectOption>();
  readonly drilldownSelected = output<VfiSelectOption>();
  options: VfiSelectOption[] = [];
  baseOptions = [];
  showDropdown = false;
  searchValue = null;
  seach$ = new Subject<string>();

  constructor(
    private cdr: ChangeDetectorRef,
    private spaceManagementService: SpaceManagementDataService,
    private entityService: EntityManagementDataService
  ) {
    super();
  }

  ngOnInit() {
    this.seach$.pipe(debounceTime(300)).subscribe((search) => {
      this.fetchOptions(search);
    });
  }

  /**
   * fetch options based on type
   *
   * @param {string} [$event]
   * @param {number} [offset=0]
   * @memberof VfiSelectSingleComponent
   */
  fetchOptions($event?: string, offset = 0) {
    this.searchValue = $event;
    this.loading.set(true);
    const type = this.type();
    if (type === 'spaceType') {
      this.getSpaceTypeOptions($event, offset);
    } else if (type === 'entityClasses') {
      this.getEntityClassOptions(this.queryType(), $event, offset);
    }
  }

  /**
   * call data service to fetch space type options
   *
   * @param {string} search
   * @param {number} offset
   * @memberof VfiSelectSingleComponent
   */
  getSpaceTypeOptions(search: string, offset: number) {
    search = isNil(search) ? '' : search;
    this.spaceManagementService
      .fetchSpaceTypes(offset, search)
      .subscribe((r) => {
        const spaceTypes =
          offset > 0 ? [...this.baseOptions, ...r.spaceTypes] : r.spaceTypes;
        this.baseOptions = fastParse(spaceTypes);
        this.options = spaceTypes.map((spaceType) => ({
          label: spaceType?.name,
          value: spaceType?.name,
        })) as VfiSelectOption[];
        this.loading.set(false);
        this.cdr.detectChanges();
      });
  }

  /**
   * call data service to fetch entity class options
   *
   * @param {AssetClass} type
   * @param {string} search
   * @param {number} offset
   * @memberof VfiSelectSingleComponent
   */
  getEntityClassOptions(type: AssetClass, search: string, offset: number) {
    search = isNil(search) ? '' : search;
    this.entityService
      .fetchEntityClassOptions(type, search, offset)
      .subscribe((r) => {
        const entityClasses =
          offset > 0
            ? [...this.baseOptions, ...r.entityClasses]
            : r.entityClasses;
        this.baseOptions = fastParse(entityClasses);
        this.options = entityClasses.map((spaceType) => ({
          label: spaceType?.name,
          value: spaceType?.id,
        })) as VfiSelectOption[];
        this.loading.set(false);
        this.cdr.detectChanges();
      });
  }

  /**
   * handle option select event
   *
   * @param {VfiSelectOption} option
   * @memberof VfiSelectSingleComponent
   */
  onOptionSelect(option: VfiSelectOption) {
    this.optionSelected.emit(option);

    if (this.closeOnSelect()) {
      this.showDropdown = false;
      this.onPopoverVisibleChange(false);
    }
  }

  /**
   * handle on search event
   *
   * @param {string} ev
   * @memberof VfiSelectSingleComponent
   */
  onSearch(search: string) {
    this.seach$.next(search);
  }

  /**
   * handle popover visible change event
   *
   * @param {boolean} show
   * @memberof VfiSelectSingleComponent
   */
  onPopoverVisibleChange(show: boolean) {
    this.dropdownVisibleChanged.emit(show);
    if (show) {
      this.fetchOptions();
      this.autoFocusSearch();
    }
  }

  /**
   * handle select clicked event
   *
   * @memberof VfiSelectSingleComponent
   */
  onSelectClicked() {
    if (!this.enableDropdown()) {
      this.dropdownVisibleChanged.emit(true);
    }
  }

  /**
   * handle options scroll event
   *
   * @memberof VfiSelectSingleComponent
   */
  onOptionsScroll() {
    this.fetchOptions(this.searchValue, this.baseOptions.length);
  }

  /**
   * auto focus on search input
   *
   * @memberof VfiSelectSingleComponent
   */
  autoFocusSearch() {
    this.cdr.detectChanges();
    if (this.autofocus() && this.showSearch()) {
      this.searchInput().nativeElement.focus();
    }
  }
}
