import { gql } from 'apollo-angular';

const USERS_RETURN_FIELDS = `
        id
        uid
        phone {
          number
          isVerified
          isSubscribed
        }
        email {
          address
          isVerified
          isSubscribed
        }
        displayName
        firstName
        lastName
        role
        lastSignInTime
        lastActiveAt
        lastInviteTime
        landingPage
        landingPageLens {
          id
          name
          category
          type
        }
        analyticsPage
        teamMembers {
            user {
              id
              displayName
            }
            team {
              id
              name
            }
            permissions {
              role
            }
          }
        avatarUrl
        isPushNotificationEnabled
        isIntegrationStatusAlertEmailsEnabled
        company
        status
        authRole
        permissions
        isSuper
        isSso
      `;

export const GET_ALL_USERS_QUERY = `
      query getUsers($where: UserFilterInput, $orderBy: UserSortInput, $page: UserPageInput) {
        Users: getUsers(where: $where, orderBy: $orderBy, page: $page) {
          id
          uid
          phone {
            number
            isVerified
            isSubscribed
          }
          email {
            address
            isVerified
            isSubscribed
          }
          displayName
          firstName
          lastName
          role
          lastSignInTime
          lastInviteTime
          landingPage
          landingPageLens {
            id
            name
            category
            type
          }
          analyticsPage
          teamMembers {
            user {
              id
              displayName
            }
            team {
              id
              name
            }
            permissions {
              role
            }
          }
          avatarUrl
          company
          status
          lastActiveAt
          authRole
          authRoleId
          permissions
          isSuper
          isSso
          isPushNotificationEnabled
        }
      }
    `;
export const GET_USERS_BY_IDS_QUERY = `
  query getUsers($where: UserFilterInput, $orderBy: UserSortInput, $page: UserPageInput) {
      Users: getUsers(where: $where, orderBy: $orderBy, page: $page) {
          id
          uid
          phone {
            number
            isVerified
            isSubscribed
          }
          email {
            address
            isVerified
            isSubscribed
            forwarding {
              isEnabled
              recipient
              until
            }
          }
          emailRecipientUser {
            id
            displayName
            avatarUrl
          }
          displayName
          firstName
          lastName
          role
          lastSignInTime
          lastInviteTime
          lastActiveAt
          landingPage
          landingPageLens {
            id
            name
            category
            type
          }
          analyticsPage
          teamMembers {
            user {
              id
              displayName
            }
            team {
              id
              name
            }
            permissions {
              role
            }
          }
          avatarUrl
          company
          status
          isPushNotificationEnabled
          isIntegrationStatusAlertEmailsEnabled
          emailRecipientUser {
            id
            displayName
          }
          authRole
          permissions
          isSuper
          isSso
        }
      }
    `;
export const GET_USERS_BY_UIDS_QUERY = `
  query getUsers($where: UserFilterInput, $orderBy: UserSortInput, $page: UserPageInput) {
      Users: getUsers(where: $where, orderBy: $orderBy, page: $page) {
       ${USERS_RETURN_FIELDS}
      }
  }
`;

export const ME_QUERY = gql`
  query {
    me {
      ${USERS_RETURN_FIELDS}
    }
  }
`;

export const CUSTOMER_API_KEY_QUERY = gql`
  query getCustomerApiKey($id: Int!) {
    getCustomerApiKey(customerId: $id)
  }
`;

export const ADD_DEVICE_REGISTRATION_TOKEN = gql`
  mutation addDeviceRegistrationToken($token: String!) {
    addDeviceRegistrationToken(token: $token)
  }
`;

export const REMOVE_DEVICE_REGISTRATION_TOKEN = gql`
  mutation removeDeviceRegistrationToken($token: String!) {
    removeDeviceRegistrationToken(token: $token)
  }
`;

export const RESET_PASSWORD_QUERY = `
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token)
  }
`;

export const SEND_RESET_PASSWORD = `
  mutation sendResetPassword($email: String!) {
    sendResetPassword(email: $email)
  }
`;

export const CREATE_USER_QUERY = gql`
  mutation createUser(
    $user: UserCreateInput!
    $customerId: Int!
    $sendInvite: Boolean
  ) {
    createUser(user: $user, customerId: $customerId, sendInvite: $sendInvite)
  }
`;

export const VALIDATE_TOKEN_QUERY = `
mutation validateActivationToken($token: String!)
{
  validateActivationToken(token: $token) {
    displayName
    emailAddress
  }
}`;

export const RESEND_INVITE_USER_QUERY = `
  mutation resendInviteUser($email: String!) {
    resendInviteUser(email: $email)
  }
`;

export const UPDATE_USER_QUERY = gql`
  mutation updateUser($id: String!, $user: UserUpdateInput!) {
    updateUser(id: $id, user: $user)
  }
`;

export const ACTIVATE_USER_QUERY = `
mutation activateUser($customerId: Int!, $password: String!, $token: String!)
{
  activateUser(customerId: $customerId, password: $password, token: $token)
}`;

export const GET_ALL_CUSTOMERS = `{
      customer: getCustomers {
        id
        name
        defaultTimezone
        onboardingStatus
        isSSOEnabled
        isMFAEnabled
        isNotificationsSilenced
        pilotDateEnd
        isPilot
        domains
        isCloseWorkRulesBypassEnabled
        isCmmsSyncEnabled
        isDisplayRawAlarmTextEnabled
        userSessionExpiryMins
      }
    }`;

export const GET_CUSTOMERS_BY_ID = `
      query getCustomers($options: CustomerOptions) {
        getCustomers(options: $options) {
          id
          name
          defaultTimezone
          isSSOEnabled
          pilotDateEnd
          isPilot
          domains
          isDisplayRawAlarmTextEnabled
          isCloseWorkRulesBypassEnabled
          isCmmsSyncEnabled
          isBASPriorityEnabled
          isNotificationsSilenced
          isMFAEnabled
          parentLocationName
          userSessionExpiryMins
          smsNotificationCount
          emailNotificationCount
          maxNotificationLimit
          products {
            id
            name
          }
          globalCustomerId
        }
      }`;

export const CREATE_CUSTOMER = `
      mutation createCustomer($customerData: CustomerCreateInput!) {
        createCustomer(customerData: $customerData) {
          id
          name
          products {
            id
            name
          }
        }
      }`;

export const UPDATE_CUSTOMER_QUERY = `
    mutation updateCustomer($id: Int!, $data: CustomerUpdateInput!) {
      updateCustomer(id: $id, data: $data) {
        id
      }
    }`;

export const GET_CUSTOMERS_INFO = `{
    products: getCustomerInfo {
        products { name }
        domains
      }
    }`;

export const GET_CUSTOM_TOKEN = gql`
  mutation {
    getCustomToken
  }
`;
export const GET_ZEN_DESK_TOKEN = `{
  token: getZendeskToken
}
`;

export const SET_USER_ACCESS_QUERY = `
  mutation setUserAccess($id: String!, $enabled: Boolean!) {
    setUserAccess(id: $id, enabled: $enabled) {
      status
    }
  }
`;

export const GET_ORGANIZATIONS_QUERY = `
query companiesAndCount($options: CompanyOptions!) {
  companiesAndCount(options: $options) {
   items {
     name
   }
  }
}`;

export const CREATE_ORGANIZATION_QUERY = `
mutation createCompany($name: String!) {
  createCompany(name: $name) {
    name
  }
}`;

export const DELETE_USER_QUERY = `
  mutation deleteUser($id: String!) {
    deleteUser(id: $id)
  }
`;

export const DISABLE_MFA_QUERY = gql`
  mutation disableUserMFA($id: String!) {
    disableUserMFA(id: $id)
  }
`;

export const UPDATE_RELATED_ENTITY_CONFIG_QUERY = gql`
  mutation updateRelatedEntityConfig($input: RelatedEntityConfigInput!) {
    updateRelatedEntityConfig(input: $input) {
      isEnabled
    }
  }
`;

export const RELATED_ENTITY_CONFIG_QUERY = gql`
  query {
    relatedEntityConfig {
      isEnabled
    }
  }
`;

export const GET_LOGIN_SSO_CONFIG = `
  query getLoginSsoConfig($email: String!) {
    getLoginSsoConfig(email: $email) {
      isSso
      tenantId
      providerId
    }
  }
`;

export const ACTIVATE_SSO_USER = `
  mutation {
    activateSsoUser
  }
`;

export const GET_CUSTOMER_ALARM_CONFIG_QUERY = gql`
  query getAlarmConfig {
    alarmConfig {
      isUpdateStatusReasonRequired
    }
  }
`;

export const UPDATE_CUSTOMER_ALARM_CONFIG_QUERY = gql`
  mutation updateAlarmConfig($input: AlarmConfigInput!) {
    updateAlarmConfig(input: $input) {
      isUpdateStatusReasonRequired
    }
  }
`;

export const CHANGE_PASSWORD_QUERY = gql`
  mutation changePassword($password: String!) {
    changePassword(password: $password)
  }
`;
