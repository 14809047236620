<div
  class="item d-flex"
  [class.disabled]="disabled()"
  [class.selected]="selected()"
  [ngStyle]="{ paddingRight: showRightIcon() ? '0' : '8px' }"
  >
  @if (item() && showCheckbox()) {
    <input
      class="vfi-checkbox"
      type="checkbox"
      [value]="item()"
      [checked]="selected()"
      (click)="selectItem()"
      />
  }
  <div
    class="ellipsis title"
    (click)="item().count > 0 && showDrilldown() ? drillDown() : selectItem()"
    [nzTooltipTitle]="title()"
    nzTooltipPlacement="right"
    [nzTooltipTrigger]="showTooltips() ? 'hover' : null"
    nzTooltipMouseEnterDelay="1"
    nz-tooltip
    >
    {{ title() }}
    @if (item()?.count && showBadge()) {
      <nz-badge
        [nzStandalone]="true"
        [nzShowZero]="false"
        [nzCount]="badgeCount()"
      ></nz-badge>
    }
  </div>
  @if (customRightIcon()) {
    <i
      [class]="'fas ' + customRightIcon() + ' pr-1 right-icon custom-right-icon'"
      [class.showRightIcon]="alwaysShowRightIcon()"
      (click)="rightIconClick.emit()"
    ></i>
  }
  @if (showRightIcon()) {
    <i
      class="fas fa-chevron-right pr-1 right-icon"
      (click)="drillDown()"
    ></i>
  }
</div>
