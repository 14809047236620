import { WorkTicketDataService } from '@vfi-ui/data-access/shared';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  SimpleChanges,
  OnChanges,
  ElementRef,
  input,
  output,
  viewChildren,
  model,
} from '@angular/core';
import { take } from 'rxjs/operators';
import {
  FormGroupDirective,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import {
  ALARM_ATTRIBUTE_OPTION,
  CmmsDisplayTemplate,
  SelectSize,
  VfiSelectOption,
} from '@vfi-ui/models';
import { fastParse, isEmpty } from '@vfi-ui/util/helpers';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { NgClass } from '@angular/common';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'atom-vfi-cmms-select-single',
  templateUrl: './vfi-cmms-select-single.component.html',
  styleUrls: ['./vfi-cmms-select-single.component.scss'],
  imports: [
    NzPopoverDirective,
    NgClass,
    NzTooltipDirective,
    InfiniteScrollDirective,
  ],
})
export class VfiCmmsSelectSingleComponent implements OnInit, OnChanges {
  readonly inputformControlName = input<string>(undefined);
  readonly size = input(SelectSize.MEDIUM);
  readonly name = input<string>(undefined);
  readonly placeholder = input<string>(undefined);
  readonly label = input<string>(undefined);
  readonly value = model<string>(undefined);
  readonly defaultLabel = input<string>(undefined);
  readonly defaultValue = input<string>(undefined);
  readonly labelCheck = input(false);
  readonly labelIcon = input<string>(undefined);
  readonly labelIconTooltip = input<string>(undefined);
  readonly required = input(false);
  readonly subtle = input(false);
  readonly disabled = input<boolean>(undefined);
  readonly readOnly = input(false);
  readonly customQuery = input<string>(undefined);
  readonly displayTemplate = input<CmmsDisplayTemplate>(undefined);
  readonly customValidator = input<boolean>(undefined);
  readonly clearValueOnChange = input(false);
  readonly useAlarmAttributeOption = input(false);
  readonly inputId = input<string>(undefined);
  readonly cmmsValueSort = input(false);
  readonly canClear = input<boolean>(true);
  readonly inputChanged = output<string | string[]>();
  readonly enterEvent = output<string>();
  readonly focusChanged = output<string>();
  readonly input = viewChildren<ElementRef>('input');
  childForm;
  baseOptions: VfiSelectOption[] = [];
  options: VfiSelectOption[] = [];
  optionsTotalCount = 0;
  searchValue = null;
  loading = false;
  showDropdown = false;
  selectedOption: VfiSelectOption = null;
  isEmpty = isEmpty;

  constructor(
    private parentForm: FormGroupDirective,
    private changeDetection: ChangeDetectorRef,
    private workTicketService: WorkTicketDataService
  ) {}

  ngOnInit() {
    if (this.inputformControlName()) {
      this.setFormGroup();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.name && this.clearValueOnChange()) {
      this.value.set(null);
    }
  }

  /**
   * set set child form group
   *
   * @memberof SelectSingleComponent
   */
  setFormGroup() {
    this.childForm = this.parentForm.form;
    const inputformControlName = this.inputformControlName();
    if (this.customValidator() && inputformControlName) {
      this.childForm.controls[inputformControlName].setValidators([
        Validators.required,
        this.inputValidator.bind(this),
      ]);
    }
  }

  /**
   * fetch custom query if it is available
   *
   * @param {string} $event
   * @memberof SelectSingleComponent
   */
  fetchFields($event: string, offset = 0) {
    this.searchValue = $event;
    this.loading = true;
    const customQuery = this.customQuery();
    if (customQuery === 'cmms') {
      this.getCmmsFieldOptions(
        $event,
        this.inputId(),
        this.cmmsValueSort(),
        offset
      );
    }

    this.focusChanged.emit(this.name());
  }

  onScroll(search: string) {
    if (this.baseOptions.length !== this.optionsTotalCount) {
      this.fetchFields(search, this.baseOptions.length);
    }
  }

  /**
   * call data service to fetch dynamic cmms options
   *
   * @param {string} event
   * @param {string} externalCmmsFieldId
   * @param {boolean} useValueSort
   * @param {number} [offset=0]
   * @memberof SelectSingleComponent
   */
  getCmmsFieldOptions(
    event: string,
    externalCmmsFieldId: string,
    useValueSort: boolean,
    offset = 0
  ) {
    const search = event || '';
    const externalDetails = this.childForm?.value || {};
    this.workTicketService
      .fetchCmmsFieldOptions({
        externalCmmsFieldId,
        search,
        externalDetails,
        useValueSort,
        offset,
      })
      .pipe(take(1))
      .subscribe((data) => {
        const op = data?.externalCmmsDomainValues.map((v) => ({
          label: this.getOptionLabel(
            { label: v?.name, value: v?.value },
            this.customQuery()
          ),
          value: v?.value,
        }));
        const cmmsFields = offset > 0 ? [...this.baseOptions, ...op] : [...op];
        this.baseOptions = fastParse(cmmsFields);
        this.options = cmmsFields;
        this.optionsTotalCount = data.externalCmmsDomainValueCount;
        if (this.useAlarmAttributeOption()) {
          this.options.unshift({
            label: ALARM_ATTRIBUTE_OPTION,
            value: ALARM_ATTRIBUTE_OPTION,
          });
        }
        this.loading = false;
        if (!isEmpty(this.options)) {
          this.baseOptions = this.options;
        }
        this.changeDetection.detectChanges();
      });
  }

  /**
   * emits value when changed
   *
   * @param {VfiSelectOption} option
   * @memberof SelectSingleComponent
   */
  valueChanged(option: VfiSelectOption) {
    this.selectedOption = option;
    const value = option?.value || null;
    const inputformControlName = this.inputformControlName();
    if (this.childForm && inputformControlName) {
      this.childForm.patchValue({
        [inputformControlName]: value,
      });
      this.childForm.markAsDirty();
      this.childForm.controls[inputformControlName].markAsDirty();
    }
    this.inputChanged.emit(value);
    this.showDropdown = false;
  }

  /**
   * return option label
   *
   * @param {*} option
   * @param {string} customQuery
   * @returns
   * @memberof SelectSingleComponent
   */
  getOptionLabel(option, customQuery: string) {
    if (customQuery === 'cmms') {
      let op = option?.label;
      const displayTemplate = this.displayTemplate();
      if (displayTemplate === CmmsDisplayTemplate.KeyValue) {
        op = `${option?.value} - ${option?.label}`;
      } else if (displayTemplate === CmmsDisplayTemplate.Value) {
        op = `${option?.value}`;
      }
      return op;
    }
    return option?.label;
  }

  /**
   * handle dropdown close event
   *
   * @memberof SelectSingleComponent
   */
  handleCloseChange() {
    this.baseOptions = [];
    this.optionsTotalCount = 0;
    this.searchValue = null;
  }

  /**
   * handle popover visible change event
   *
   * @memberof VfiCmmsSelectSingleComponent
   */
  onPopoverVisibleChange() {
    this.fetchFields(null);
  }

  /**
   * fetch field value
   *
   * @param {string} value
   * @returns
   * @memberof VfiCmmsSelectSingleComponent
   */
  getFieldLabel(value: string) {
    return (
      this.selectedOption?.label ||
      this.options.find((o) => o?.value === value)?.label ||
      this.getOptionLabel(
        { label: this.defaultLabel(), value },
        this.customQuery()
      ) ||
      value
    );
  }

  /**
   * clear field value
   *
   * @memberof VfiCmmsSelectSingleComponent
   */
  clearValue() {
    this.value.set(null);
    this.selectedOption = null;
    this.valueChanged(null);
  }

  /**
   * custom validator to check if the entered input is valid
   *
   * @private
   * @param {*} control
   * @returns {(ValidationErrors | null)}
   * @memberof SelectSingleComponent
   */
  private inputValidator(control): ValidationErrors | null {
    if (control.value !== '') {
      if (this.baseOptions.includes(control.value)) {
        return null;
      }
      return { inputValid: false };
    }
    return { inputValid: false };
  }
}
