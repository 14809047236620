@if (workState() === workResolutionState.OpenWork) {
  <i
    class="fa-solid fa-clipboard-list mr-1 regular-{{ size() }} openWork"
    nzTooltipTitle="Open Work"
    nzTooltipPlacement="top"
    nz-tooltip
  ></i>
}
@if (workState() === workResolutionState.MultipleWork) {
  <img
    src="/assets/work-multiple.svg"
    class="mr-1"
    [width]="size()"
    [height]="size()"
    alt="multiple_work"
    nzTooltipTitle="Multiple Work Tickets"
    nzTooltipPlacement="top"
    nz-tooltip
    />
}
@if (workState() === workResolutionState.GoodClosedWork) {
  <img
    src="/assets/work-closed-good.svg"
    class="mr-1"
    [width]="size()"
    [height]="size()"
    alt="work_closed_good"
    nzTooltipTitle="Resolved Closed Work"
    nzTooltipPlacement="top"
    nz-tooltip
    />
}
@if (workState() === workResolutionState.BadClosedWork) {
  <img
    src="/assets/work-closed-bad.svg"
    class="mr-1"
    [width]="size()"
    [height]="size()"
    alt="work_closed_bad"
    nzTooltipTitle="Unresolved Closed Work"
    nzTooltipPlacement="top"
    nz-tooltip
    />
}
@if (workState() === workResolutionState.NoWork) {
  <span class="mr-1">—</span>
}
