import { Component, OnInit, input, output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MultiLevelInputOption } from '@vfi-ui/models';
import { NgClass } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'atom-multi-level-input',
  templateUrl: './multi-level-input.component.html',
  styleUrls: ['./multi-level-input.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollDirective,
    DropdownItemComponent,
    NzTooltipDirective,
    NgClass,
  ],
})
export class MultiLevelInputComponent implements OnInit {
  readonly multiSelect = input(false);
  readonly options = input<MultiLevelInputOption[]>([]);
  readonly type = input<string>(undefined);
  readonly selectedValue = input<
    MultiLevelInputOption | MultiLevelInputOption[]
  >(undefined);
  readonly parent = input<MultiLevelInputOption[]>(undefined);
  readonly loading = input(false);
  readonly showSelectButton = input(false);
  readonly buttonLabel = input('Select');
  readonly optionsBySection = input(false);
  readonly unassignedOptions = input<MultiLevelInputOption[]>(undefined);
  readonly showDrilldown = input(true);
  readonly showTypeSelector = input(true);
  readonly showSearch = input(true);
  readonly backClicked = output<boolean>();
  readonly valueChange = output<
    MultiLevelInputOption | MultiLevelInputOption[]
  >();
  readonly searchChange = output<string>();
  readonly newSelected = output<boolean>();
  readonly drilldownClick = output<{
    option?: MultiLevelInputOption;
    searchTerm?: string;
    type?: string;
  }>();
  readonly scrolled = output<{
    searchTerm: string;
  }>();

  offset = 0;
  limit = 50;
  dropdownOpen = false;
  selectedValueDupe: MultiLevelInputOption | MultiLevelInputOption[];
  formGroup = new FormGroup({
    search: new FormControl(''),
    selected: new FormControl(null, Validators.required),
  });

  ngOnInit() {
    const selectedValue = this.selectedValue();
    if (selectedValue) {
      this.selectedValueDupe = JSON.parse(JSON.stringify(selectedValue));
      this.formGroup.get('selected').setValue(this.selectedValueDupe);
    }

    if (this.multiSelect() && !selectedValue) {
      this.selectedValueDupe = [];
      this.formGroup.get('selected').setValue([]);
    }
  }

  /**
   * drill down to child
   *
   * @param {MultiLevelInputOption} option
   * @memberof MultiLevelInputComponent
   */
  drillDown(option: MultiLevelInputOption) {
    if (option.count > 0) {
      this.formGroup.get('selected').setValue([]);
      this.formGroup.get('selected').setErrors(null);
      this.offset = 0;
      const searchTerm = this.formGroup.get('search').value;
      this.drilldownClick.emit({
        option,
        searchTerm,
        type: this.type(),
      });
    }
  }

  /**
   * handle search change
   * @param {string} searchTerm
   * @memberof MultiLevelInputComponent
   */
  search(searchTerm: string) {
    this.formGroup.get('search').setValue(searchTerm);
    this.searchChange.emit(searchTerm);
  }

  /**
   * handle scroll event
   * @memberof MultiLevelInputComponent
   */
  scroll() {
    this.scrolled.emit({ searchTerm: this.formGroup.get('search').value });
  }

  /**
   * handle option selected event
   *
   * @param {{ name: EntityType }} $event
   * @memberof MultiLevelSelectInputComponent
   */
  pickOption(option: MultiLevelInputOption) {
    if (this.multiSelect()) {
      const index = this.formGroup
        .get('selected')
        .value.map((u) => u.id)
        .indexOf(option.id);
      if (index > -1) {
        this.formGroup.get('selected').value.splice(index, 1);
        this.formGroup.get('selected').setErrors(null);
      } else {
        this.formGroup
          .get('selected')
          .setValue([...this.formGroup.get('selected').value, option]);
        this.formGroup.get('selected').setErrors(null);
      }
    } else if (option?.id === 0) {
      this.newSelected.emit(true);
    } else {
      this.selectedValueDupe = option;
      this.formGroup.get('selected').setValue(this.selectedValueDupe);
    }
  }

  /**
   * select entity
   * @param {MultiLevelInputOption} value
   * @memberof MultiLevelInputComponent
   */
  select(option: MultiLevelInputOption | MultiLevelInputOption[]) {
    this.valueChange.emit(option);
    this.closeDropdown();
  }

  /**
   * handle back button click
   * @memberof MultiLevelInputComponent
   */
  back() {
    this.backClicked.emit(true);
  }

  /**
   * close dropdown
   *
   * @memberof MultiLevelSelectInputComponent
   */
  closeDropdown() {
    this.dropdownOpen = false;
    this.formGroup.reset();
  }

  /**
   * should the item be checked
   * @param {MultiLevelInputOption} option
   * @returns {boolean}
   * @memberof MultiLevelSelectInputComponent
   */
  isChecked(option: MultiLevelInputOption) {
    if (option.name === 'Space' || option.name === 'Asset') {
      return false;
    }

    let user;
    const multiSelect = this.multiSelect();
    if (multiSelect) {
      user = (
        this.formGroup.get('selected').value as MultiLevelInputOption[]
      )?.find((u) => u.id === option.id);
    }
    return multiSelect
      ? user
      : this.formGroup.get('selected').value?.id === option.id;
  }

  /**
   * get object keys
   * @param {*} obj
   * @returns {string[]}
   * @memberof MultiLevelSelectInputComponent
   */
  getOptionKeys(obj: any) {
    return Object.keys(obj).sort((a, b) => a.localeCompare(b));
  }

  /**
   * return asset name
   *
   * @param {*} option
   * @returns
   * @memberof MultiLevelInputComponent
   */
  getAssetName(option) {
    return option?.id === 0
      ? option?.name
      : option?.building + ' - ' + option?.name;
  }

  /**
   * return tooltip for assignee options
   *
   * @param {MultiLevelInputOption} option
   * @returns
   * @memberof MultiLevelInputComponent
   */
  getAssigneeTooltipText(option: MultiLevelInputOption) {
    const teamLead = option.meta.teamLead ? '(Team Lead)' : '';
    const disabledText =
      option.meta.disabled && option.meta.disabledText
        ? `- ${option.meta.disabledText}`
        : '';
    return `${option?.name} ${teamLead} ${disabledText}`;
  }
}
