<div class="login w-100 d-flex align-content-center justify-content-center">
  <div class="align-self-center">
    <div class="login_wrapper">
      <div class="login_form">
        <div class="login_box">
          @if (env !== 'prod') {
            <div class="ribbon text-uppercase">
              <a href="/version">{{ env }}</a>
            </div>
          }
          <section
            class="login_content d-flex flex-column align-items-center justify-content-between"
            >
            <img
              src="/assets/vfi-logo.svg"
              alt="vfi-logo"
              class="vfi-logo mb-4"
              />
            @if (!showSignInForm) {
              <div class="email_content w-100">
                <div
                  class="form_wrapper d-flex flex-column align-items-center w-100"
                  >
                  <form [formGroup]="emailForm" class="w-100">
                    <div class="w-100">
                      <input
                        nz-input
                        type="email"
                        placeholder="Email"
                        formControlName="email"
                        />
                      @if (submitted && !!signInFormControls.email.errors) {
                        <div
                          class="red"
                          >
                          @if (!!signInFormControls.email.errors.required) {
                            <div
                              class="mt-2"
                              >
                              Email is required
                            </div>
                          }
                          @if (!!signInFormControls.email.errors.email) {
                            <div
                              class="mt-2"
                              >
                              Email must be a valid email address
                            </div>
                          }
                        </div>
                      }
                      <div class="mt-2">
                        <atom-vfi-button
                          id="submitEmailBtn"
                          [text]="'Log In'"
                          [ariaLabel]="'submitEmailBtn'"
                          [type]="'secondary'"
                          [classes]="'block-button'"
                          (clicked)="checkEmail(emailForm.value)"
                        ></atom-vfi-button>
                      </div>
                    </div>
                  </form>
                  @if (env !== 'prod' && otaVersion) {
                    <div
                      class="text-uppercase"
                      >
                      <a href="/version"
                        >Mobile OTA Version: {{ otaVersion?.slice(-8) }}</a
                        >
                      </div>
                    }
                  </div>
                  <div class="mt-8">
                    <p class="copyright gray-1 regular-12">
                      &copy; {{ currentYear }} Virtual Facility, Inc. All Rights
                      Reserved.
                    </p>
                  </div>
                </div>
              }
              @if (showSignInForm) {
                <div class="login_content">
                  <div class="d-flex align-items-center w-100">
                    <form [formGroup]="signInForm" class="w-100">
                      <input
                        nz-input
                        type="email"
                        placeholder="Email"
                        formControlName="email"
                        />
                      @if (submitted && !!signInFormControls.email.errors) {
                        <div
                          class="red"
                          >
                          @if (!!signInFormControls.email.errors.required) {
                            <div
                              class="mt-2"
                              >
                              Email is required
                            </div>
                          }
                          @if (!!signInFormControls.email.errors.email) {
                            <div
                              class="mt-2"
                              >
                              Email must be a valid email address
                            </div>
                          }
                        </div>
                      }
                      <input
                        nz-input
                        type="password"
                        placeholder="Password"
                        formControlName="password"
                        />
                      @if (submitted && !!signInFormControls.password.errors) {
                        <div
                          class="red"
                          >
                          @if (!!signInFormControls.password.errors.required) {
                            <div
                              class="mt-2"
                              >
                              Password is required
                            </div>
                          }
                          @if (!!signInFormControls.password.errors.minlength) {
                            <div
                              class="mt-2"
                              >
                              Password must be at least 6 characters
                            </div>
                          }
                        </div>
                      }
                      <a routerLink="/reset" class="underline mb-3 regular-16"
                        >Forgot Password</a
                        >
                        <atom-vfi-button
                          id="loginBtn"
                          [text]="'Log In'"
                          [ariaLabel]="'loginBtn'"
                          [type]="'secondary'"
                          [classes]="'block-button'"
                          (clicked)="signIn(signInForm.value)"
                        ></atom-vfi-button>
                      </form>
                      @if (env !== 'prod' && otaVersion) {
                        <div
                          class="text-uppercase"
                          >
                          <a href="/version"
                            >Mobile OTA Version: {{ otaVersion?.slice(-8) }}</a
                            >
                          </div>
                        }
                      </div>
                      <div class="mt-8">
                        <p class="copyright gray-1 regular-12">
                          &copy; {{ currentYear }} Virtual Facility, Inc. All Rights
                          Reserved.
                        </p>
                      </div>
                    </div>
                  }
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="mfa-recaptcha"></div>
