import {
  Component,
  OnChanges,
  SimpleChanges,
  input,
  model,
  output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  EDIT_INTEGRATION_FIELDS,
  ETL_IGNORABLE_STATE_OPTIONS,
  ExternalSystemOptions,
  Integrations,
  INTEGRATION_DIRECTIONS,
  INTEGRATION_FIELDS,
  INTEGRATION_OPTIONS_CONNECTION_TYPE,
  ConnectionTypeFields,
  CmmsIntegrationType,
  ExternalSystemTypes,
  MODAL_Z_INDEX,
} from '@vfi-ui/models';
import { isNil, keys, urlValidator } from '@vfi-ui/util/helpers';
import { NzModalComponent, NzModalContentDirective } from 'ng-zorro-antd/modal';
import { NgClass } from '@angular/common';
import { NzSliderComponent } from 'ng-zorro-antd/slider';
import { NzSwitchComponent } from 'ng-zorro-antd/switch';
import { DropdownMenuComponent, VfiButtonComponent } from '@vfi-ui/ui/atoms';

@Component({
  selector: 'nuclei-create-integration',
  templateUrl: './create-integration.component.html',
  styleUrls: ['./create-integration.component.scss'],
  imports: [
    NzModalComponent,
    NzModalContentDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    DropdownMenuComponent,
    NzSliderComponent,
    NzSwitchComponent,
    VfiButtonComponent,
  ],
})
export class CreateIntegrationComponent implements OnChanges {
  readonly isVisible = model(false);
  readonly mode = input<string>(undefined);
  readonly form = input<UntypedFormGroup>(undefined);
  readonly dynamicForm = model<UntypedFormGroup>(undefined);
  readonly model = input<any>(undefined);
  readonly editData = input<Integrations>(undefined);
  readonly licenseKey = input<string>(undefined);
  readonly integrationTypeOptions = model<ExternalSystemOptions[]>(undefined);
  readonly closeModal = output();
  readonly createIntegration = output<Integrations>();
  readonly updateIntegration = output<Integrations>();
  readonly connectionTypeChanged = output<string>();
  dynamicFields: ConnectionTypeFields[] = [];
  integrationDirectionOptions = INTEGRATION_DIRECTIONS;
  etlIgnorableStateOptions = ETL_IGNORABLE_STATE_OPTIONS;
  etlNormalizerVersionOptions = [];
  emailFields = ['senderAddress', 'externalAlertEmails'];
  CmmsIntegrationType = CmmsIntegrationType;
  externalSystemTypes = ExternalSystemTypes;
  modalZIndex = MODAL_Z_INDEX;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue) {
      this.dynamicFields = [];
      this.dynamicForm.set(new UntypedFormGroup({}));
    }
    if (changes?.mode?.currentValue === 'edit') {
      this.populateEditForm(this.editData());
    }
    if (changes?.integrationTypeOptions?.currentValue) {
      this.integrationTypeOptions.set(
        this.filterIntegrationTypeOptions(this.integrationTypeOptions())
      );
      this.updateIntegrationTypeValue(this.integrationTypeOptions());
    }
  }

  /**
   * update default integration form
   *
   * @param {string} value
   * @param {string} formName
   * @memberof CreateIntegrationComponent
   */
  updateFormValue(value: string, formName: string) {
    const form = this.form();
    if (formName === 'schema' && form.controls[formName].value !== value) {
      form.controls[formName].setValue(value);
      this.updateDynamicFormControl();
      this.updateConnectionType(value, this.integrationTypeOptions());
    }
    if (formName === 'direction' && form.controls[formName].value !== value) {
      form.controls[formName].setValue(value);
      form.controls['schema'].reset();
      this.dynamicFields = INTEGRATION_FIELDS[value];
      this.connectionTypeChanged.emit(null);
      this.setDyanmicForm(this.dynamicFields);
    }
  }

  /**
   * update dynamic integration form
   *
   * @param {string} value
   * @param {string} formName
   * @memberof CreateIntegrationComponent
   */
  updateDynamicFormValue(value: string, formName: string) {
    this.dynamicForm().controls[formName].setValue(value);
    this.dynamicForm().controls[formName].updateValueAndValidity();
    this.dynamicForm().markAsDirty();
    if (formName === 'connectionType') {
      this.updateDynamicFormControl();
      this.connectionTypeChanged.emit(value);
    }
  }

  /**
   * update validators for dynamic form based on selected value
   *
   * @memberof CreateIntegrationComponent
   */
  updateDynamicFormControl() {
    const keepFields = this.dynamicFields.filter((f) =>
      this.showDynamicField(f)
    );
    const removeFields = this.dynamicFields.filter(
      (f) => !this.showDynamicField(f)
    );
    removeFields.forEach((f) => {
      this.dynamicForm().get(f.formName).clearValidators();
      this.dynamicForm().get(f.formName).reset();
      this.dynamicForm().get(f.formName).updateValueAndValidity();
    });
    keepFields.forEach((f) => {
      if (f.required) {
        if (f.formName === 'url') {
          this.dynamicForm()
            .get(f.formName)
            .setValidators([Validators.required, urlValidator]);
        } else {
          this.dynamicForm().get(f.formName).setValidators(Validators.required);
        }
        this.dynamicForm().get(f.formName).updateValueAndValidity();
      }
    });
  }

  /**
   * check if dynamic form field should be shown
   *
   * @param {ConnectionTypeFields} dynamicField
   * @returns
   * @memberof CreateIntegrationComponent
   */
  showDynamicField(dynamicField: ConnectionTypeFields): boolean {
    const matchesSchema =
      !dynamicField?.showOnlySchemas?.length ||
      dynamicField.showOnlySchemas.includes(
        this.form()?.controls?.schema?.value
      );
    const matchesConnectionTypes =
      !dynamicField?.showOnlyConnectionTypes?.length ||
      dynamicField.showOnlyConnectionTypes.includes(
        this.dynamicForm()?.controls?.connectionType?.value
      );
    return matchesSchema && matchesConnectionTypes;
  }

  /**
   * set dynamic form controls
   *
   * @param {*} fields
   * @memberof CreateIntegrationComponent
   */
  setDyanmicForm(fields) {
    const newDyanmicForm = new UntypedFormGroup({});
    fields.forEach((r) => {
      const validator = r.required ? Validators.required : null;
      const defaultValue = r.type === 'slider' ? r?.defaultValue : null;
      if (r.formName === 'url') {
        newDyanmicForm.addControl(
          r.formName,
          new UntypedFormControl(
            defaultValue,
            Validators.compose([Validators.required, urlValidator])
          )
        );
      } else {
        newDyanmicForm.addControl(
          r.formName,
          new UntypedFormControl(defaultValue, validator)
        );
      }
    });
    this.dynamicForm.set(newDyanmicForm);
  }

  /**
   * populate form for edit
   *
   * @param {Integrations} data
   * @memberof CreateIntegrationComponent
   */
  populateEditForm(data: Integrations) {
    this.dynamicFields = INTEGRATION_FIELDS[data.direction];
    this.connectionTypeChanged.emit(null);
    this.setDyanmicForm([...this.dynamicFields, ...EDIT_INTEGRATION_FIELDS]);
    keys(this.form().value).forEach((key) =>
      this.form().controls[key].setValue(data[key])
    );
    this.formatFormValues(data);
    this.updateDynamicFormControl();
  }

  /**
   * emit event to create integration
   *
   * @memberof CreateIntegrationComponent
   */
  handleCreateIntegration() {
    const dynamicForm = this.generateDynamicFormForApi(true);
    this.createIntegration.emit({
      ...this.form().value,
      ...dynamicForm,
    });
  }

  /**
   * emit event to update integration
   *
   * @memberof CreateIntegrationComponent
   */
  handleUpdateIntegration() {
    const dynamicForm = this.generateDynamicFormForApi(false);
    this.updateIntegration.emit({
      ...this.form().value,
      ...dynamicForm,
    });
  }

  /**
   * filter integration type options based on direction type
   *
   * @param {ExternalSystemOptions[]} integrations
   * @returns {ExternalSystemOptions[]}
   * @memberof CreateIntegrationComponent
   */
  filterIntegrationTypeOptions(
    integrations: ExternalSystemOptions[]
  ): ExternalSystemOptions[] {
    const direction = this.form().controls['direction'].value;
    const types = INTEGRATION_OPTIONS_CONNECTION_TYPE[direction];
    return integrations.filter((i) => types.includes(i.connectionType));
  }

  /**
   * update schema value
   *
   * @param {ExternalSystemOptions[]} integrations
   * @memberof CreateIntegrationComponent
   */
  updateIntegrationTypeValue(integrations: ExternalSystemOptions[]) {
    const schemaForm = this.form().controls['schema'];
    const schemaValue = schemaForm.value;
    if (!integrations.find((i) => i.value === schemaValue)) {
      schemaForm.reset();
      schemaForm.updateValueAndValidity();
    }
  }

  /**
   * update connection type value
   *
   * @param {string} value
   * @param {ExternalSystemOptions[]} integrationOptions
   * @memberof CreateIntegrationComponent
   */
  updateConnectionType(
    value: string,
    integrationOptions: ExternalSystemOptions[]
  ) {
    const connectionTypeForm = this.dynamicForm().controls['connectionType'];
    const connectionType = connectionTypeForm.value;
    if (isNil(connectionType) && !isNil(value)) {
      const selectedOption = integrationOptions.find((i) => i.value === value);
      connectionTypeForm.setValue(selectedOption.connectionType);
      connectionTypeForm.updateValueAndValidity();
      this.connectionTypeChanged.emit(selectedOption.connectionType);
    }
  }

  /**
   * format form values
   *
   * @private
   * @param {Integrations} data
   * @memberof CreateIntegrationComponent
   */
  private formatFormValues(data: Integrations) {
    keys(this.dynamicForm().value).forEach((key) => {
      if (this.emailFields.includes(key)) {
        let emails = data[key] || [];
        if (key === 'senderAddress') {
          const senderEmails = data['email']?.['from'] || [];
          emails = senderEmails ? senderEmails.map((e) => e?.email) : [];
        }
        this.dynamicForm().controls[key].setValue(emails.join(', '));
      } else {
        this.dynamicForm().controls[key].setValue(data[key]);
      }
    });
    this.etlNormalizerVersionOptions = (data.normalizerVersions || []).map(
      (version) => ({ label: version, value: version })
    );
  }

  /**
   * generate email data for api call
   *
   * @private
   * @param {boolean} isCreate
   * @returns
   * @memberof CreateIntegrationComponent
   */
  private generateDynamicFormForApi(isCreate: boolean) {
    const { senderAddress, externalAlertEmails } = this.dynamicForm().value;
    let senderAddressEmails = null;
    if (senderAddress) {
      senderAddressEmails = this.formatEmailFormForApi(senderAddress);
    }
    let alertEmails = isCreate ? null : [];
    if (externalAlertEmails) {
      alertEmails = this.formatEmailFormForApi(externalAlertEmails);
    }
    return {
      ...this.dynamicForm().value,
      senderAddress: senderAddressEmails,
      externalAlertEmails: alertEmails,
    };
  }

  /**
   * format email form values for api call
   *
   * @private
   * @param {string} emails
   * @returns
   * @memberof CreateIntegrationComponent
   */
  private formatEmailFormForApi(emails: string) {
    return emails
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email.length);
  }
}
