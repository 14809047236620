import {
  AuthRoles,
  CUSTOMER_ID_HEADER,
  Customers,
  SSO_SIGN_IN,
  User,
  Users,
  VFI_CLAIMS,
  VFIClaims,
} from '@vfi-ui/models';
import { take, filter, map, switchMap, catchError } from 'rxjs/operators';
import { Injectable, ErrorHandler } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthService, SocketsService } from '@vfi-ui/data-access/shared';
import {
  SetCurrentCustomers,
  LoadUser,
  SetUpdateStatusReasonRequired,
} from '@vfi-ui/state';
import {
  IdTokenResult,
  multiFactor,
  User as firebaseUser,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class MeResolver {
  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private auth: AuthService,
    private socketService: SocketsService,
    private globalErrorHandler: ErrorHandler
  ) {}

  resolve(): Observable<User> {
    return this.initTasks().pipe(switchMap(() => this.waitForStorageToLoad()));
  }

  waitForStorageToLoad(): Observable<User> {
    return this.store
      .select((state) => state.auth.user)
      .pipe(
        filter((profileData) => !!profileData),
        take(1)
      );
  }

  /**
   * Initiates the tasks to load current user
   *
   * @return {*}  {Observable<any>}
   * @memberof MeResolver
   */
  initTasks(): Observable<any> {
    const userRef = this.auth.afa.currentUser;
    if (userRef) {
      return from(userRef.getIdTokenResult(true)).pipe(
        switchMap((token: IdTokenResult) => {
          const isSsoLogin = localStorage.getItem(SSO_SIGN_IN);
          if (isSsoLogin) {
            localStorage.removeItem(SSO_SIGN_IN);
            const vfiClaims = token?.claims?.[VFI_CLAIMS] as VFIClaims;
            const hasMultipleCustomers =
              vfiClaims?.customers?.filter((customer) => !customer?.disabled)
                ?.length >= 2;
            if (hasMultipleCustomers) {
              return of({ hasMultipleCustomers });
            }
          }
          return this.auth.me(false).pipe(
            // handle sso user
            catchError(() => of(null)),
            take(1),
            map((user) => user),
            switchMap((res: Users) => {
              const localStorageCustomerId =
                localStorage.getItem(CUSTOMER_ID_HEADER);
              const hasuraCustomerId =
                token?.claims?.['https://hasura.io/jwt/claims']?.[
                  'x-hasura-org-id'
                ];
              const customerId = localStorageCustomerId || hasuraCustomerId;

              // SSO users does not have customer ID set in local storage
              // Use hasura claims to set customer ID header
              if (!localStorageCustomerId && hasuraCustomerId) {
                localStorage.setItem(CUSTOMER_ID_HEADER, hasuraCustomerId);
              }

              if (customerId) {
                return this.auth.getCustomerById([+customerId]).pipe(
                  map((customer) => ({
                    user: res,
                    token,
                    customer,
                  }))
                );
              }
              return of({ user: res, token });
            })
          );
        }),
        switchMap(
          ({
            user,
            token,
            customer,
            hasMultipleCustomers,
          }: {
            user: Users;
            token: IdTokenResult;
            customer?: Customers;
            hasMultipleCustomers?: boolean;
          }) => {
            if (user && customer) {
              return this.setupUser({ user, userRef, token, customer });
            }
            if (hasMultipleCustomers) {
              return from(this.router.navigate(['select']));
            }
            return from(this.router.navigate(['404/user']));
          }
        ),

        take(1)
      );
    }
    return of();
  }

  /**
   * Sets up the user in the datastore
   *
   * @param {{
   *     user: Users;
   *     userRef: Partial<firebaseUser>;
   *     token: IdTokenResult;
   *     customer?: Customers;
   *   }} {
   *     user: databaseUser,
   *     userRef,
   *     token,
   *     customer,
   *   }
   * @return {*}  {Observable<boolean>}
   * @memberof MeResolver
   */
  setupUser({
    user: databaseUser,
    userRef,
    token,
    customer,
  }: {
    user: Users;
    userRef: firebaseUser;
    token: IdTokenResult;
    customer?: Customers;
  }): Observable<boolean> {
    const user = {
      id: databaseUser.id,
      uid: userRef.uid,
      email: userRef.email,
      username: userRef.displayName,
      displayName: databaseUser.displayName,
      phone: databaseUser.phone,
      active: true,
      avatarUrl: databaseUser.avatarUrl,
      isMFAEnabled: multiFactor(userRef)?.enrolledFactors.length > 0,
      isPushNotificationEnabled: databaseUser.isPushNotificationEnabled,
      isIntegrationStatusAlertEmailsEnabled:
        databaseUser.isIntegrationStatusAlertEmailsEnabled,
      isSuper: databaseUser.isSuper,
      teamMembers: databaseUser.teamMembers,
      authRole: databaseUser.isSuper ? AuthRoles.admin : databaseUser.authRole,
      permissions: databaseUser.permissions,
      landingPage: databaseUser.landingPage,
      landingPageLens: databaseUser.landingPageLens,
      analyticsPage: databaseUser.analyticsPage,
    };

    const hasuraClaims = token.claims
      ? token.claims['https://hasura.io/jwt/claims']
      : null;

    // [internal note] to add claims for user in Firebase
    if (!hasuraClaims) {
      console.warn('User should have claims set');
    } else {
      this.auth.setupTracker(
        databaseUser,
        hasuraClaims['x-hasura-org-id'],
        customer?.name
      );
    }

    this.store.dispatch(
      new LoadUser({
        ...user,
        id: String(user.id),
        phone: user?.phone?.number,
        token: userRef.refreshToken,
        hasuraClaims,
      })
    );
    if (customer) {
      this.store.dispatch(new SetCurrentCustomers({ customers: customer }));
      this.auth.getCustomerAlarmConfig().subscribe((config) => {
        this.store.dispatch(
          new SetUpdateStatusReasonRequired(config.isUpdateStatusReasonRequired)
        );
      });
    }

    if (this.globalErrorHandler['setUser']) {
      this.globalErrorHandler['setUser'](user);
    }
    // Connect to socket connection
    this.socketService.initSocket(token.token);

    return of(true);
  }
}
